// import { Disclosure } from "@headlessui/react";
import React from "react";
import {
  Book,
  // Book,
  // Bookmark,
  Code,
  Codesandbox,
  Copy,
  Cpu,
  Download,
  Minimize,
  // Minus,
  // Plus,
} from "react-feather";
import { Link } from "react-router-dom";
// import whitePaper from "./../../assets/images/whitepaper.svg";
// import gitbook from "./../../assets/images/gitbook.svg";

const DeveloperDocument = () => {
  return (
    <>
      <div className="flex w-full ">
        <div className="xl:basis-3/4 basis-full pt-6 px-6 lg:pt-9 lg:px-12">
          {/* border-r border-r-[#EAEEF6]  */}
          <div className="w-full">
            <div className="w-full mb-4">
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The L1X Protocol enables transactions cross-chain using advanced
                technology. The objective is to revolutionize the decentralized
                landscape by unraveling exceptional interoperability,
                scalability, speed, and security.
              </p>

              <div className="mb-4">
                <p className="text-base font-semibold">
                  Please refer to the GitBook for a complete step-by-step guide
                </p>
              </div>
              <div className="grid sm:grid-cols-2 gap-4 mb-8">
                <div className="group">
                  <div className="p-4 rounded-2xl border border-[#E9EDF3]  group-hover:bg-blue-50 group-hover:border-blue-200 cursor-pointer">
                    <Link target="_blank" to="https://l1x-sdk.gitbook.io/l1x-developer-interface">
                      <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-blue group-hover:text-white">
                        <Code className="w-5 h-5" />
                      </div>
                      <h4 className="text-base font-semibold mb-2">
                        Development SDK
                      </h4>
                      <p className="text-sm text-secondary-text  text-ellipsis line-clamp-2">
                        Unlock boundless potential with our cutting-edge
                        Development SDK tailored for the revolutionary L1X
                        blockchain, designed to inspire developers and fuel
                        their creativity within our dynamic developer portal
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="group">
                  <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-green-50 group-hover:border-green-200  cursor-pointer">
                    <Link target="_blank" to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/interface-essentials">
                      <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-green group-hover:text-white">
                        <Book className="w-5 h-5" />
                      </div>
                      <h4 className="text-base font-semibold mb-2">
                        Interface Essentials
                      </h4>
                      <p className="text-sm text-secondary-text  text-ellipsis line-clamp-2">
                        Dive into the essential tools and interfaces crafted for
                        seamless interaction with the L1X blockchain.
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="group">
                  <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-yellow-50 group-hover:border-yellow-200  cursor-pointer">
                    <Link target="_blank" to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/interface-essentials/l1x-vm-sdk/l1x-native-sdk-for-l1x-vm/build-your-first-smart-contract-on-l1x-vm">
                      <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-yellow group-hover:text-white">
                        <Codesandbox className="w-5 h-5" />
                      </div>
                      <h4 className="text-base font-semibold mb-2">
                        Build your first smart contract
                      </h4>
                      <p className="text-sm text-secondary-text  text-ellipsis line-clamp-2">
                        Embark on your journey into L1X blockchain with our
                        comprehensive guide to building your first smart
                        contract.
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="group">
                  <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-red-50 group-hover:border-red-200  cursor-pointer">
                    <Link target="_blank" to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/multi-chain-with-x-talk/start-building-with-x-talk/cross-chain-data-and-message-passing-xcdp/build-your-first-xcdp-contract">
                      <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-red group-hover:text-white">
                        <Cpu className="w-5 h-5" />
                      </div>
                      <h4 className="text-base font-semibold mb-2">
                        Build your first cross-chain contract
                      </h4>
                      <p className="text-sm text-secondary-text  text-ellipsis line-clamp-2">
                        Discover the power of interoperability as we guide you
                        through creating your first cross-chain contract,
                        seamlessly connecting diverse blockchain ecosystems.
                      </p>
                    </Link>
                  </div>
                </div>
                {/* <div className="group">
                  <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-purple-50 group-hover:border-theme-purple  cursor-pointer">
                    <Link to="#">
                      <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-purple group-hover:text-white">
                        <Copy className="w-5 h-5" />
                      </div>
                      <h4 className="text-base font-semibold mb-2">
                        Clone existing multichain projects
                      </h4>
                      <p className="text-sm text-secondary-text text-ellipsis line-clamp-2">
                        Accelerate your blockchain endeavors by cloning existing
                        multi-chain projects, leveraging established frameworks
                        to expedite development and foster interoperability.
                      </p>
                    </Link>
                  </div>
                </div> */}
              </div>
              {/*<div className="mb-4">
                <p className="text-2xl font-semibold">Overview</p>
              </div>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                This document provides developers with the required information
                to enable self-onboarding to Layer One X, for a Web3 project,
                including information/how to's on use cases and grants:
              </p>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#4087F3] leading-6">
                  <Link
                    to="https://app.gitbook.com/o/E37av3Jbz3uuBODbRRSf/s/eTLmER6Ab8VaxeOI6Vla/~/changes/177/l1x-development-environment-overview/setup-l1x-development-environment"
                    target="_blank"
                  >
                    Welcome Message & Overview
                  </Link>
                </li>
                <li className="text-sm mb-1 text-[#4087F3] leading-6">
                  <Link
                    to="https://app.gitbook.com/o/E37av3Jbz3uuBODbRRSf/s/eTLmER6Ab8VaxeOI6Vla/~/changes/177/l1x-development-environment-overview/l1x-vs.-other-chains"
                    target="_blank"
                  >
                    L1X vs. Other Chains
                  </Link>
                </li>
              </ul>
              <div className="mb-2">
                <p className="text-base font-semibold ">
                  Setup your L1X Development Environment
                </p>
              </div>
              <div className="mb-2">
                <p className="text-base font-semibold ">
                  {" "}
                  Developer Onboarding
                </p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Introduction to the on-boarding process for developers on L1X
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Importance of Web3 Development Skills
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Overview of Developer Roles and Responsibilities
                </li>
              </ul>
              <div className="mb-2">
                <p className="text-base font-semibold "> Use Cases Overview</p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Explanation of Use Cases
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Importance of Web3 Technology
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Examples of Successful Use Cases
                </li>
              </ul>
              <div className="mb-2">
                <p className="text-base font-semibold ">
                  {" "}
                  Use Case 1: Decentralized Finance (DeFi)
                </p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Overview of DeFi
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Key Components and Protocols
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Use Case Implementation Examples
                </li>
              </ul>
              <div className="mb-2">
                <p className="text-base font-semibold ">
                  {" "}
                  Use Case 2: Non-Fungible Tokens (NFTs)
                </p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Introduction to NFTs
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Applications in Art, Gaming, and Collectibles
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Use Case Examples and Case Studies
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Emerging Trends in NFTs
                </li>
              </ul>

              <div className="mb-2">
                <p className="text-base font-semibold ">
                  Use Case 3: Decentralized Autonomous Organizations (DAOs)
                </p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Understanding DAOs
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Use Cases in Medical Records Management
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Future Developments
                </li>
              </ul>
              <div className="mb-2">
                <p className="text-base font-semibold ">
                  Use Case 4: Supply Chain Management
                </p>
              </div>
              <ul className="w-full mb-5 list-outside list-disc ps-8">
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Understanding DAOs
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Use Cases in Medical Records Management
                </li>
                <li className="text-sm mb-1 text-[#2D4665] leading-6">
                  Future Developments
                </li>
              </ul>
             
                <Link
                  className="bg-[#4087F3] text-white px-4 py-2.5 mb-10 text-sm leading-3 inline-block rounded-md"
                  to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-overview/contents"
                  target="_blank"
                >
                  Check more use cases
                </Link>
             
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                This L1X Gitbook, provides a comprehensive guide for Web3
                developers to onboarding to take advantage of the Layer One X,
                XTalk cross-chain interoperability, covering, development
                practices, use cases, grants program, developer tools, best
                practices, community collaboration, and resources for further
                learning. Each section includes explanations, examples,
                guidelines, and references to additional resources.
              </p> */}
            </div>
          </div>
        </div>
        <div className="basis-1/4 min-w-[280px] pt-9 px-5 hidden xl:block">
          {/* <div className="w-full sticky top-0 left-0">
            <Disclosure defaultOpen>
              <Disclosure.Button className="text-left flex items-start w-full justify-between mb-2">
                <h4 className="text-[#222D3A] text-sm font-medium mb-2 mr-3 normal-case">
                  L1X Development Environment - Overview
                </h4>
                <Plus className=" min-w-4 w-4 h-4" />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                <ul className="w-full mb-5">
                  <li className="text-[#4087F3] hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[#4087F3] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-overview/contents"
                    >
                      Contents
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-overview/l1x-vs.-other-chains"
                    >
                      L1X vs. Other Chains
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-overview/setup-l1x-development-environment"
                    >
                      Setup L1X Development Environment
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-self-onboarding/l1x-documentation"
                    >
                      {" "}
                      L1X Documentation
                    </Link>
                  </li>
                </ul>
              </Disclosure.Panel>
            </Disclosure>
            <Disclosure>
              <Disclosure.Button className="text-left flex items-start w-full justify-between mb-2">
                <h4 className="text-[#222D3A] text-sm font-medium mb-2 mr-3 capitalize  ">
                  Get started with l1x sde
                </h4>

                <Plus className=" min-w-4 w-4 h-4" />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                <ul className="w-full mb-5">
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/l1x-workspace-overview"
                    >
                      L1x Workspace Overview
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/l1x-workspace-overview/l1x-sde-toolkit-architecture"
                    >
                      {" "}
                      L1x SDE toolkit architecture
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types"
                    >
                      {" "}
                      Development Workspace Environment & Contract Types
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/1-contemporary-contracts"
                    >
                      {" "}
                      1 - Contemporary Contracts
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/2-erc20-contract-deployment-and-cross-contract-call"
                    >
                      {" "}
                      2 - ERC20 Contract Deployment and Cross Contract Call
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/3-x-talk-flow-cross-chain-contract"
                    >
                      {" "}
                      3 - X-Talk Flow Cross Chain Contract
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/4-balancer-v2"
                    >
                      {" "}
                      4 - Balancer-v2
                    </Link>
                  </li>
                </ul>
              </Disclosure.Panel>
            </Disclosure>

            <Disclosure>
              <Disclosure.Button className="text-left flex items-start w-full justify-between mb-2">
                <h4 className="text-[#222D3A] text-sm font-medium mb-2 mr-3">
                  Build with developer playground
                </h4>
                <Plus className=" min-w-4 w-4 h-4" />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                <ul className="w-full mb-5">
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/build-with-developer-playground/developer-playground-resource"
                    >
                      Developer Playground Resource
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-technology-overview"
                    >
                      {" "}
                      L1X Technology Overview
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-technology-overview/l1x-system-architecture"
                    >
                      {" "}
                      L1X System Architecture
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-technology-overview/x-talk"
                    >
                      {" "}
                      X-Talk
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-technology-overview/x-talk/x-talk-overview"
                    >
                      {" "}
                      X-Talk Overview
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-technology-overview/x-talk/xtalk-deep-dive"
                    >
                      {" "}
                      X-Talk Deep Dive
                    </Link>
                  </li>
                </ul>
              </Disclosure.Panel>
            </Disclosure>

            <Disclosure>
              <Disclosure.Button className="text-left flex items-start w-full justify-between">
                <h4 className="text-[#222D3A] text-sm font-medium mb-2 mr-3">
                  Core concepts
                </h4>
                <Plus className=" min-w-4 w-4 h-4" />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500 ">
                <ul className="w-full mb-5">
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/account"
                    >
                      Account
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/address"
                    >
                      {" "}
                      Address
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/transaction"
                    >
                      Transaction
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/smart-contract"
                    >
                      {" "}
                      Smart Contract
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/contract-lifecycle-vm"
                    >
                      Contract Lifecycle (VM)
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/cross-contract-call"
                    >
                      {" "}
                      Cross-Contract call
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/cross-contract-call/l1x-vm-cross-contract-calls"
                    >
                      {" "}
                      L1X VM Cross-Contract Calls
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/cross-contract-call/l1x-vm-evm-cross-contract-calls"
                    >
                      {" "}
                      L1X VM-EVM Cross Contract Calls
                    </Link>
                  </li>
                  <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-5 relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                    <Link
                      target="_blank"
                      to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/core-concepts/cluster"
                    >
                      {" "}
                      Cluster
                    </Link>
                  </li>
                </ul>
              </Disclosure.Panel>
            </Disclosure>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DeveloperDocument;
