const fetchData = async (limit,page = 1) => {
  try {
        const response = await fetch(`https://l1x-blog.ghost.io/ghost/api/content/posts/?key=a9ee0792b61894278ea5e6adb8&limit=${limit}&page=${page}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Accept-Version': 'v5.0'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        return response.json();
          
  } catch (error) {
    console.error('Error fetching data:', error.message);
  }
};

const fetchBlogDetails = async (_id) => {
  try {
        const response = await fetch(`https://l1x-blog.ghost.io/ghost/api/content/posts/${_id}?key=a9ee0792b61894278ea5e6adb8`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Accept-Version': 'v5.0'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        return response.json();
          
  } catch (error) {
    console.error('Error fetching data:', error.message);
  }
};



const getGitbookData = async () => {
  try {
          const accessToken = 'gb_api_EFO4I9vRJK8opwu0Hq6RgGgyQnf7c60yXzTVHKpd'; // Your GitBook API access token
          const bookSlug = 'l1x-master-sdk'; // Replace with the book slug of your GitBook documentation
          const pageSlug = 'ubTvs7ITP4emn1uocqF8'; // Replace with the page slug of the specific page you want to fetch
          
          // Construct the URL for the API endpoint
          const apiUrl = `https://api.gitbook.com/v1/book/${bookSlug}/page/${pageSlug}`;
          
          // Fetch page content from GitBook API
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch page content');
            }
            return response.json();
          })
          .then(pageData => {
            console.log('Page Content:', pageData);
            // Handle the page content as needed
          })
          .catch(error => {
            console.error('Error fetching page content:', error);
          });
        // const response = await fetch(`https://api.gitbook.com/v1/accounts/docs`, {
        //     method: 'GET',
        //     headers: {
        //       'Authorization': `Bearer gb_api_EFO4I9vRJK8opwu0Hq6RgGgyQnf7c60yXzTVHKpd`,
        //       'Content-Type': 'application/json'
        //     }
        // });


        // if (!response.ok) {
        //     throw new Error('Network response was not ok');
        // }
        
        // return response.json();
          
  } catch (error) {
    console.error('Error fetching data:', error.message);
  }
};

export default { fetchData,fetchBlogDetails, getGitbookData };