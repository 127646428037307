import { useEffect, useRef } from "react";
import PDFFile from "./../../assets/images/pdf.png";
import DocFile from "./../../assets/images/doc.png";
import ChatUser from "./../../assets/images/user.jpg";
import { Download } from "react-feather";

const SendMessageCard = ({ message }) => {
    const messageContainer = useRef(null);
    useEffect(() => {
      if (messageContainer?.current) {
        messageContainer.current.innerHTML = message?.message;
      }
    }, [messageContainer?.current]);
  
    function getMediaType(url) {
      const parts = url.split(".");
      return parts[parts.length - 1];
    }
  
    return (
      <>
        <div className="w-full mb-4 flex justify-end">
          <div className="flex items-start">
            <div className="text-right">
              <h6 className="text-xs  text-[#515151] font-medium flex items-center justify-end mb-2">
                <span className="text-[8px] text-[#2D4665] mr-3">
                  {new Date(message?.created_at)?.toLocaleString("en-GB", {
                    hour12: true,
                  })}
                </span>{" "}
                {message?.sender?.name}
              </h6>
  
              {message?.attachments?.length > 0 ? (
                <div className="bg-theme-blue/15 to-theme-purple text-black px-4 py-3 text-sm leading-4 rounded-lg rounded-tr-none  inline-block">
                  <div className="w-28 h-28 rounded-lg relative mb-2 overflow-hidden">
                    {getMediaType(message?.attachments[0]?.url) == "png" ||
                    getMediaType(message?.attachments[0]?.url) == "jpg" ? (
                      <>
                        <img
                          src={message?.attachments[0]?.url}
                          className="w-full h-full rounded-lg object-cover"
                        />
                        <a
                          href={message?.attachments[0]?.url}
                          target="_blank"
                          download
                        >
                          <button
                            className="bg-white text-black p-1 w-6 h-6 rounded-full absolute bottom-1 right-1 flex items-center justify-center shadow-lg"
                            // onClick={() =>
                            //   downloadFileFromUrl(message?.attachments[0]?.url)
                            // }
                          >
                            <Download className="w-4" />
                          </button>
                        </a>
                      </>
                    ) : getMediaType(message?.attachments[0]?.url) == "pdf" ? (
                      <div className="w-28 h-28 rounded-lg relative p-2 border border-gray-200 bg-gray-100">
                        <img src={PDFFile} className="w-10 mb-2" />
                        <div>
                          <p className="truncate text-xs mb-1">
                            {message?.attachments[0]?.name}
                          </p>
                          <div className="grid grid-cols-1">
                            <p className="text-xs "></p>
                            {/* <p className="text-xs ml-2">pdf</p> */}
                          </div>
                        </div>
                        <a
                          href={message?.attachments[0]?.url}
                          target="_blank"
                          download
                        >
                          <button
                            className="bg-white text-black p-1 w-6 h-6 rounded-full absolute bottom-1 right-1 flex items-center justify-center shadow-lg"
                            // onClick={() =>
                            //   downloadFileFromUrl(message?.attachments[0]?.url)
                            // }
                          >
                            <Download className="w-4" />
                          </button>
                        </a>
                      </div>
                    ) : (
                      <div className="w-28 h-28 rounded-lg relative p-2 border border-gray-200 bg-gray-100">
                        <img src={DocFile} className="w-10 mb-2" />
                        <div>
                          <p className="truncate text-xs mb-1">
                            {message?.attachments[0]?.name}
                          </p>
                          {/* <div className="grid grid-cols-2">
                            <p className="text-xs border-r border-gray-200">
                              {(attachment?.size / 1024).toFixed(2)} MB
                            </p>
                            <p className="text-xs ml-2">Document</p>
                          </div> */}
                        </div>
                        <a
                          href={message?.attachments[0]?.url}
                          target="_blank"
                          download
                        >
                          <button
                            className="bg-white text-black p-1 w-6 h-6 rounded-full absolute bottom-1 right-1 flex items-center justify-center shadow-lg"
                            // onClick={() =>
                            //   downloadFileFromUrl(message?.attachments[0]?.url)
                            // }
                          >
                            <Download className="w-4" />
                          </button>
                        </a>
                      </div>
                    )}
                  </div>
                  <p className="txt-sm" ref={messageContainer}></p>
                </div>
              ) : (
                <div className="bg-theme-blue/15 to-theme-purple text-black px-4 py-3 text-sm leading-4 rounded-lg rounded-tr-none inline-block">
                  <p
                    className="whitespace-pre-wrap leading-normal text-left break-words break-all"
                    ref={messageContainer}
                  ></p>
                </div>
              )}
            </div>
            <div className="ml-3 rounded-full overflow-hidden w-8 h-8 min-w-8 inline-flex items-center justify-center">
              <img
                src={
                  message?.sender?.profile_picture
                    ? message?.sender?.profile_picture
                    : ChatUser
                }
                alt="user icon"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

export default SendMessageCard;