import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { toast } from "react-toastify";
import { YoutubeService } from "../../Services/youtubeService";
import CourseCard from "./CourseCard";
import CategoryCard from "./CategoryCard";
import { Link } from "react-router-dom";
import StatisticsCard from "./StatisticsCard";
import AuthModal from "../../components/AuthModal";
import { CourseService } from "../../Services/courseService";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
ChartJS.register(ArcElement, Tooltip, Legend);
export const data = {
  labels: ["Rust", "L1X Blockchain", "Solidity"],
  datasets: [
    {
      label: "Learning Interest",
      data: [56, 20, 20],
      cutout: "75%",

      backgroundColor: ["#3B82F6", "#F59E0B", "#14B8A6"],
      borderColor: ["#ffffff", "#ffffff", "#ffffff"],
      borderWidth: 1,
    },
  ],
};
const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 8,
      },
    },
  },
};

const Courses = () => {
  const session = JSON.parse(localStorage.getItem("session"))?.session_token;
  const [courses, setCourses] = useState({
    myLearnings: [],
    categories: [],
    loader: false,
    mylearningLoader: false,
    nextPageToken: "",
    prevPageToken: "",
  });

  useEffect(() => {
    listCourseCategories();
    listMyLearnings();
  }, []);

  async function listCourseCategories() {
    try {
      if (!session) {
        return;
      }
      setCourses((prevState) => ({
        ...prevState,
        loader: true,
      }));
      const response = await YoutubeService.listPlaylist(courses.nextPageToken);
      setCourses((prevState) => ({
        ...prevState,
        categories: [...prevState.categories, ...response?.items],
        nextPageToken: response?.nextPageToken,
        prevPageToken: response?.prevPageToken,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to load courses.");
    } finally {
      setCourses((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  }

  async function listMyLearnings() {
    try {
      if (!session) {
        return;
      }
      setCourses((prevState) => ({
        ...prevState,
        mylearningLoader: true,
      }));
      const courses = await CourseService.listCourse("", 1, 3);
      setCourses((prevState) => ({
        ...prevState,
        myLearnings: [...courses?.data],
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to load courses.");
    } finally {
      setCourses((prevState) => ({
        ...prevState,
        mylearningLoader: false,
      }));
    }
  }
  return (
    <div className="inner-content-wrapper overflow-y-auto z-10  relative max-h-[calc(767px-48px)]">
      <div className="flex w-full">
        <div className="basis-3/4">
          {/* My Learnings */}
          <div className="w-full bg-[#F6F8FC] pt-9 px-12 pb-5">
            <h4 className="text-sm mb-3 text-[#222D3A] font-medium">
              My Courses
            </h4>
            <div className="grid grid-cols-3 gap-5">
              <StatisticsCard />
            </div>
            {!courses.mylearningLoader && courses.myLearnings?.length > 0 ? (
              <div className="flex items-center justify-between mb-3 ">
                <h4 className="text-sm text-[#222D3A] font-medium">
                  My Learning
                </h4>
                <Link to={`/courses/learnigs`}>
                  <button className="px-4 py-1 font-medium text-xs bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl">
                    View All
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="grid grid-cols-3 gap-5">
              {!courses.mylearningLoader &&
                courses?.myLearnings?.map((course) => (
                  <Link to={`learn/${course?.videoId}`} key={course?.videoId}>
                    <CourseCard data={course} />
                  </Link>
                ))}

              {/* skeleton */}
              {courses.mylearningLoader &&
                new Array(3).fill(1).map((el, index) => {
                  return (
                    <div className="w-full relative mb-4" key={index}>
                      <Skeleton className="w-full h-24 object-cover rounded-md mb-2" />
                      <Skeleton count={4} className="mb-0" />
                    </div>
                  );
                })}
              {/* skeleton */}
            </div>
          </div>
          {/* End Of My Learnings */}

          {/* Trending Courses */}
          <div className="w-full pt-9 px-12 pb-5">
            <h4 className="text-sm mb-3 text-[#222D3A] font-medium">
              Categories
            </h4>
            <InfiniteScroll
              dataLength={courses.categories?.length ?? 0}
              next={listCourseCategories}
              hasMore={courses.nextPageToken && courses.categories?.length > 0}
              height="630px"
            >
              <div className="grid grid-cols-3 gap-5">
                {courses.categories?.length > 0 &&
                  courses.categories.map((category, index) => (
                    <Link
                      to={`/courses/${category?.id}?category=${category?.snippet?.title}`}
                      key={category?.etag}
                    >
                      <CategoryCard category={category} />
                    </Link>
                  ))}

                {/* Skeleton */}
                {courses.loader &&
                  new Array(3).fill(1).map((el, index) => {
                    return (
                      <div className="w-full relative mb-4" key={index}>
                        <Skeleton className="w-full h-24 object-cover rounded-md mb-2" />
                        <Skeleton count={4} className="mb-0" />
                      </div>
                    );
                  })}
                {/* End Of Skeleton */}
              </div>
            </InfiniteScroll>
          </div>
          {/* End Of Trending Courses */}
        </div>
        {/* Learning Interest */}
        <div className="basis-1/4 min-w-[282px] ">
          <div className="sticky left-0 top-0 px-5 py-10 ">
            <h4 className="text-sm text-[#222D3A] mb-4 font-medium text-center">
              {" "}
              Learning Interest{" "}
              {courses.nextPageToken && courses.categories?.length > 0
                ? "true"
                : "false"}
            </h4>
            <Doughnut data={data} options={options} />
          </div>
        </div>
        {/* End Of Learning Interest */}
      </div>

      {/* Auth */}
      {!session && (
        <AuthModal message="To start the courses please Sign up / Sign in" />
      )}
      {/* End Of Auth */}
    </div>
  );
};

export default Courses;
