import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { InteractionService } from "../../Services/interactionService";
import { Search, User, Volume2 } from "react-feather";
import InviteConnection from "./../../assets/images/invite-connections.svg";
import ChatListSkeleton from "./ChatListSkeleton";
import ChatUserCard from "../../components/ChatUserCard";
const ChatList = ({
  interaction = {
    contacts: [],
    groups: [],
    activeChat: {},
    type: "message", // message | channels,
    contactLoader: false,
    groupLoader: false
  },
  setContactsList = (list, activeChat) => {},
  setGroupsList = (list, activeChat) => {},
  changeActiveChat = () => {},
  toggleModelSearch = false,
  setToggleModelSearch = () => {},
  changeType = (type) => {},
  setLoader = (state) => {},
}) => {
  const [loader, setLocalLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [debounceSearchId, setDebounceSearchId] = useState(null);
  const DEBOUNCETIME = 500;

  const loaderMemo = useMemo(() => {
    return interaction.type == 'message' ? interaction?.contactLoader : interaction?.groupLoader;
  }, [interaction?.contactLoader, interaction?.groupLoader, interaction.type])

  const list = useMemo(() => {
    return interaction.type == 'message' ? interaction?.contacts : interaction?.groups;
  }, [interaction.contacts, interaction.groups, interaction.type]);

  useEffect(() => {
    if (debounceSearchId) {
      clearTimeout(debounceSearchId);
    }
    let timeoutid = setTimeout(() => {
      searchDeveloper(search);
    }, DEBOUNCETIME);
    setDebounceSearchId(timeoutid);
  }, [search]);

  useEffect(() => {
    setSearchList(list);
  }, [list]);

  useEffect(() => {
    setLocalLoader(loaderMemo);
  }, [loaderMemo]);

  async function searchDeveloper(search) {
    try {
      if (!search) {
        return setSearchList(list);
      }
      setLoader(true, interaction.type);
      if (interaction.type == "message") {
        const developers = await InteractionService.searchDeveloper(search);
        setSearchList(developers?.data);
      } else if (interaction.type == "channels") {
        const channels = await InteractionService.listChannels(search);
        setSearchList(channels?.data);
      }
      setLoader(false, interaction.type);
    } catch (error) {
      toast.error("Failed to search developer.");
      setLoader(false, interaction.type);
    }
  }

  async function addSearchUserToList(user) {
    const existedUser = list.find((el) =>
      interaction.type == "message"
        ? el?.receiver == user?.receiver && el.receiver
        : el?._id == user?._id && el?._id
    );
    if (existedUser) {
      changeActiveChat(existedUser);
    } else {
      const newChatList = [{...user, isSearched: true}, ...list];
      interaction.type == 'message' ? setContactsList(newChatList, user) : setGroupsList(newChatList, user);
    }
    setSearchList([]);
    setSearch("");
  }

  function removeNotInteractedUsers() {
    setContactsList(interaction.contacts.filter(el => !(el.isSearched && !el.created_at)))
    setGroupsList(interaction.groups.filter(el => !(el.isSearched && !el.created_at)))
  }

  return (
    <div className="basis-1/3 col-span-1 min-w-[336px] border-[#EAEEF6] border-r ">
      {/* Header */}
      <div className="bg-[#E9EDF3] p-[10px]">
        {/* Toggle */}
        <div className="grid grid-cols-2 bg-white rounded-2xl mb-3">
          <button
            className={`text-xs rounded-3xl px-3 py-[10px] ${
              interaction.type == "message"
                ? "bg-[#0A2F5D] text-white"
                : "text-primary-text"
            }`}
            onClick={() => {
              setSearch("");
              changeType("message");
            }}
          >
            <User
              className={`inline-block me-1 w-4 h-4 ${
                interaction.type == "message" ? "text-white" : "text-primary-text"
              }`}
            />{" "}
            Message
          </button>
          <button
            className={`text-xs rounded-3xl px-3 py-[10px] ${
              interaction.type == "channels"
                ? "bg-[#0A2F5D] text-white"
                : "text-primary-text"
            }`}
            onClick={() => {
              setSearch("");
              changeType("channels");
            }}
          >
            <Volume2
              className={`inline-block me-1 w-4 h-4 ${
                interaction.type == "channels" ? "text-white" : "text-primary-text"
              }`}
            />{" "}
            Channels
          </button>
        </div>
        {/* End Toggle */}

        {/* Search */}
        <div className="flex justify-between">
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Search"
              className="placeholder:text-primary-text text-black border border-[#EAEEF6]/75 ps-4 pe-8 py-2 rounded-[25px] text-sm outline-none focus:border-slate-400 w-full min-w-[245px] leading-5"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search className="w-5 h-5 text-primary-text/50 absolute top-2 right-3" />
          </div>
        </div>
        {/* End Search */}
      </div>
      {/* End Of Header */}

      {/* Chat List */}
      {loader ? (
        <ChatListSkeleton />
      ) : (
        <div className="overflow-y-auto h-screen max-h-[calc(100vh-156px)] md:max-h-[calc(100vh-421px)]">
          {searchList?.length <= 0 ? (
            <div className="w-full py-3 overflow-y-auto max-h-[calc(100%-106px-10px)] h-full">
              <div className="h-full flex items-center justify-center">
                <div className="text-center">
                  <img src={InviteConnection} className="mx-auto mb-4 w-24" />
                  <p className="text-sm font-normal mb-1.5">
                    You don’t have any connections
                  </p>
                </div>
              </div>
            </div>
          ) : searchList.length > 0 ? (
            searchList?.map((user) => (
              <ChatUserCard
                user={user}
                changeActiveChat={addSearchUserToList}
                activeChat={interaction.activeChat}
                type={interaction.type}
                key={`user_${user?._id ?? user?.receiver}`}
              />
            ))
          ) : ''}
        </div>
      )}
      {/* End Of Chat List */}

      {/* Search On Model */}
      {toggleModelSearch && (
        <div className="absolute top-0 left-0 w-full h-full z-10 flex items-start justify-center pt-28">
          <div className="relative z-10">
            <div className="bg-white w-[400px] rounded-md overflow-hidden">
              <div className="relative">
                <input
                  className="px-4 py-3 rounded-md bg-white w-full"
                  placeholder={`Search by Name${interaction.type == 'message' ? `, Email` : ''}`}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={() => removeNotInteractedUsers()}
                />
                <Search className="absolute top-1/2 right-3 -translate-y-1/2 text-secondary-text" />
              </div>
              <div className="w-full border-t border-gray-200 overflow-y-auto max-h-60 ">
                {loader ? (
                  <ChatListSkeleton />
                ) : searchList.length > 0 ? (
                  searchList?.map((user, index) => (
                    <ChatUserCard
                      user={user}
                      changeActiveChat={(activeChat) => {
                        if (!interaction.contactLoader && !interaction.groupLoader) {
                          setToggleModelSearch(false);
                          addSearchUserToList(activeChat); 
                        }
                      }}
                      type={interaction.type}
                      key={`user_${user?._id ?? user?.receiver}`}
                    />
                  ))
                ) : <div className="text-center m-5"><h4>No Records Found.</h4></div>}

                {!search && list?.length <= 0 && (
                  <div className="bg-slate-50 px-12 py-16 text-center">
                    <h2 className="font-semibold text-slate-900">
                      No results found
                    </h2>
                    <p className="mt-2 text-sm leading-6 text-slate-600">
                      We can’t find anything with that term at the moment, try
                      searching something else.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="absolute top-0 left-0 w-full h-full bg-black/50 backdrop-blur-md z-0"
            onClick={() => setToggleModelSearch(false)}
          ></div>
        </div>
      )}
      {/* End Of Search On Model */}
    </div>
  );
};

export default ChatList;
