// UserContext.js
import React, { createContext, useEffect, useState } from 'react';
import socketConnection from '../socket';

const PageTextContext = createContext();

export const PageTextProvider = ({ children }) => {
  const storedPageData = localStorage.getItem('pageData');
  const [pageData, setPageData] = useState(() => {
    const storedUser = localStorage.getItem('pageData');
    return storedPageData ? JSON.parse(storedPageData) : {pageText: '', pageTitle: ''};
  });
  
  
useEffect(() => {
  localStorage.setItem('pageData', JSON.stringify(pageData));
}, [pageData]);

const updatePageData = (newPageData) => {
    setPageData(newPageData);
};

  return (
    <PageTextContext.Provider value={{ pageData, updatePageData}}>
      {children}
    </PageTextContext.Provider>
  );
};

export default PageTextContext;
