import React, { createContext, useEffect, useState } from "react";
import socketConnection from "../socket";

export const SocketContext = createContext({
  connected: false,
  updateConnectionStatus: () => {},
});

const SocketProvider = ({ children }) => {
  const [isSocketConnected, setSocketConnected] = useState(false);
  const [intervalIds, setIntervalIds] = useState([]);

  useEffect(() => {
    if (!isSocketConnected) {
      let intervalID = setInterval(() => {
        setSocketConnected(socketConnection.socket.connected);
      }, 500);
      setIntervalIds([...intervalIds, intervalID]);
    } else {
      intervalIds.map((id) => clearInterval(id));
    }

    return () => {
      intervalIds.map((id) => clearInterval(id));
    };
  }, [isSocketConnected]);

  return (
    <SocketContext.Provider
      value={{
        connected: isSocketConnected,
        updateConnectionStatus: setSocketConnected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
