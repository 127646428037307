import { io } from "socket.io-client";
export const SOCKETEVENTS = {
  DISCONNECT: "disconnect",
  NOTIFICATION: "NOTIFICATION",
  MESSAGE: "MESSAGE",
  READ_NOTIFICATION: "READ_NOTIFICATION",
  JOIN_GROUP: "JOIN_GROUP",
  LEAVE_GROUP: "LEAVE_GROUP",
  GROUP_MESSAGE: "GROUP_MESSAGE",
  REMOVE_GROUP_MEMBER: "REMOVE_GROUP_MEMBER",
  SINGLE_MESSAGE: "SINGLE_MESSAGE",
  BLOCK_MEMBER: "BLOCK_MEMBER",
  UNBLOCK_MEMBER: "UNBLOCK_MEMBER",
  READ_GROUP_MESSAGES: "READ_GROUP_MESSAGES",
  READ_SINGLE_MESSAGES: "READ_SINGLE_MESSAGES",
  EDIT_GROUP_DETAILS: "EDIT_GROUP_DETAILS",
  GROUP_DELETED: "GROUP_DELETED",
  GROUP_CREATED: "GROUP_CREATED",
  USER_ONLINE_STATUS: "USER_ONLINE_STATUS",
  UPDATE_COURSE_PROGRESS: 'UPDATE_COURSE_PROGRESS'
};

class SocketConnection {
  get #socketurl() {
    return `${process.env.REACT_APP_API_SOCKET_CONNECTION}`;
  }

  get #socketQuery() {
    let session = JSON.parse(localStorage.getItem("session"));
    return {
      id: session?.user_id,
      Authorization: session?.session_token,
    };
  }
  #socket = io(this.#socketurl, {
    autoConnect: false,
    query: this.#socketQuery,
  });
  get socket() {
    return this.#socket;
  }
  constructor() {}

  askNotifictionPermission(callback = () => {}) {
    // Check if the browser supports notifications
    if ("Notification" in window) {
      // Request permission for notifications
      Notification.requestPermission().then(() => callback());
    } else {
      console.log("This browser does not support notifications.");
    }
  }

  notify(title, message) {
    const notify = () =>
      new Notification(title, {
        body: message,
        icon: "logo.svg",
      });
    this.askNotifictionPermission(notify);
  }

  connect() {
    let session = JSON.parse(localStorage.getItem("session"));
    if (!session?.user_id || this.#socket.connected) {
      return;
    }
    this.#socket.io.opts.query = {
      id: session?.user_id,
      Authorization: session?.session_token,
    };
    this.socket.connect();
    this.handleEvents();
    this.askNotifictionPermission();
  }

  disconnect() {
    this.socket.disconnect();
  }

  handleEvents() {
    this.socket?.on("connect", () => {
      console.log("Socket connected...");
    });
    this.socket?.on("disconnect", () => {
      console.log("Socket closed...");
      this.socket.removeAllListeners();
    });
  }

  listenInteraction(callback = () => {}) {
    this.socket.on(SOCKETEVENTS.SINGLE_MESSAGE, (message) => {
      callback({...message, event: SOCKETEVENTS.SINGLE_MESSAGE});
      const user_id = JSON.parse(localStorage.getItem("session"))?.user_id;
      if (message?.sender?._id != user_id) {        
        this.notify(message?.sender?.name, message?.message);
      }
    });
    this.socket.on(SOCKETEVENTS.GROUP_MESSAGE, (message) => {
      callback({...message, event: SOCKETEVENTS.GROUP_MESSAGE});
      const user_id = JSON.parse(localStorage.getItem("session"))?.user_id;
      if (message?.sender?._id != user_id) {        
        this.notify(message?.sender?.name, message?.message);
      }
    });
  }

  listenUserOnlineStatus(callback = () => {}) {
    this.socket.on(SOCKETEVENTS.USER_ONLINE_STATUS, callback);
  }

  removeInteraction() {
    this.socket.removeAllListeners(SOCKETEVENTS.SINGLE_MESSAGE);
    this.socket.removeAllListeners(SOCKETEVENTS.GROUP_MESSAGE);
  }

  removeUserStatusListener() {
    this.socket.removeAllListeners(SOCKETEVENTS.USER_ONLINE_STATUS);
  }
}

export default new SocketConnection();
