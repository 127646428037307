import axiosInstance from "../axios.interceptor";
import SocketConnection, { SOCKETEVENTS } from "../socket";
import { Service } from "./service";

export class InteractionService {
  static async searchDeveloper(search) {
    const url = `${Service.baseurl}/developer/chat/users?search=${search}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async listChatUsers() {
    const url = `${Service.baseurl}/developer/list-user-chats`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async listChannels(search = "") {
    const url = `${Service.baseurl}/developer/list-groups?search=${search}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async listChannelsMessage(id = "", page = 1, limit = 50) {
    const url = `${Service.baseurl}/developer/list-group-messages/${id}?page=${page}&limit=${limit}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async listSingleMessage(receiver = '', page = 1, limit = 50) {
    const sender = JSON.parse(localStorage.getItem("session"))?.user_id;
    const url = `${Service.baseurl}/developer/list-messages?sender=${sender}&receiver=${receiver}&page=${page}&limit=${limit}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async sendMessageToUser(data) {
    const url = `${Service.baseurl}/developer/send-single-message`;
    const payload = {
      sender: JSON.parse(localStorage.getItem("session"))?.user_id,
      receiver: data?.receiver,
      type: 1,
      message: data?.message,
      attachments: data?.attachments
    };
    const response = await axiosInstance.post(url, payload);
    return response.data;
  }

  static async sendMessageToChannel(data) {
    const url = `${Service.baseurl}/developer/send-group-message`;
    const payload = {
      sender: JSON.parse(localStorage.getItem("session"))?.user_id,
      group: data?.receiver,
      type: 1,
      message: data?.message,
      attachments: data?.attachments
    };
    const response = await axiosInstance.post(url, payload);
    return response.data;
  }

  static async joinChannel(id) {
    const url = `${Service.baseurl}/developer/join-group`;
    const data = {
      sender: JSON.parse(localStorage.getItem("session"))?.user_id,
      group: id,
    };
    const response = await axiosInstance.post(url, data);
    return response.data;
  }

  static async readMessage(receiver, type) {
    if (type != 'message' && type != 'channels') {
      throw 'Invalid message type.'
    }
    const sender = JSON.parse(localStorage.getItem("session"))?.user_id;
    if (!sender) {
      return;
    }
    const payload = {
      sender: sender,
      receiver: receiver,
      group: receiver
    };
    const event = type == 'message' ? SOCKETEVENTS.READ_SINGLE_MESSAGES : SOCKETEVENTS.READ_GROUP_MESSAGES;
    SocketConnection.socket.emit(event, payload);
    return true;
  }
}
