import React from "react";
import Skeleton from "react-loading-skeleton";

const ChatMessageSkeleton = ({ length = 3 }) => {
  return (
    <div className="basis-2/3 h-screen max-h-[calc(767px-48px)] flex flex-col relative">
    <div className="w-full pt-10 px-10">
      {new Array(length).fill(1).map((el, index) => (
        <div key={index}>
          <div className="w-full mb-4 flex justify-end">
            <div className="flex items-start flex-end w-full">
              <div className="text-right w-full ">
                <h6 className="mb-5 w-32 h-2 ml-auto">
                  <Skeleton />
                </h6>

                <div className=" text-xs leading-4 rounded-lg rounded-tr-none">
                  <Skeleton
                    className="w-full max-w-[80%] h-[40px] px-4 py-3"
                    containerClassName="w-full inline-block"
                  />
                </div>
              </div>

              <div className="ml-3">
                <Skeleton className="w-8 h-8 min-w-8 " circle={true} />
              </div>
            </div>
          </div>

          <div className="w-full flex justify-start mb-4">
            <div className="w-full flex items-start">
              <div className="mr-3">
                <Skeleton className="w-8 h-8 min-w-8 " circle={true} />
              </div>

              <div className="w-full">
                <h6 className="mb-5 w-32 h-2 ">
                  <Skeleton />
                </h6>

                <div className=" w-full max-w-[80%] ">
                  <Skeleton
                    className="h-10 w-full"
                    containerClassName="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ChatMessageSkeleton;
