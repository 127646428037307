import React, { useEffect, useState } from "react";
import ChatList from "./ChatList";
import { InteractionService } from "../../Services/interactionService";
import { toast } from "react-toastify";
import socketConnection from "../../socket";
import AuthModal from "../../components/AuthModal";
import Chat from "./Chat";

const Interact = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const activeChatId = searchParams.get('id');
  const type = searchParams.get('type');
  const [toggleModelSearch, setToggleModelSearch] = useState(false);
  const session = JSON.parse(localStorage.getItem("session"))?.session_token;
  const [interaction, setInteraction] = useState({
    contacts: [],
    groups: [],
    activeChat: {},
    type: type == 'message' || type == 'channels' ? type : "message", // message | channels,
    contactLoader: false,
    groupLoader: false
  });

  useEffect(() => {
    fetchContactList();
    fetchGroupList();
    return () => {
      socketConnection.removeUserStatusListener();
    };
  }, []);

  useEffect(() => {
    socketConnection.removeUserStatusListener();
    listenUserOnlineStatus();
  }, [interaction.activeChat]);

  useEffect(() => {
    if (!interaction.activeChat || JSON.stringify(interaction.activeChat) == '{}') {
      const key = type == 'message' ? 'receiver' : '_id';
      const list = type == 'message' ? interaction.contacts : interaction.groups;
      const activeChat = list.find(el => activeChatId && (el[key] == activeChatId));
      if (activeChat) {
        setInteraction((prevState) => ({
          ...prevState,
          activeChat: activeChat
        }))
      }
    }
  }, [interaction.contacts, interaction.groups]);

  function listenUserOnlineStatus() {
    const mapList = (prevState, message) => prevState.contacts?.map((user) => {
      return user?.receiver == message?.user_id
        ? {
            ...user,
            online: message?.online,
            last_seen: message?.last_seen,
          }
        : user;
    });

    socketConnection.listenUserOnlineStatus(async (message) => {
      if (interaction.activeChat?.receiver == message?.user_id) {
        setInteraction((prevState) => ({
          ...prevState,
          activeChat: {
            ...prevState.activeChat,
            online: message?.online,
            last_seen: message?.last_seen,
          },
        }));
      }

      setInteraction((prevState) => ({
        ...prevState,
        contacts: interaction.type == 'message' ? mapList(prevState, message) : prevState.contacts,
      }));
    });
  }

  const setActiveChat = (chat) => {
    const mapList = (prevState, key) => prevState[key].map((el) => {
      return el?.receiver == chat?.receiver
        ? {
            ...el,
            unread_count: 0,
          }
        : el;
    });
    setInteraction((prevState) => ({
      ...prevState,
      activeChat: {
        ...chat,
        unread_count: 0,
      },
      contacts: interaction.type == 'message' ? mapList(prevState, 'contacts') : prevState.contacts,
      groups: interaction.type == 'channels' ? mapList(prevState, 'groups') : prevState.groups,
    }));
  };

  const setType = (type) => {
    setInteraction((prevState) => ({
      ...prevState,
      type,
      activeChat: {}
    }));
  };

  const setContactsList = (list, activeChat = {}) => {
    setInteraction((prevState) => ({
      ...prevState,
      contacts: list,
      activeChat: activeChat,
      chatMessages: [],
    }));
  };

  const setGroupsList = (list, activeChat = {}) => {
    setInteraction((prevState) => ({
      ...prevState,
      groups: list,
      activeChat: activeChat,
      chatMessages: [],
    }));
  };

  async function fetchContactList() {
    try {
      if (!session) {
        return;
      }
      setLoader(true, 'message');
      const channels = await InteractionService.listChatUsers();
      setContactsList(channels?.data);
      setLoader(false, 'message');
    } catch (error) {
      toast.error("Failed to list chat users.");
      setLoader(false, 'message');
    }
  }

  async function fetchGroupList() {
    try {
      if (!session) {
        return;
      }
      setLoader(true, 'channels');
      const developers = await InteractionService.listChannels();
      setGroupsList(developers?.data);
      setLoader(false, 'channels');
    } catch (error) {
      toast.error("Failed to list chat users.");
      setLoader(false, 'channels');
    }
  }

  async function setLoader(state, type) {
    setInteraction((prevState) => ({
      ...prevState,
      contactLoader: type == 'message' ? state : prevState.contactLoader,
      groupLoader: type == 'channels' ? state : prevState.groupLoader
    }));
  }

  return (
    <div className="flex overflow-hidden relative h-full flex-col md:flex-row ">
      <>
        {/* Chat Side Navigation */}
        <ChatList
          interaction={interaction}
          setContactsList={setContactsList}
          changeActiveChat={setActiveChat}
          toggleModelSearch={toggleModelSearch}
          setToggleModelSearch={setToggleModelSearch}
          changeType={setType}
          setLoader={setLoader}
        />
        {/* End Of Chat Side Navigation */}

        {/* Chat */}
        <Chat
          interaction = {interaction}
          toggleModelSearch={toggleModelSearch}
          setToggleModelSearch={setToggleModelSearch}
          changeType={setType}
          setContactsList={setContactsList}
          setGroupsList={setGroupsList}
          fetchGroupList={fetchGroupList}
          fetchContactList={fetchContactList}
        />
        {/* End Of Chat */}
      </>

      {!session && (
        <AuthModal message="Please log in to start contributing to the L1X Development community." subtext="No registration required to build on l1X" />
      )}
    </div>
  );
};

export default Interact;
