import React, { useContext, useState } from "react";
import signupBg from "./../../assets/images/signup-bg.jpg";
import layeroneX from "./../../assets/images/layerone-x.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, Eye, EyeOff } from "react-feather";
import { AuthService } from "../../Services/authService";
import { toast } from "react-toastify";
import socketConnection from "../../socket";
import UserContext from "../../providers/UserContext";

const Login = () => {
  const [passowrdToggle, setPassowrdToggle] = useState(false);
  const [errors, setErrors] = useState({});
  const { user, updateDeveloper, logout } = useContext(UserContext);
  const location = useLocation();
  const { redirect } = location.state || "/information";
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
    loader: false,
  });

  async function handleFormSubmit(e) {
    try {
      e.preventDefault();
      const validForm = validateForm();
      if (!validForm) {
        return;
      }
      setForm((prevState) => ({
        ...prevState,
        loader: true,
      }));
      let response = await AuthService.login(form);

      localStorage.setItem("session", JSON.stringify(response?.data?.session));
      /* get user Details */
      let userDetails = {
        isLogin: true,
        name: response?.data?.user?.name || "",
        email: response?.data?.user?.email || "",
        mobile: response?.data?.user?.phone_number || "",
        profile: response?.data?.user?.profile_picture || "",
      };
      localStorage.setItem("user", JSON.stringify(userDetails));
      updateDeveloper(userDetails);

      socketConnection.connect();
      return navigate(redirect ?? "/");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to login.");
    } finally {
      setForm((prevState) => ({
        ...prevState,
        email: "",
        password: "",
        loader: false,
      }));
    }
  }

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!form.email || !form.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "Email is invalid";
      valid = false;
    }

    if (!form.password || !form.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  return (
    <div className="App has-bg md:px-10">
      <div className="md:flex md:items-center md:justify-center h-screen w-full">
        <div className="w-full max-w-screen-xl mx-auto md:max-h-[767px]  md:h-full overflow-hidden md:rounded-2xl">
          <div className="bg-white h-full md:flex">
            <div className="md:basis-2/3 hidden md:block relative">
              <div className="w-full h-full">
                <img
                  src={signupBg}
                  alt="sign up"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute flex items-center justify-center flex-col top-0 left-0 w-full h-full p-10">
                <div className="w-14 h-14 overflow-hidden mb-5">
                  <img src={layeroneX} alt="logo" />
                </div>
                <h3 className="text-white lg:text-3xl text-2xl font-light text-center">
                  Welcome to{" "}
                </h3>
                <h2 className="text-white font-bold lg:text-[42px] text-4xl  text-center">
                  L1X Developer Platform
                </h2>
              </div>
            </div>
            <div className="basis-1/3 sm:min-w-[380px] relative">
              <div className="absolute top-4 -left-6 hidden md:block">
                <Link
                  to="/"
                  className="flex items-center px-4 py-2 rounded-full bg-gray-100 text-sm"
                >
                  <ArrowLeft className="mr-2 w-5" /> Back to Home
                </Link>
              </div>
              <div className="absolute top-4 left-4 block md:hidden">
                <Link
                  to="/"
                  className="flex items-center px-2 py-2 rounded-full bg-gray-100 text-sm min-w-[40px]"
                >
                  <ArrowLeft className="w-5 mx-auto" />
                </Link>
              </div>
              <div className="md:flex items-center justify-center w-full  p-7 sm:p-10 flex-col h-screen md:h-full">
                <div className="w-full max-w-[320px] mx-auto text-center mb-8 mt-8 md:mt-0">
                  <div className="w-8 h-8 overflow-hidden mb-2 mx-auto">
                    <img src={layeroneX} alt="logo" />
                  </div>
                  <h4 className="text-black text-2xl font-semibold">
                    L1X Labs{" "}
                  </h4>
                  <h6 className="text-[#2D4665] font-medium text-sm">
                    Login to continue to use L1X Labs
                  </h6>
                </div>
                <form
                  className="w-full max-w-[320px] mx-auto"
                  onSubmit={handleFormSubmit}
                >
                  <div className="w-full relative mb-4">
                    <input
                      type="text"
                      placeholder="Email"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                      value={form.email}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="w-full relative mb-2">
                    <input
                      type={`${passowrdToggle ? `text` : `password`}`}
                      placeholder="Password"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                      value={form.password}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          password: e.target.value,
                        }))
                      }
                    />
                    <button
                      className="absolute top-[11px] right-[11px] z-10"
                      type="button"
                    >
                      {passowrdToggle ? (
                        <EyeOff
                          className="text-[#8896A6] w-6 h-6  stroke-[1px]"
                          onClick={() => setPassowrdToggle(!passowrdToggle)}
                        />
                      ) : (
                        <Eye
                          className="text-[#8896A6] w-6 h-6  stroke-[1px]"
                          onClick={() => setPassowrdToggle(!passowrdToggle)}
                        />
                      )}
                    </button>
                    {errors.password && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div className="w-full relative mb-4 text-right leading-3">
                    <Link
                      to="/forget-password"
                      className="text-sm text-[#4087F3] hover:text-black"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div className="w-full relative mb-4">
                    <button
                      className="border border-[#4087F3] px-4 py-3 text-white text-sm w-full rounded-lg bg-[#4087F3] hover:bg-black hover:border-black"
                      type="submit"
                      disabled={form.loader}
                    >
                      {form.loader ? "Signing In..." : "Login"}
                    </button>
                  </div>
                  <div className="w-full relative mb-4 text-center leading-3">
                    <div className="mb-2 w-full">
                      <h4 className="text-sm text-[#8896A6] hover:text-black ">
                        Don’t have an account?
                      </h4>
                    </div>
                    <div className="w-full">
                      <Link
                        to="/register"
                        className="text-sm text-[#4087F3] hover:text-black border-b border-b-[#4087F3] hover:border-b-black pb-[2px]"
                      >
                        Create an Account
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
