import React, { useEffect, useRef, useState } from "react";
import courses from "./../../assets/images/courses.jpg";
import {
  Calendar,
  ChevronDown,
  Delete,
  Edit,
  Image,
  MoreVertical,
  Trash,
  Trash2,
} from "react-feather";
import Loader from "../../components/Loader";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Logo from "./../../assets/images/layerone-x.svg";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { SessionService } from "../../Services/sessionService";
import { toast } from "react-toastify";
import moment from "moment";
import { FileService } from "../../Services/fileService";
import eventImage from "./../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import defaultImg from "./../../assets/images/default.png";
import Swal from 'sweetalert2';

const EventPanel = () => {
  const [showHide, setShowHide] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [prevImage, setPrevImage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [eventIdDetails, setEventIdDetails] = useState('');
  const [loaderStatusName, setLoaderStatusName] = useState('');
  const [authCode, setAuthCode] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [streamYardVideo, setStreamYardVideo] = useState('');
  const [streamyardEventList, setStreamyardEventList] = useState([]);

  const [form, setForm] = useState({
                              eventName:"",
                              url:"",
                              dateTime: "",
                              image: "",
                              description: "",
                            });
        
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("eventAuthCode")) {
      setAuthCode(localStorage.getItem("eventAuthCode"));
      getEventList()
    } else {
      toast.error("Please enter valid auth code to connect")

      navigate("/");
    }
  }, []);     
  
  const getEventList = async (tempPage = 1) => {
    try {
      let _response = await SessionService.getAdminEventslist(tempPage,limit,localStorage.getItem("eventAuthCode"))

      if (_response?.status === true) {
        if (_response?.data?.docs) {
          let _data = [];
    
          if (tempPage != 1) {
            _data = [...streamyardEventList, ..._response?.data?.docs];
          }
          if (tempPage == 1) {
            _data = [..._response?.data?.docs];
          }

          setStreamyardEventList(_data);
        }
      }
    } catch (error) {
      console.log('error',error)
      toast.error(error?.response?.data?.message ?? 'Failed to get list.');
    }
 
  }

                      
  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!form.eventName || !form.eventName.trim()) {
      errors.eventName = "Event name is required";
      valid = false;
    }
    if (!form.url || !form.url.trim()) {
      errors.url = "Event url is required";
      valid = false;
    }
    if (form.url && !/<[^>]*>/i.test(form.url)) {
        errors.url = "Please enter valid streamyard video";
        valid = false;
    }
    if (!selectedDateTime || selectedDateTime == "") {
      errors.selectedDateTime = "Event Date-Time is required";
      valid = false;
    }
    if(form.url)
    {
      const parser = new DOMParser();
      const doc = parser.parseFromString(form.url, 'text/html');
      let iframeElement = doc.querySelector('iframe');
  
      if (!iframeElement) {
        errors.url = "Invalid streamyard video link";
        valid = false;
      }
    }

    // console.log('errors',errors,'selectedDateTime',selectedDateTime)
    // return;
    

    setErrors(errors);
    return valid;
  };

  async function handleFormSubmit(e) {
    try {
      e.preventDefault();

      if (validateForm()) {
        
            setLoader(true)
            setLoaderStatusName("Event storing, please wait...")
            let imageUrl = await uploadFile()

            /* get streamyard video url */
            const parser = new DOMParser();
            const doc = parser.parseFromString(form.url, 'text/html');

            let iframeElement = doc.querySelector('iframe');
            iframeElement.removeAttribute('style');
            
            const formattedDateTime = moment.utc(selectedDateTime).format('YYYY-MM-DDTHH:mm:ss');

            /* get auth token */
            const eventDetails = {
                "auth_code": authCode,
                "name": form.eventName,
                "event_url": iframeElement.outerHTML || "",
                "event_date": formattedDateTime,
                "thumbnail_image":imageUrl,
                "description":form.description
            }
            
            if(!isUpdate)
            {
              let response = await SessionService.createEvent(eventDetails);
               
              if(response.status == true)
              {
                await getEventList(); 
                toast.success("Event created successfully");
                resetForm(); 
                setLoader(false)
                setShowHide(false)
              } 
            }
            else
            {
              if(eventIdDetails && eventIdDetails?._id != null)
              {
                let updateResponse = await SessionService.updateEvent(eventIdDetails?._id,eventDetails);
                if(updateResponse.status == true)
                {
                  await getEventList();
                  resetForm();  
                  toast.success("Event updated successfully");
                  setLoader(false)
                  setShowHide(false)
                } 
              }
            }
       setLoader(false)
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error", error);
      // toast.error(error?.message);
      toast.error(error?.response?.data?.message ?? 'Error while storing details, please try again');
    }
  }

  const uploadFile = async () => {
    let imageUrl = "";
    if (form.image) {
      if (form.image.length > 0) {
        let images = await FileService.upload(form.image);

        if (images.status == true) {
          imageUrl = images?.data[0]?.url;
        }
      }
    } else {
      imageUrl = prevImage;
    }
    return imageUrl;
  };

  const handleDatetimeChange = (momentObj) => {
    setSelectedDateTime(momentObj);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if file size exceeds 5MB
    if (file && file.size > 10 * 1024 * 1024) {
      toast.error(
        "File size exceeds 10MB limit. Please select a smaller file."
      );
      event.target.value = null;
    } else {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setPrevImage(reader.result);
        };
        reader.readAsDataURL(file);
      }

      setForm((prevState) => ({
        ...prevState,
        image: [file],
      }));
    }
    event.target.value = "";
  };

const editEvent = async (id) => {
  
  setLoader(true);
  setLoaderStatusName("Event getting, please wait...")
  await setErrors({})
  /* get event details */
  try {
    let response = await SessionService.getEventById(id,authCode)
    if(response.status == true)
    {
      
      
      setEventIdDetails(response?.data)
      setForm({
          eventName: response?.data?.name || '',
          url: response?.data?.event_url || '',
          description: response?.data?.description || ''
      });
      setSelectedDateTime(moment.utc(response?.data?.event_date, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY hh:mm A'));
      setPrevImage(response?.data?.thumbnail_image);
      setShowHide(true)
      setLoader(false);
    }
    setLoader(false);
  } catch (error) {
    setLoader(false)
    console.log('error',error);
    toast.error(error?.response?.data?.message ?? 'Failed to get details.');
  }
 
};
const resetForm = () => {
  setIsUpdate(false);
  setForm({
    eventName:"",
    url:"",
    description: ""
  });
  setSelectedDateTime("");
  setPrevImage("");
}


const openConfirmation = (_eventId,_status) => {
  let _activeState = "";
  if(_status == 0)
  {
    _activeState = 'Activate'
  }
  else
  {
    _activeState = 'Deactivate'
  }
  Swal.fire({
    title: 'Are you sure?',
    text: `You want to ${_activeState} event`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, do it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      await updateStatus(_eventId,_status);
    }
  });
};

const updateStatus = async(_eventId,_status) => {
  try {
      setLoader(true);
      setLoaderStatusName("Event updating, please wait...")

      const eventDetails = {
          "auth_code": authCode,
          "status": _status,
          "id": _eventId 
      }

      let _response = await SessionService.updateEventStatus(eventDetails)
      if (_response?.status === true) {
          await getEventList()
          
          setLoader(false);
          Swal.fire('Event updated successfully', '', 'success');
          return true;
      }
      setLoader(false);
  } catch (error) {
    setLoader(false);
    console.log('error',error)
    toast.error(error?.response?.data?.message ?? 'Failed to update event.');
    return false;
  }
}

/* delete event */
const openDeleteConfirmation = (_eventId) => {
  Swal.fire({
    title: 'Are you sure?',
    text: `You want to delete event`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, do it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteSelectedEvent(_eventId);
   
    }
  });
};

const deleteSelectedEvent = async(_eventId) => {
  try {
      setLoader(true);
      setLoaderStatusName("Event deleting, please wait...")

      const eventDetails = {
          "auth_code": authCode,
          "id": _eventId 
      }

      let _response = await SessionService.deleteEvent(eventDetails)
      if (_response?.status === true) {
          await getEventList()
          
          setLoader(false);
          Swal.fire('Event deleted successfully', '', 'success');
          return true;
      }
      setLoader(false);
  } catch (error) {
    setLoader(false);
    console.log('error',error)
    toast.error(error?.response?.data?.message ?? 'Failed to delete event.');
    return false;
  }
}

const adminLogout = () => {
  localStorage.removeItem("eventAuthCode");

  navigate("/event-admin/auth");
  return;
}

  return (
    <div className="App has-bg px-5 sm:px-10">
      <div className="flex items-center justify-center h-screen w-full">
        <div className="w-full max-w-screen-xl mx-auto pt-5 md:max-h-[767px]  md:h-full rounded-2xl bg-white px-10 relative overflow-hidden">
          <div className="flex items-center justify-between w-full mb-3 ">
            <h3 className="text-black text-2xl font-semibold ">Events</h3>
            <button
              className="bg-[#4087F3] hover:bg-black text-white text-xs px-3 py-2 rounded-md inline-block ms-auto me-3"
              onClick={() => {setShowHide(true); setIsUpdate(false);}}
            >
              Add New Event
            </button>
            <button
              className="bg-red-500 hover:bg-black text-white text-xs px-3 py-2 rounded-md inline-block"
              onClick={() => {adminLogout()}}
            >
             Logout
            </button>
          </div>
          <div className="grid grid-cols-4 gap-5  overflow-y-auto  md:max-h-[670px]   ">
            {/* {new Array(10).fill(1).map(() => ( */}
            {streamyardEventList?.length > 0 && streamyardEventList.map ((streamyardEvent) => (
              <div className="w-full relative  border border-[#E9EDF3] rounded-lg px-2 py-3 ">
                <div className="mb-2 flex items-center justify-between">
                  <h4 className="text-[#222D3A] text-sm leading-5 mb-1 font-medium">
                    {streamyardEvent?.name && streamyardEvent?.name?.length > 25 
                  ? streamyardEvent?.name.substring(0,25)+'...' : streamyardEvent?.name}
                  </h4>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium focus:outline-none">
                        <MoreVertical className="-mr-1 ml-2 h-4 w-4" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 w-28 origin-top-right rounded-md bg-white shadow-xl focus:outline-none py-2 border border-slate-200">
                        <button className=" flex w-full items-center rounded-md px-4 py-1 text-xs hover:text-[#4087F3]"
                          onClick={() =>{openConfirmation(streamyardEvent?._id,streamyardEvent?.status)}}>
                          {streamyardEvent?.status === 0 ? 'Activate' : 'Deactivate'}
                        </button>

                        <button onClick={() => {editEvent(streamyardEvent?._id); setIsUpdate(true)}}  className=" flex w-full items-center rounded-md px-4 py-1 text-xs hover:text-[#4087F3]">
                          Edit
                        </button>
                        <button
                          onClick={() =>{openDeleteConfirmation(streamyardEvent?._id)}}
                         className=" flex w-full items-center rounded-md px-4 py-1 text-xs hover:text-[#4087F3]">
                          Delete
                        </button>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <img
                  src={streamyardEvent?.thumbnail_image || defaultImg}
                  className="w-full h-40 object-cover rounded-md mb-2"
                  // alt={streamyardEvent?.thumbnail_image ? eventImage : "Event"}
                  // alt="Event Image"
                />

                <h6 className="text-[#8896A6] text-xs  font-normal flex items-start mb-2">
                  {streamyardEvent?.description && streamyardEvent?.description?.length > 100 
                  ? streamyardEvent?.description.substring(0,100) : streamyardEvent?.description}
                </h6>

                <h5 className="text-[#8896A6] text-[10px] leading-3 font-normal flex items-center">
                  <Calendar className="w-3 h-3 text-[#8896A6] mr-1 " /> 
                  {streamyardEvent?.event_date && moment.utc(streamyardEvent?.event_date).format('DD MMM YYYY, hh:mm A')}
                </h5>
              </div>
            ))}
          </div>

          {showHide && (
            <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-[5px]">
              <div className="bg-white w-[600px] px-6 rounded-2xl py-10">
                <div className="text-center">
                  <h4 className="text-lg font-semibold mb-4 flex items-center justify-center">
                    <img src={Logo} className="w-5 mr-2" /> Enter event details
                  </h4>
                  <div className="flex">
                    <div className="basis-1/3 ">
                      <div className=" relative mb-4 inline-flex w-full h-[120px] mx-auto overflow-hidden group ">
                        {prevImage ? (
                          <>
                            <img
                              src={prevImage || ""}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            <button
                              className=" group-hover:flex  absolute top-0 right-0 w-full h-[120px] hidden items-center justify-center bg-black/70 rounded-lg "
                              onClick={() => {setPrevImage(""); 
                                                setForm({
                                                    eventName:form.eventName,
                                                    url:form.url,
                                                    image: "",
                                                    description:form.description,
                                                    dateTime:selectedDateTime
                                                }
                                              )}}
                            >
                              <Trash2 className="text-white w-6 h-6 " />{" "}
                            </button>
                          </>
                        ) : (
                          <label class="border w-full h-[120px] p-4 text-black text-sm rounded-lg  bg-slate-100 border-slate-200 mx-auto flex items-center justify-center">
                            <span class="text-sm leading-normal cursor-pointer  font-medium">
                              <div className="text-center">
                                <Image className="text-slate-600 w-6 h-6 mb-2 mx-auto" />
                              </div>
                              Upload Thumbnail
                            </span>
                            <input
                              type="file"
                              class="hidden"
                              accept=".jpg,.jpeg,.png"
                              multiple={false}
                              onChange={handleFileChange}
                            />
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="basis-2/3 ps-5">
                      <form className="w-full " onSubmit={handleFormSubmit}>
                        <div className="w-full relative mb-4">
                          <input
                            type="text"
                            placeholder="Event Name"
                            className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                            value={form.eventName}
                            onChange={(e) =>
                              setForm((prevState) => ({
                                ...prevState,
                                eventName: e.target.value,
                              }))
                            }
                          />
                          {errors.eventName && (
                            <p className="text-red-500 text-xs mt-1 text-left">
                              {errors.eventName}
                            </p>
                          )}
                        </div>
                        <div className="w-full relative mb-4">
                          <textarea
                            type="text"
                            placeholder="Event URL"
                            className="resize-none border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                            value={form.url}
                            onChange={(e) =>
                              setForm((prevState) => ({
                                ...prevState,
                                url: e.target.value,
                              }))
                            }
                          />
                          {errors.url && (
                            <p className="text-red-500 text-xs mt-1 text-left">
                              {errors.url}
                            </p>
                          )}
                        </div>

                        <div className="w-full relative mb-4 inline-flex flex-wrap  ">
                         
                          <Datetime
                            utc={true}
                            className="custom-calender z-10"
                            value={selectedDateTime}
                            onChange={handleDatetimeChange}
                          />
                          <button
                            type="button"
                            className="absolute top-[13px] right-[15px] z-[0]"
                          >
                            <Calendar className="text-[#8896A6] w-5 h-5 stroke-[1.5px]" />
                          </button>
                       
                          {errors.selectedDateTime && (
                            <p className="text-red-500 text-xs mt-1 text-left">
                              {errors.selectedDateTime}
                            </p>
                          )}
                        </div>

                        <div className="w-full relative mb-4">
                          <textarea
                            rows={3}
                            type="text"
                            placeholder="Short Description"
                            className="border resize-none border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                            value={form.description}
                            onChange={(e) =>
                              setForm((prevState) => ({
                                ...prevState,
                                description: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="w-full relative ">
                          <button
                            className="border border-[#4087F3] px-4 py-3 text-white text-sm w-full rounded-lg bg-[#4087F3] hover:bg-black hover:border-black"
                            type="submit"
                          >
                            Submit
                          </button>
                          <div class="w-full mt-2">
                            <button
                              type="button"
                              class="text-sm text-black hover:text-[#4087F3] border-b border-b-black hover:border-b-[#4087F3] pb-[2px]"
                              onClick={() => {
                                setShowHide(false);
                                resetForm();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
        {loader && (
            <Loader statusName={ loaderStatusName || "Event storing, please wait..."} bgColor='bg-theme-green/20' textColor='text-theme-green'/>
        )}
    </div>
  );
};

export default EventPanel;
