import "./App.css";
import Layout from "./components/Layout";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Interact from "./pages/interact/Index";
import Information from "./pages/Information/Information";
// import Session from "./pages/session/Session";
import InformationDetails from "./pages/Information/InformationDetails";
import BlogDetails from "./pages/Information/BlogDetails";
import Login from "./pages/Auth/login";
import Register from "./pages/Auth/register";
import ForgetPassword from "./pages/Auth/forgetPassword";
import Overview from "./pages/ImprovementProtocol/overview";
import ProtocolRequests from "./pages/ImprovementProtocol/protocolRequests";
import socketConnection from "./socket";
import { useContext, useEffect, useState } from "react";
import Courses from "./pages/Courses";
import Roadmap from "./pages/Information/Roadmap";
import DeveloperDocument from "./pages/DevDocument/DeveloperDocument";
import Profile from "./pages/Auth/Profile";
import { UserProvider } from "./providers/UserContext";
import { SocketContext } from "./providers/SocketProvider";
import CategoryVideos from "./pages/Courses/CategoryVideos";
import EmailVerified from "./pages/Auth/EmailVerified";
import ResetPassword from "./pages/Auth/ResetPassword";
import Learn from "./pages/Courses/Learn";
import Session from "./pages/Session/Session";
import EventListing from "./pages/Session/EventListing";
import EventAdminLogin from './pages/EventAdmin/Auth'
import EventAdminPanel from './pages/EventAdmin'
import EventDetails from "./pages/Session/EventDetails";
import MyLearning from "./pages/Courses/MyLearning";
import ComingSoon from "./pages/ComingSoon";
import { PageTextProvider } from "./providers/PageTextContext";
import LoginGuard from "./login.guard";
import Responsive from "./components/Responsive";

const route = createBrowserRouter([ 
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Information />,
      },
      {
        path: "information",
        element: <Information />,
      },
      {
        path: "information/details",
        element: <InformationDetails />,
      },
      {
        path: "developer-document",
        element: <DeveloperDocument />,
      },
      {
        path: "information/roadmap",
        element: <Roadmap />,
      },
      {
        path: "information/blog-details/:blog_id",
        element: <BlogDetails />,
      },
      {
        path: "interact",
        element: <Interact />,
      },
      {
        path: "session",
        element: <Session />,
      },
      {
        path: "session/event-list",
        element: <EventListing />,
      },
      {
        path: "session/existing-list",
        element: <EventListing />,
      },
      {
        path: "session/event/:eventId",
        element: <EventDetails />,
      },
      {
        path: "improvement-protocol/overview",
        element: <Overview />,
      },
      {
        path: "improvement-protocol/protocol-requests",
        element: <ProtocolRequests />,
      },
      {
        path: "courses",
        element: <ComingSoon />,
      },
      // {
      //   path: "courses",
      //   children: [
      //     {
      //       path: '',
      //       element: <Courses />
      //     },
      //     {
      //       path: ':id',
      //       element: <CategoryVideos />
      //     },
      //     {
      //       path: 'learn/:id',
      //       element: <Learn />
      //     },
      //     {
      //       path: 'learnigs',
      //       element: <MyLearning />
      //     }
      //   ]
      // },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "login",
    element: <LoginGuard>
      <Login />
    </LoginGuard>,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "verify-email",
    element: <EmailVerified />,
  },
  {
    path: 'create-password',
    element: <ResetPassword />
  },
  {
    path: "event-admin/auth",
    element: <EventAdminLogin />,
  },
  {
    path: "event-admin/panel",
    element: <EventAdminPanel />,
  },
]);

const App = () => {
  const socketContext = useContext(SocketContext);
  useEffect(() => {
    socketConnection.connect();
    listenSocketConnect();
    listenSocketDisconnect();    
  }, []);

  function listenSocketConnect() {
    socketConnection.socket.on("connect", () => {
      socketContext.updateConnectionStatus(true);
    });
  }

  function listenSocketDisconnect() {
    socketConnection.socket.on("disconnect", () => {
      socketConnection.socket.on("connect", () => {
        socketContext.updateConnectionStatus(true);
        socketConnection.listenInteraction(() => {});
      });
      socketContext.updateConnectionStatus(false);
      setTimeout(() => {
        socketConnection.connect();
      }, 2000);
    });
  }

  const [isLaptop, setIsLaptop] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <UserProvider>
       {/* {isLaptop  ? (<Responsive/>) :( */}
       <PageTextProvider>
        <RouterProvider router={route} />
      </PageTextProvider>
      {/* )} */}
    </UserProvider>
  );
};

export default App;
