import React, { useEffect } from "react";
import PDFFile from "./../../assets/images/pdf.png";
import DocFile from "./../../assets/images/doc.png";
import { X } from "react-feather";

const ChatMediaPreview = ({
  attachments = [],
  removeAttachment = (index) => {},
  loader = false,
  device = 'desktop'
}) => {
  useEffect(() => {
    console.log(attachments);
  }, []);
  function displayImage(file, elementId) {
    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        var imageContainer = document.getElementById(elementId);
        if (imageContainer) {
          const img = document.createElement('img');
          img.src = e.target.result;
          img.classList.add('w-full', 'h-full', 'rounded-lg', 'object-cover');
          imageContainer.innerHTML = '';
          imageContainer.appendChild(img);
        }
      };

      reader.readAsDataURL(file);
    }
  }
  return (
    <div className="w-full p-4 rounded-t-lg">
      <div className="flex flex-wrap gap-4">
        {attachments?.map((attachment, index) =>
          attachment?.type == "image/jpeg" ? (
            <div className=" w-12 h-12 md:w-28 md:h-28 rounded-lg relative p-1 md:p-2 border border-gray-200 bg-gray-100 overflow-hidden">
              {/* <img src={attachment?.data} className="w-full h-ull rounded-lg" /> */}
              <div
                className="h-8 md:h-28 max-h-[96px] overflow-hidden rounded-lg"
                id={`imageContainer_${device}_${index}`}
              >
                {displayImage(attachment, `imageContainer_${device}_${index}`)}
              </div>
              <button
                className="bg-white p-1 w-5 h-5 md:w-6 md:h-6 rounded-full absolute top-0 right-0 md:top-1 md:right-1 flex items-center justify-center shadow-lg"
                onClick={() => removeAttachment(index)}
              >
                <X className="w-4" />
              </button>

              {loader && (
                <div className="loading-space absolute top-12 left-1/2 -translate-x-1-2 -translate-y-1-2 ">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          ) : attachment?.type == "application/pdf" ? (
            <div className="w-28 h-28 rounded-lg relative p-2 border border-gray-200 bg-gray-100">
              <img src={PDFFile} className="w-10 mb-2" />
              <div>
                <p className="truncate text-xs mb-1">{attachment?.name}</p>
                <div className="grid grid-cols-1">
                  <p className="text-xs truncate">
                    {(attachment?.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                  {/* <p className="text-xs ml-2">{attachment?.type}</p> */}
                </div>
              </div>
              <button
                className="bg-white p-1 w-6 h-6 rounded-full absolute top-1 right-1 flex items-center justify-center shadow-lg"
                onClick={() => removeAttachment(index)}
              >
                <X className="w-4" />
              </button>

              {loader && (
                <div className="loading-space absolute top-12 left-1/2 -translate-x-1-2 -translate-y-1-2 ">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          ) : (
            <div className="w-28 h-28 rounded-lg relative p-2 border border-gray-200 bg-gray-100">
              <img src={DocFile} className="w-10 mb-2" />
              <div>
                <p className="truncate text-xs mb-1">{attachment?.name}</p>
                <div className="grid grid-cols-1">
                  <p className="text-xs ">
                    {(attachment?.size / 1024).toFixed(2)} MB
                  </p>
                  {/* <p className="text-xs ml-2">Document</p> */}
                </div>
              </div>
              <button
                className="bg-white p-1 w-6 h-6 rounded-full absolute top-1 right-1 flex items-center justify-center shadow-lg"
                onClick={() => removeAttachment(index)}
              >
                <X className="w-4" />
              </button>

              {loader && (
                <div className="loading-space absolute top-12 left-1/2 -translate-x-1-2 -translate-y-1-2 ">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ChatMediaPreview;
