import axiosInstance from "../axios.interceptor";
// import SocketConnection, { SOCKETEVENTS } from "../socket";
import { Service } from "./service";

export class UserService {
    
    static async getUser() {
        const userId = JSON.parse(localStorage.getItem("session"))?.user_id;

        const url = `${Service.baseurl}/developer/details/${userId}`;
        const response = await axiosInstance.get(url);
        return response.data;
    }

    static async updateUser(data) {
        const userId = JSON.parse(localStorage.getItem("session"))?.user_id;
        const url = `${Service.baseurl}/developer/update/${userId}`;
        const payload = data;

        const response = await axiosInstance.patch(url, payload);
        return response.data;
      }
}
