import React, { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import Skeleton from "react-loading-skeleton";
import { Link, useParams,useNavigate, useLocation } from "react-router-dom";
import { SessionService } from "../../Services/sessionService";
import { toast } from "react-toastify";
import { YoutubeService } from "../../Services/youtubeService";
import moment from "moment";

const EventDetails = () => {
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState('');
  const [youtubeVideoDetails, setYoutubeVideoDetails] = useState('');
  const [isEventDetails, setIsEventDetails] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const session = JSON.parse(localStorage.getItem("session"))?.user_id;

  const { isYoutube } = location.state || false;
  const arrYoutubeDescription = [
    [
      "In Episode IV of the L1X Deep Dive Series, Kevin Coutinho and Dr. Neeraj Khairwal analyze fee structures across different cryptocurrency networks, explore decentralized contract updates, and delve into transaction tokenomics. Dr. Neeraj shares valuable insights into transaction costs and economics, underscoring their significance. Kevin discusses the adaptability of opcode tables and upcoming initiatives like username NFTs.  ",
      "<a class='text-blue-600' target='_blank' href='https://blog.l1x.foundation/sum-up-of-the-22-03-2024-l1x-deep-dive-block-rewards-and-tx-fees/'>https://blog.l1x.foundation/sum-up-of-the-22-03-2024-l1x-deep-dive-block-rewards-and-tx-fees/</a>",
    ],
    [
      "Join Kevin Coutinho as he explores the transformative power of the L1X Virtual Machine (L1X VM). Discover how this innovative technology ensures secure and efficient smart contract execution, setting new standards in blockchain technology. Explore its pivotal role in maintaining transaction integrity and driving the evolution of decentralized networks. Dive into the AMA for detailed insights into the L1X VM's functionalities and community discussions. ",
      "<a class='text-blue-600' target='_blank' href='https://blog.l1x.foundation/sum-up-of-15-03-2024-l1x-deep-dive-l1x-virtual-machine/'>https://blog.l1x.foundation/sum-up-of-15-03-2024-l1x-deep-dive-l1x-virtual-machine/</a>",
    ],
    [
      "Explore the intricate details of Layer One X's groundbreaking consensus mechanism in the second episode of the L1X Deep Dive series.  ",
      "Join Kevin Coutinho and Dr. Neeraj as they dissect the architecture, networking strategies, and fee structures driving blockchain innovation within the L1X ecosystem. From event emission to mobile validation, uncover the future of decentralized technology with L1X.",
      "<a target='_blank' class='text-blue-600' href='https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-consensus-mechanism/'> https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-consensus-mechanism/</a> ",
    ],
    [
      "Dive into the world of blockchain interoperability with Layer One X (L1X) Deep Dive series' inaugural episode. ",
      "Kevin Coutinho, L1X Founder and Mohammad Atef, L1X developer, unravel the mysteries of smart chain connectivity, exploring its historical roots, current innovations, and future potential. Join them on this enlightening journey through the evolution of decentralized technology.",
      "<a class='text-blue-600'  target='_blank' href='https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-xtalk/'> https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-xtalk/</a> ",
    ], 
    [
      "Discover Layer One X (L1X) and its revolutionary X-Talk technology with these key points:",
      "<b>L1X Protocol:</b> Delve into the unique features of the Layer One X protocol, purposefully designed to empower decentralized applications (DApps) and smart contracts.",
      "<b>X-Talk Mechanics:</b> Explore the intricacies of X-Talk, an innovative solution revolutionizing cross-chain interactions, and eliminating the need for conventional bridges.",
      "<b>Quick Contract Deployment:</b> Learn the streamlined process for rapid and effective smart contract deployment on the Layer One X platform."
    ]
  ];

  const [youtubeDescription, setYoutubeDescription] = useState('');

  useEffect(() => {
      (async () => {
        if(isYoutube)
        {
          getYoutubeDetails()
          setIsEventDetails(false)
        }else
        {
          setIsEventDetails(true);
          await getEventDetails()
        }
      })()
  }, []);

  const getYoutubeDetails = async() => 
  {
    try {
      setLoader(true);
      const response = await YoutubeService.getVideoDetails(eventId);
      
      // console.log('response?.items[0]',response?.items[0])
      if(response?.items[0])
      {
        if(response?.items[0]?.id == '1b_8b5rpWnU')
        {
          setYoutubeDescription(arrYoutubeDescription[0])
        }
        else if(response?.items[0]?.id == 'f1zh5rNvQIc')
        {
          setYoutubeDescription(arrYoutubeDescription[1])
        }
        else if(response?.items[0]?.id == '3vEFfSoTUGk')
        {
          setYoutubeDescription(arrYoutubeDescription[2])
        }
        else if(response?.items[0]?.id == 'jGBLAgPGqbc')
        {
          setYoutubeDescription(arrYoutubeDescription[3])
        }
        else if(response?.items[0]?.id == '3sksI3OgpSw')
        {
          setYoutubeDescription(arrYoutubeDescription[4])
        }

        setLoader(false);
        setYoutubeVideoDetails(response?.items[0]);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message ?? "Failed to load courses.");
    }
  }

  const goBack = () => {
    navigate(-1);
  };

  const getEventDetails = async () => {
    try {
      setLoader(true)
      if(eventId != undefined)
      {
        let eventDetails = {
          'id': eventId,
          "user_id":session
        }
        let _response = await SessionService.getEventsDetails(eventDetails);
        setLoader(false)
        if (_response?.status === true) {
          if(_response?.data[0]?.event_end_date)
          {
            let datePass = await isDatePassed(_response?.data[0]?.event_end_date)
            if(!datePass)
            {
              if(_response?.data[0]?.hasBooking == false)
              {
                toast.error("You are not authorized to view event, please reserve your spot");
                navigate("/session")
              }
            }
          }
          // console.log('_response?.event_url',_response?.data?.event_url)
          const convertedUrl = _response?.data[0]?.event_url.replace(/\\&quot;/g, '') // Replace \&quot; with "
                                  .replace(/\\\"/g, '"');

          console.log('convertedUrl',convertedUrl)
          setIframeUrl(convertedUrl)

          setEventDetails(_response?.data[0]);
        }
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
      toast.error(error?.message ?? "Failed to get event details");
      
    }
    
  
  }

  const isDatePassed = (dateString) => {
    const givenDate = new Date(dateString);
    const currentUTC = new Date();
    console.log(givenDate,'givenDate',currentUTC);
    return givenDate.getTime() < currentUTC.getTime();
  };



  return (
    <div className="inner-content-wrapper p-6 lg:py-9 px-6 lg:px-12 overflow-y-auto z-10  relative md:max-h-[calc(767px-48px)]">
      <button
        className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mr-2 mb-4"
        onClick={goBack}
      >
        <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
      </button>
      {!loader ? (
        <>
          {isEventDetails ? (
              <>
              <div className="w-full">
                  <div className="flex items-center justify-between mb-4">
                    <h1 className="text-xl md:text-2xl font-semibold  mr-5">
                      {/* Existing events are going on. */}
                      {eventDetails && eventDetails?.name || ""}
                    </h1>
                    <span className=" bg-slate-200 text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium  mt-3 md:mt-0">
                      {/* Date : Jun. 10 – 12, 2024 */}
                      Date : {eventDetails?.event_date && moment.utc(eventDetails?.event_date).format("DD MMM YYYY, hh:mm A")}
                    </span>
                  </div>
                  <div className="w-full h-auto mb-4">
                    {/* <iframe
                      width="100%"
                      height=""
                      src="https://www.youtube.com/embed/WZ5JpnmZJcw"
                      title="XTalks FAQs #episode 41. Validator &amp; Node Hosting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      className="aspect-video"
                      allowfullscreen
                    ></iframe> */}
                    {/* {eventDetails && eventDetails?.event_url || ""} */}
                    <div className="w-full h-auto mb-4 aspect-video" dangerouslySetInnerHTML={{ __html: iframeUrl }} />
                    {/* <div className="w-full h-auto mb-4 aspect-video"  dangerouslySetInnerHTML={{ __html: '<iframe src="\&quot;https://streamyard.com/watch/pzVXGEKnxFaC?embed=true\&quot;" width="\&quot;100%\&quot;" height="\&quot;100%\&quot;" frameborder="\&quot;0\&quot;" allow="\&quot;autoplay;" fullscreen\"=""></iframe>' }} /> */}
                    {/* <div className="w-full h-auto mb-4 aspect-video"  dangerouslySetInnerHTML={{ __html: '<iframe src="https://streamyard.com/watch/FyXnWET6XbwJ?embed=true" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen"></iframe>' }} /> */}
                  </div>
                  <div className="w-full h-auto mt-6 ">
                    <p className="text-sm text-gray-600 mb-4 leading-loose">
                    {eventDetails && eventDetails?.description || ""}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
           
                <div className="w-full">
                  <div className="md:flex items-center justify-between mb-4">
                    <h1 className="text-xl md:text-2xl font-semibold  mr-5">
                      {/* Existing events are going on. */}
                      {youtubeVideoDetails?.snippet?.title}
                    </h1>
                    <span className=" bg-slate-200 text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium whitespace-nowrap mt-3 md:mt-0">
                      Date : {youtubeVideoDetails?.snippet?.publishedAt &&
                              moment
                                .utc(youtubeVideoDetails?.snippet?.publishedAt)
                                .format("DD MMM YYYY, hh:mm A")}
                    </span>
                  </div>
                  <div className="w-full h-auto mb-4 aspect-video" dangerouslySetInnerHTML={{ __html: youtubeVideoDetails?.player?.embedHtml }}>
                    {/* <iframe
                      width="100%"
                      height=""
                      src="https://www.youtube.com/embed/WZ5JpnmZJcw"
                      title="XTalks FAQs #episode 41. Validator &amp; Node Hosting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      className="aspect-video"
                      allowfullscreen
                    ></iframe> */}
                    {/* {youtubeVideoDetails?.player?.embedHtml} */}
                    {/* <div dangerouslySetInnerHTML={{ __html: youtubeVideoDetails?.player?.embedHtml }} /> */}
                  </div>
                  <div className="w-full h-auto mt-6">
                    {
                      youtubeDescription && youtubeDescription.map((content, index)=> {
                        return(
                    <p key={index} className="text-sm text-gray-600 mb-4 leading-loose" dangerouslySetInnerHTML={{ __html: content }}>
                      {/* {youtubeVideoDetails?.snippet?.description} */}
                     
                    </p>

                        )
                      })
                    }
                  </div>
                </div>
              </>
            )}
        </>
        ) :  (
        <>
            <div className="w-full">
            <div className="flex items-center justify-between mb-4">
              <Skeleton className="w-full h-8 min-w-[300px] mr-5" />
              <Skeleton className="w-full h-8 min-w-[200px]"  />
            </div>
            <div className="w-full h-auto mb-4">
            <Skeleton className="w-full h-[520px]"  />
            </div>
            <div className="w-full h-auto ">
            <Skeleton className="w-full" count={4}  />
            </div>
          </div>
        </>
        )}


      
    </div>
  );
};

export default EventDetails;
