import React from "react";
import ChatUser from "./../assets/images/user.jpg";

const ChatUserCard = ({ user, changeActiveChat, activeChat, type }) => {
  return (
    <div
      className={`md:max-w-[343px] flex items-center justify-between w-full px-5 py-2 cursor-pointer ${
        (user?._id ?? user?.receiver) ==
        (activeChat?._id ?? activeChat?.receiver)
          ? "bg-blue-100"
          : ""
      }`}
      onClick={() => changeActiveChat(user)}
      key={user?._id ?? user?.receiver}
    >
      <div className="mr-3 rounded-full overflow-hidden w-11 h-11 min-w-11 inline-flex items-center justify-center">
        <img
          src={user?.profile_picture || ChatUser}
          alt="user icon"
          className="w-10 h-10 rounded-full object-cover"
        />
      </div>

      <div className="w-full">
        {/* <h4 className="flex justify-between text-sm text-[#717171] font-medium mb-1"> for active */}
        <h4 className="flex justify-between text-sm text-[#333333] font-semibold mb-1 truncate">
          <p className="truncate max-w-[150px]"> {user?.name}</p>
          {type == "message" ? (
            <span className="text-[#A0A0A0] text-xs ms-auto font-normal truncate">
              {user?.created_at && new Date(user?.created_at)?.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                // hour12: true,
                // hour: "2-digit",
                // minute: "2-digit",
              })}
            </span>
          ) : (
            ""
          )}
        </h4>
        <div className="flex items-center">
          {/* <CornerUpLeft className="w-3 h-3 text-[#A0A0A0] mr-1" /> */}
          <h6 className="text-xs text-[#A0A0A0] font-normal truncate max-w-52 me-2" dangerouslySetInnerHTML={{__html: user?.message }}>
          </h6>
          {user?.unread_count > 0 && (
            <span className="bg-[#4087F3] px-1 h-4 min-w-4 text-white text-xs rounded-full inline-flex leading-loose items-center justify-center ms-auto">
              {user.unread_count}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatUserCard;
