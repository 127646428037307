import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import fetchExternalData from "../../Services/fetchExternalData";
import { ArrowLeft, Calendar, XCircle } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { YoutubeService } from "../../Services/youtubeService";
import { toast } from "react-toastify";
import { SessionService } from "../../Services/sessionService";
import News1 from "../../assets/images/default.png";
import moment from "moment";
import AuthModal from "../../components/AuthModal";
import Lottie from "lottie-react";
import completedAnimation from "../../assets/images/completed-animation.json";

const EventListing = () => {
  const [infoCmsPosts, setInfoCmsPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [divHeight, setDivHeight] = useState(0);
  const myDivRef = useRef(null);
  const location = useLocation();
  const [pastYoutubeVideo, setPastYoutubeVideo] = useState([]);
  const [streamyardEventList, setStreamyardEventList] = useState([]);
  const session = JSON.parse(localStorage.getItem("session"))?.user_id;
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("Events");
  const [isEventList, setIsEventList] = useState(false);
  const navigate = useNavigate();
  const [bookingShowHide, setBookingShowHide] = useState(false);
  const [isEnroll, setIsEnroll] = useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = useState(false);

  const [bookingObject, setBookingObject] = useState({
    eventId: "",
    eventName: "",
    eventDate: "",
    eventImg: "",
  });

  const arrYoutubeDescription = [
    [
      "Dive into the world of blockchain interoperability with Layer One X (L1X) Deep Dive series' inaugural episode. ",
      "Kevin Coutinho, L1X Founder and Mohammad Atef, L1X developer, unravel the mysteries of smart chain connectivity, exploring its historical roots, current innovations, and future potential. Join them on this enlightening journey through the evolution of decentralized technology.",
      "https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-xtalk/ ",
    ],
    [
      "Explore the intricate details of Layer One X's groundbreaking consensus mechanism in the second episode of the L1X Deep Dive series.  ",
      "Join Kevin Coutinho and Dr. Neeraj as they dissect the architecture, networking strategies, and fee structures driving blockchain innovation within the L1X ecosystem. From event emission to mobile validation, uncover the future of decentralized technology with L1X.",
      "https://blog.l1x.foundation/sum-up-ama-l1x-deep-dive-consensus-mechanism/ ",
    ],
    [
      "Join Kevin Coutinho as he explores the transformative power of the L1X Virtual Machine (L1X VM). Discover how this innovative technology ensures secure and efficient smart contract execution, setting new standards in blockchain technology. Explore its pivotal role in maintaining transaction integrity and driving the evolution of decentralized networks. Dive into the AMA for detailed insights into the L1X VM's functionalities and community discussions. ",
      "https://blog.l1x.foundation/sum-up-of-15-03-2024-l1x-deep-dive-l1x-virtual-machine/ ",
    ],
    [
      "In Episode IV of the L1X Deep Dive Series, Kevin Coutinho and Dr. Neeraj Khairwal analyze fee structures across different cryptocurrency networks, explore decentralized contract updates, and delve into transaction tokenomics. Dr. Neeraj shares valuable insights into transaction costs and economics, underscoring their significance. Kevin discusses the adaptability of opcode tables and upcoming initiatives like username NFTs.  ",
      "https://blog.l1x.foundation/sum-up-of-the-22-03-2024-l1x-deep-dive-block-rewards-and-tx-fees/",
    ],
  ];
  const [youtubeDescription, setYoutubeDescription] = useState(
    arrYoutubeDescription
  );

  useEffect(() => {
    if (myDivRef.current) {
      const height = myDivRef.current.offsetHeight;
      setDivHeight(height);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (location.pathname?.includes("event-list")) {
        setIsEventList(true);
        setTitle("Upcoming Sessions");
        await getEventList();
      } else {
        setIsEventList(false);
        setTitle("Past Sessions");
        await getPastYoutubeList();
      }
    })();
  }, []);

  const getEventList = async (tempPage = 1) => {
    try {
      let _response = await SessionService.getEventslist(
        tempPage,
        limit,
        session
      );
      if (_response?.status === true) {
        if (_response?.data) {
          let _data = [];

          if (tempPage != 1) {
            _data = [...streamyardEventList, ..._response?.data];
          }
          if (tempPage == 1) {
            _data = [..._response?.data];
          }

          setStreamyardEventList(_data);
        }
      }
    } catch (error) {
      toast.error(error?.message ?? "Failed to get list");
    }
  };

  const getPastYoutubeList = async () => {
    try {
      const courses = await YoutubeService.listPlaylistVideos(
        "PLGQUxW7nTDtc2HXkSc-JmrraB8Z7XTILH"
      );
      if (courses?.items?.length > 0) {
        console.log("courses?.items", courses?.items);
        setPastYoutubeVideo(courses?.items);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to load courses.");
    }
  };

  const checkIsLoggedIn = (_eventId, _eventName, _eventDate, _eventImg) => {
    if (!session) {
      setBookingShowHide(true);
    } else {
      setBookingObject({
        eventId: _eventId || "",
        eventName: _eventName || "",
        eventDate: _eventDate || "",
        eventImg: _eventImg || "",
      });
      setIsConfirmBooking(true);
      // bookSpot(_eventId);;
    }
  };

  const bookSpot = async (_eventId) => {
    try {
      setLoader(true);

      let eventDetails = {
        session_event_id: _eventId,
      };
      const response = await SessionService.bookEvent(eventDetails);
      // console.log('response',response);
      if (response.status == true) {
        setIsEnroll(true);
        setIsConfirmBooking(false);
        await getEventList();
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message ?? "Failed to book event.");
    }
  };

  const openDetails = (_videoId, _isEvent = "") => {
    if (_isEvent == "event") {
      navigate(`/session/event/${_videoId}`, { state: { isYoutube: false } });
    } else {
      navigate(`/session/event/${_videoId}`, { state: { isYoutube: true } });
    }
  };

  const handlePageChange = () => {
    setPage(page + 1);
    getEventList(page + 1);
  };

  const isDatePassed = (dateString) => {
    const givenDate = new Date(dateString);
    const currentUTC = new Date();
    return givenDate.getTime() < currentUTC.getTime();
  };

  return (
    <div className="inner-content-wrapper overflow-y-hidden z-10  relative h-screen max-h-[calc(100vh-48px)] md:max-h-[calc(767px-48px)]">
      <div className="flex items-center justify-start mb-4 sticky top-0 left-0 pt-6 lg:pt-9  px-6 lg:px-12 ">
        <Link
          className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mr-2"
          to="/session"
        >
          <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
        </Link>
        <h1 className="text-2xl font-semibold flex items-center">{title}</h1>
      </div>
      <>
        {isEventList ? (
          // <InfiniteScroll
          //   dataLength={
          //     streamyardEventList?.length ? streamyardEventList?.length : 0
          //   } // Update with the current data length
          //   next={handlePageChange} // Provide a reference to the function, don't call it immediately
          //   hasMore={true} // Indicates whether there is more data to load
          //   className="!h-[calc(100%-2px)] md:!h-[calc(100%-280px)]"
          //   // height =  {streamyardEventList?.length > 4 ? "300px" : "250px"}
          //   // height={`calc(${divHeight}px - 52px - 50px)`}
          // >
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5  mb-40 md:mb-4 px-6 lg:px-12 ">
              {/* <div className="hidden"> */}
              {streamyardEventList.length <= 0 &&
                new Array(9).fill(1).map(() => (
                  <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                    <Skeleton height={150} width="100%" />
                    <div className="p-3">
                      <Skeleton count={2} width="100%" />
                    </div>
                  </div>
                ))}
              {/* </div> */}

              {streamyardEventList &&
                streamyardEventList.map((streamyardEvent) => (
                  <div
                    key={streamyardEvent?._id}
                    className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer flex flex-col justify-between"
                  >
                    <div>
                      <div className="relative max-h-[150px] h-full overflow-hidden">
                        <img
                          src={streamyardEvent?.thumbnail_image || News1}
                          className="object-cover h-full w-full"
                        />
                        <div className="text-center  bottom-3 absolute w-full">
                          <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                            {streamyardEvent?.event_date &&
                              moment
                                .utc(streamyardEvent?.event_date)
                                .format("DD MMM YYYY, hh:mm A")}
                          </span>
                        </div>
                      </div>
                      <div className="px-3 pt-3">
                        <h4 className="text-base font-medium mb-2 text-md">
                          {streamyardEvent?.name &&
                          streamyardEvent?.name?.length > 25
                            ? streamyardEvent?.name.substring(0, 25) + "..."
                            : streamyardEvent?.name}
                        </h4>
                        <p className="text-xs text-gray-600">
                          {streamyardEvent?.description &&
                          streamyardEvent?.description?.length > 100
                            ? streamyardEvent?.description.substring(0, 100)
                            : streamyardEvent?.description}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 flex justify-between items-center px-3 pb-3">
                      <h4 className="text-secondary-text text-xs font-medium">
                        {/* <span className="text-[#8896A6]">59</span>/100 Booked */}
                      </h4>

                      {session ? (
                        streamyardEvent?.hasBooking ||
                        isDatePassed(streamyardEvent?.event_end_date) ? (
                          <>
                            <button
                              onClick={() =>
                                openDetails(streamyardEvent?._id, "event")
                              }
                              className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                            >
                              View Event Details
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              checkIsLoggedIn(
                                streamyardEvent?._id,
                                streamyardEvent?.name,
                                streamyardEvent?.event_date,
                                streamyardEvent?.thumbnail_image
                              )
                            }
                            className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                          >
                            Reserve your spot
                          </button>
                        )
                      ) : (
                        <button
                          onClick={() =>
                            checkIsLoggedIn(
                              streamyardEvent?._id,
                              streamyardEvent?.name,
                              streamyardEvent?.event_date,
                              streamyardEvent?.thumbnail_image
                            )
                          }
                          className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                        >
                          Reserve your spot
                        </button>
                      )}
                      {/* <button
                              onClick={() => checkIsLoggedIn()}
                              className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                            >
                              Reserve your spot
                            </button> */}
                      {/* {isDatePassed(streamyardEvent?.event_end_date)}
                             {session && isDatePassed(streamyardEvent?.event_end_date) && (
                                <button
                                    onClick={() => openDetails(streamyardEvent?._id,'event')}
                                    className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                                  >
                                    View Passed Event Details
                                </button> )
                            }
                            {isDatePassed(streamyardEvent?.event_end_date)}
                            {session && streamyardEvent?.hasBooking && !isDatePassed(streamyardEvent?.event_end_date) && ( 
                              <button
                                onClick={() => openDetails(streamyardEvent?._id,'event')}
                                className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                              >
                                View Event Details
                              </button>
                              )}
                              {!session && !streamyardEvent?.hasBooking && !isDatePassed(streamyardEvent?.event_end_date) && (
                                <>
                                  
                                  <button
                                    onClick={() => checkIsLoggedIn(streamyardEvent?._id)}
                                    className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                                  >
                                    Reserve your spot
                                  </button>
                                </>
                              )} */}
                    </div>
                  </div>
                ))}
            </div>
          // </InfiniteScroll>
        ) : (
          // <InfiniteScroll
          //   dataLength={pastYoutubeVideo?.length ? pastYoutubeVideo?.length : 0} // Update with the current data length
          //   next={handlePageChange} // Provide a reference to the function, don't call it immediately
          //   hasMore={true} // Indicates whether there is more data to load
          //   // height={`calc(100vh-100px)`}
          //   // className="!h-[calc(100%-2px)] md:!h-[calc(100%-280px)]"
          // >
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 px-6 lg:px-12 mb-40 md:mb-4 overflow-y-auto max-h-[calc(100%-68px-36px)] pb-4">
              {/* <div className="hidden"> */}
              {pastYoutubeVideo.length <= 0 &&
                new Array(9).fill(1).map(() => (
                  <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                    <Skeleton height={150} width="100%" />
                    <div className="p-3">
                      <Skeleton count={2} width="100%" />
                    </div>
                  </div>
                ))}
              {/* </div> */}

              {pastYoutubeVideo &&
                pastYoutubeVideo.map((pastEvent, index) => (
                  // console.log(pastEvent[0])

                  <div
                    key={pastEvent?.id}
                    className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer"
                    onClick={() =>
                      openDetails(
                        pastEvent?.snippet?.resourceId?.videoId,
                        false
                      )
                    }
                  >
                    <div className="relative">
                      <img
                        src={
                          pastEvent?.snippet?.thumbnails?.standard?.url || News1
                        }
                      />
                      <div className="text-center  bottom-3 absolute w-full">
                        <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                          {pastEvent?.contentDetails?.videoPublishedAt &&
                            moment
                              .utc(pastEvent?.contentDetails?.videoPublishedAt)
                              .format("DD MMM YYYY, hh:mm A")}
                        </span>
                      </div>
                    </div>
                    <div className="p-3">
                      <h4 className="text-base font-medium mb-2 text-md">
                        {pastEvent?.snippet?.title}
                        {/* {pastEvent?.snippet?.title &&
                      pastEvent?.snippet?.title?.length > 25
                        ? pastEvent?.snippet?.title.substring(0, 25) + "..."
                        : pastEvent?.snippet?.title} */}
                      </h4>
                      <p className="text-xs text-gray-600">
                        {/* {pastEvent?.snippet?.description &&
                      pastEvent?.snippet?.description?.length > 100
                        ? pastEvent?.snippet?.description.substring(0, 100)
                        : pastEvent?.snippet?.description} */}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          // </InfiniteScroll>
        )}
      </>
      {bookingShowHide && (
        <AuthModal
          message="To book your spot, please Sign up / Sign in"
          redirectUrl="session"
          setBookingShowHide={setBookingShowHide}
        />
      )}

      {/* Enroll Now popup */}
      {isConfirmBooking && (
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-md">
          <div className="bg-white w-96  rounded-2xl overflow-hidden">
            <div className="relative w-full h-[160px] overflow-hidden">
              <img
                src={bookingObject?.eventImg || News1}
                className="object-cover h-full w-full"
              />
              <button
                onClick={() => setIsConfirmBooking(false)}
                className="absolute top-4 right-4"
              >
                <XCircle className="w-5 h-5 hover:text-red-500" />
              </button>
            </div>
            <div className="px-6 py-4">
              <h5 className="text-lg font-medium mb-2">
                {/* Existing events are going on. one events are going on. */}
                {bookingObject?.eventName}
              </h5>
              <h6 className="text-sm font-medium mb-5 text-[#2D4665] flex items-center">
                <Calendar className="w-5 h-5 stroke-[1.5px] text-slate-600 me-2" />
                {bookingObject?.eventDate &&
                  moment
                    .utc(bookingObject?.eventDate)
                    .format("DD MMM YYYY, hh:mm A")}
              </h6>
              <div className="text-center pb-4">
                <button
                  onClick={() => bookSpot(bookingObject?.eventId)}
                  className="px-6 py-2.5 font-medium text-sm rounded-[25px] bg-theme-blue text-white mx-auto"
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Enroll Now popup */}

      {/* congratulation  popup */}
      {isEnroll && (
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-md">
          <div className="bg-white w-96  rounded-2xl overflow-hidden">
            <div className="px-6 py-10 text-center relative">
              <button
                onClick={() => {
                  setIsConfirmBooking(false);
                  setIsEnroll(false);
                  setBookingObject({
                    eventId: "",
                    eventName: "",
                    eventDate: "",
                    eventImg: "",
                  });
                }}
                className="absolute top-4 right-4"
              >
                <XCircle className="w-5 h-5 hover:text-red-500" />
              </button>
              <Lottie
                animationData={completedAnimation}
                loop={true}
                style={{ width: "150px", margin: "0 auto" }}
              />
              <h5 className="text-lg font-medium mb-2 ">Congratulation!</h5>
              <h6 className="text-sm font-medium text-black">
                You have successfully registered for the event
              </h6>
              <div className="w-10 h-[1px] bg-slate-500 mx-auto my-3"></div>
              <h6 className="text-xs font-medium text-[#2D4665]">
                Event will be held on:{" "}
                {bookingObject?.eventDate &&
                  moment
                    .utc(bookingObject?.eventDate)
                    .format("DD MMM YYYY, hh:mm A")}
              </h6>
            </div>
          </div>
        </div>
      )}
      {/* congratulation popup */}
    </div>
  );
};

export default EventListing;
