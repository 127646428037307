import React from 'react';
import Logo from "./../assets/images/sidebar/logo.svg";



const ComingSoon = () => {
    return (
        <div className="relative h-full w-full flex items-center justify-center bg-cover bg-center text-center px-5 courseBg"
            // style={{backgroundImage:'url(https://images.pexels.com/photos/260689/pexels-photo-260689.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500)'}}
            >
            {/* <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div> */}

            <div className="z-50 flex flex-col justify-center text-black w-full h-full">
                <img src={Logo} className='w-10 mb-4 mx-auto' />
                <h1 className="text-4xl mb-3">Coming Soon</h1>
                {/* <h1 className="text-2xl mb-4">We are <b>Almost</b> there!</h1> */}
                <p>Stay tuned for something amazing!!!</p>

                {/* <div className="mt-10 mb-5">
                    <div className="shadow w-full bg-white mt-2 max-w-xl mx-auto rounded-full">
                        <div className="rounded-full bg-indigo-600 text-xs leading-none text-center w-[75%] text-white py-1"
                            >75%</div>
                    </div>
                </div> */}  
            </div>
        </div>
    )
}

export default ComingSoon