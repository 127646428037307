import axiosInstance from "../axios.interceptor";
import { Service } from "./service";

export class FileService {
    static async upload(data = []) {
        const formData = new FormData();
        formData.append('files', data[0]);
        formData.append('service', 'chat');
        const url = `${Service.baseurl}/file-upload/upload`;
        const response = await axiosInstance.post(url, formData);
        return response.data;
    }
}