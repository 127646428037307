import axios from "axios";
import socket, { SOCKETEVENTS } from "../socket";

export class YoutubeService {
  static async listVideos() {
    const MAX_RESULT = 50;
    const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${process.env.REACT_APP_YOUTUBE_CHANNEL}&maxResults=${MAX_RESULT}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&type=video&order=viewCount`;
    const response = await axios.get(url);
    return response.data;
  }

  static async getVideoDetails(id = '') {
    if (!id) {
      throw new Error('Invalid video id.');
    }
    const url = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,player,statistics,topicDetails&id=${id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const response = await axios.get(url);
    return response.data;
  }

  static async listPlaylist(pageToken = '') {
    const MAX_RESULT = 50;
    const url = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet,contentDetails&channelId=${process.env.REACT_APP_YOUTUBE_CHANNEL}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&maxResults=${MAX_RESULT}&pageToken=${pageToken}`;
    const response = await axios.get(url);
    return response.data;
  }

  static async listPlaylistVideos(id = '', pageToken = '') {
    const MAX_RESULT = 50;
    const url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=${id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&maxResults=${MAX_RESULT}&pageToken=${pageToken}`;
    const response = await axios.get(url);
    return response.data;
  }

  static updateCourseProgress(id, progress) {
    socket.socket.emit(SOCKETEVENTS.UPDATE_COURSE_PROGRESS, {
      id,
      progress
    });
    return true;
  }
}
