import React from 'react'

const Loader = ({statusName, bgColor, textColor}) => {
  return (
    <div className='w-full absolute top-0 left-0 z-10 h-full'>
        <div className='relative z-10'>
            <div className='h-1 w-full bg-blue-100 overflow-hidden'>
                <div className='progress w-full h-full bg-blue-500 left-right'></div>
            </div>
            <div className='w-full flex items-center justify-center'>
                <div className={`px-4 py-2 text-sm font-medium rounded-full mt-6 ${bgColor} ${textColor}`}>{statusName}</div>
            </div>
        </div>
        <div className='absolute top-0 left-0 w-full h-full bg-white/10'></div>
    </div>
  )
}

export default Loader