import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import fetchExternalData from "../../Services/fetchExternalData";
import { ArrowLeft } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const InformationDetails = () => {

  const arrPriorityBlogs = [
                            "deploy-and-earn-1500-l1x",
                            "l1x-labs-developers-portal",
                            "l1x-developer-essentials-kickstart",
                            "layer-one-x-and-x-talks-multichain-connectivity",
                            "transforming-blockchain-interoperability-multi-chain-asset-issuance-and-management-with-x-talk-powered-by-layer-one",
                            "layer-one-x-vs-traditional-bridges-live-demo",
                            "solving-bridge-vulnerabilities-with-x-talk",
                            "l1x-makes-crypto-history-with-a-significant-achievement",
                            "layer-one-x-grant-program"
                          ];


  const [priorityBlogs, setPriorityBlogs] = useState(arrPriorityBlogs);

  const [infoCmsPosts, setInfoCmsPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [divHeight, setDivHeight] = useState(0);
  const myDivRef = useRef(null);

  useEffect(() => {
    if (myDivRef.current) {
      const height = myDivRef.current.offsetHeight;
      setDivHeight(height);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getGhostCmsPosts();
    })();
  }, []);

  const getGhostCmsPosts = async (tempPage = 1) => {
    let publishPosts = await fetchExternalData.fetchData(75, tempPage);

    // console.log("publishPosts?.posts", publishPosts?.posts?.length);
      
    if (publishPosts?.posts) {

      movePostsWithMatchingSlugToFirst(publishPosts?.posts,priorityBlogs)
      // let _data = [];

      // if (tempPage != 1) {
      //   _data = [...infoCmsPosts, ...publishPosts?.posts];
      // }
      // if (tempPage == 1) {
      //   _data = [...publishPosts?.posts];
      // }

      // setInfoCmsPosts(_data);
    }
  };

  function movePostsWithMatchingSlugToFirst(posts, slugs) {
      // Filter out posts with matching slugs
      const matchingPosts = posts.filter(post => slugs.includes(post.slug));

      matchingPosts.sort((a, b) => {
          return slugs.indexOf(a.slug) - slugs.indexOf(b.slug);
      });
      
      // Filter out posts without matching slugs
      const nonMatchingPosts = posts.filter(post => !slugs.includes(post.slug));
      
      // Concatenate matching posts with non-matching posts
      const reorderedPosts = matchingPosts.concat(nonMatchingPosts);

      // setInfoCmsPosts(reorderedPosts);
      setInfoCmsPosts(matchingPosts);
      // console.log('reorderedPosts',reorderedPosts);
      // return;
      
      // return reorderedPosts;
  }

  const handlePageChange = () => {
    setPage(page + 1);
    getGhostCmsPosts(page + 1);
  };
  return (
    <div className="inner-content-wrapper">
      <div className="flex items-center justify-start pb-4 sticky top-0 left-0 pt-6 lg:pt-9 px-6 lg:px-12 bg-white">
        <Link
          className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mr-2"
          to="/information"
        >
          <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
        </Link>
        <h1 className="text-2xl font-semibold flex items-center">Blogs</h1>
      </div>
      {/* <InfiniteScroll
        dataLength={infoCmsPosts?.length ? infoCmsPosts?.length : 0} // Update with the current data length
        next={handlePageChange} // Provide a reference to the function, don't call it immediately
        hasMore={true} // Indicates whether there is more data to load
        height="630px"
        // height =  {infoCmsPosts?.length > 4 ? "300px" : "250px"}
        // height={`calc(${divHeight}px - 52px - 50px)`}
      > */}
        <div className="grid blog-grid grid-cols-2 lg:grid-cols-3 gap-5 mb-8 px-6 lg:px-12">
          {/* <div className="hidden"> */}
          {/* {infoCmsPosts.length <= 0 && new Array(9).fill(1).map(() => (
            <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
              <Skeleton height={150} width="100%" />
              <div className="p-3">
                <Skeleton count={2} width="100%" />
              </div>
            </div>
          ))} */}
          {/* </div> */}

          {infoCmsPosts &&
            infoCmsPosts.map((cmsPost) => (
              <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
                <Link to={`/information/blog-details/${cmsPost?.id}`}>
                  <img src={cmsPost?.feature_image} className="h-[167px] object-cover mx-auto w-full" />
                  <div className="p-3">
                    <h4 className="text-base font-medium mb-2 text-ellipsis line-clamp-2">
                      {cmsPost?.title}
                    </h4>
                    {/* <p className="text-sm text-gray-600">Massive 250,000 L1X Coin Airdrop: Buy a username on the L1X App or deposit a minimu...</p> */}
                  </div>
                </Link>
              </div>
            ))}
        </div>
      {/* </InfiniteScroll> */}
    </div>
  );
};

export default InformationDetails;
