import React, { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link, useParams, useNavigate } from "react-router-dom";
import fetchExternalData from "../../Services/fetchExternalData";
import "./blog-details.scss";
const BlogDetails = () => {
  const { blog_id } = useParams();
  const [blogCmsDetails, setBlogCmsDetails] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [blogUrl, setBlogUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getGhostCmsPostDetails();
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const getGhostCmsPostDetails = async () => {
    if (blog_id != undefined) {
      let blogDetails = await fetchExternalData.fetchBlogDetails(blog_id);

      if (blogDetails?.posts) {
        // setBlogCmsDetails(blogDetails?.posts[0]?.html);
        setBlogTitle(blogDetails?.posts[0]?.title);
        // setBlogImage(blogDetails?.posts[0]?.feature_image);
        setBlogUrl(blogDetails?.posts[0]?.url);
      }
    }
  };

  return (
    <div className="inner-content-wrapper pt-6 lg:pt-9 px-6 lg:px-12 blog-details-crm h-full">
      <Link
        className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full "
        onClick={goBack}
      >
        <ArrowLeft className="w-6 h-6" />
      </Link>
      {/* <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold flex items-center">
          <Link className="inline-block mr-3" to="/information"><ArrowLeft className="w-6 h-6" /></Link>

          {blogTitle}
        </h1>
      </div> */}
      <div className="w-full blog-details-preview h-[calc(100%-49px)]">
        <>
          {/* <img src={blogImage} className="rounded-2xl" />
          <div className="max-w-2xl mx-auto py-10">
            <div dangerouslySetInnerHTML={{ __html: blogCmsDetails }} />
          </div> */}
        <div className="w-full h-full">
          <iframe
            title="L1X Blogs"
            src={blogUrl}
            height="100%"
            width="100%"
            frameBorder="0"
            allowFullScreen
          />
          </div>
        </>
      </div>
    </div>
  );
};

export default BlogDetails;
