// UserContext.js
import React, { createContext, useEffect, useState } from 'react';
import socketConnection from '../socket';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // const [user, setUser] = useState({ name: '', email: '' ,mobile:'', profile:""});

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : {isLogin:false, name: '', email: '' ,mobile:'', profile:"" };
  });

   // Update localStorage when user data changes
   useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  
  const updateDeveloper = (userData) => {
    setUser(userData);
  };

  const logout = (navigate) => {
    setUser({ isLogin:false , name: '', email: '' ,mobile:'', profile:""});
    localStorage.clear();
    navigate("/login", { state: { redirect: window.location.pathname } });
    socketConnection.disconnect();
    
  };

  return (
    <UserContext.Provider value={{ user, updateDeveloper, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
