import React, { useEffect, useState } from "react";
import News1 from "../../assets/images/default.png";
import { Calendar, XCircle } from "react-feather";
import Logo from "../../assets/images/sidebar/logo.svg";
import { SessionService } from "../../Services/sessionService";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import EventDetails from "./EventDetails";
import AuthModal from "../../components/AuthModal";
import { YoutubeService } from "../../Services/youtubeService";
import Lottie from "lottie-react";
import completedAnimation from "../../assets/images/completed-animation.json";
const Session = () => {
  const [showHide, setShowHide] = useState();
  const [bookingShowHide, setBookingShowHide] = useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = useState(false);
  const [isEnroll, setIsEnroll] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [streamyardEventList, setStreamyardEventList] = useState([]);
  const [pastYoutubeVideo, setPastYoutubeVideo] = useState([]);
  const session = JSON.parse(localStorage.getItem("session"))?.user_id;
  const [loader, setLoader] = useState(false);
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false);
  const navigate = useNavigate();
  const [bookingObject, setBookingObject] = useState({
    eventId: "",
    eventName: "",
    eventDate: "",
    eventImg: "",
  });

  useEffect(() => {
    console.log("isUpcomingEvent", isUpcomingEvent);
    getEventList();
    getPastYoutubeList();
  }, []);

  const getEventList = async (tempPage = 1) => {
    try {
      let _response = await SessionService.getEventslist(tempPage, 3, session);
      if (_response?.status === true) {
        if (_response?.data) {
          let _data = [];

          if (tempPage != 1) {
            _data = [...streamyardEventList, ..._response?.data];
          }
          if (tempPage == 1) {
            _data = [..._response?.data];
          }

          setStreamyardEventList(_data);
          // console.log('_data?.length',_data?.length)

          if (_data?.length != 0) {
            setIsUpcomingEvent(true);
          }

          // setTimeout(() => {
          //   if (_data?.length == 0) {
          //     setIsUpcomingEvent(false);
          //   }
          // }, 1000);
        }
      }
    } catch (error) {
      toast.error(error?.message ?? "Failed to get list");
    }
  };

  const getPastYoutubeList = async () => {
    try {
      // setCourses((prevState) => ({
      //   ...prevState,
      //   loader: true,
      // }));
      const courses = await YoutubeService.listPlaylistVideos(
        "PLGQUxW7nTDtc2HXkSc-JmrraB8Z7XTILH"
      );
      if (courses?.items?.length > 0) {
        setPastYoutubeVideo(courses?.items);
      }
      // console.log('courses',courses);

      // setCourses((prevState) => ({
      //   ...prevState,
      //   list: [...courses?.items],
      //   nextPageToken: courses?.nextPageToken,
      //   prevPageToken: courses?.prevPageToken,
      // }));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Failed to load courses.");
    }
  };

  const checkIsLoggedIn = (_eventId, _eventName, _eventDate, _eventImg) => {
    if (!session) {
      setBookingShowHide(true);
    } else {
      setBookingObject({
        eventId: _eventId || "",
        eventName: _eventName || "",
        eventDate: _eventDate || "",
        eventImg: _eventImg || "",
      });
      setIsConfirmBooking(true);
      // bookSpot(_eventId,_eventName,_eventDate,_eventImg);
    }
  };

  const bookSpot = async (_eventId) => {
    try {
      setLoader(true);

      let eventDetails = {
        session_event_id: _eventId,
      };
      const response = await SessionService.bookEvent(eventDetails);
      if (response.status == true) {
        // toast.success(response?.message);
        setIsEnroll(true);
        setIsConfirmBooking(false);
        await getEventList();
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message ?? "Failed to book event.");
    }
  };

  const openDetails = (_videoId) => {
    navigate(`/session/event/${_videoId}`, { state: { isYoutube: true } });
  };

  const isDatePassed = (dateString) => {
    const givenDate = new Date(dateString);
    const currentUTC = new Date();
    return givenDate.getTime() < currentUTC.getTime();
  };

  return (
    <>
      {/* {!showEventDetails ? ( */}
      <div className="inner-content-wrapper pt-6 px-6 lg:pt-9 lg:px-12">
        {isUpcomingEvent && (
          <>
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-semibold">Upcoming Sessions</h1>
              {streamyardEventList?.length > 0 && (
                <Link
                  to={"/session/event-list"}
                  className="px-4 py-1 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl"
                >
                  View All
                </Link>
              )}
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mb-8">
              {isUpcomingEvent &&
                streamyardEventList.length <= 0 &&
                new Array(3).fill(1).map(() => (
                  <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                    <Skeleton height={150} width="100%" />
                    <div className="p-3">
                      <Skeleton count={2} width="100%" />
                    </div>
                  </div>
                ))}
              {streamyardEventList?.length > 0 &&
                streamyardEventList.map((streamyardEvent) => (
                  <div
                    key={streamyardEvent?._id}
                    className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer flex flex-col justify-between"
                  >
                    <div>
                      <div className="relative max-h-[150px] h-full overflow-hidden">
                        <img
                          src={streamyardEvent?.thumbnail_image || News1}
                          className="object-cover h-full w-full"
                        />
                        <div className="text-center  bottom-3 absolute w-full">
                          <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                            {streamyardEvent?.event_date &&
                              moment
                                .utc(streamyardEvent?.event_date)
                                .format("DD MMM YYYY, hh:mm A")}
                          </span>
                        </div>
                      </div>
                      <div className="px-3 pt-3">
                        <h4 className="text-base font-medium mb-2 text-md">
                          {streamyardEvent?.name &&
                          streamyardEvent?.name?.length > 25
                            ? streamyardEvent?.name.substring(0, 25) + "..."
                            : streamyardEvent?.name}
                        </h4>
                        <p className="text-xs text-gray-600">
                          {streamyardEvent?.description &&
                          streamyardEvent?.description?.length > 100
                            ? streamyardEvent?.description.substring(0, 100)
                            : streamyardEvent?.description}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 flex justify-between items-center pb-3 px-3">
                      <h4 className="text-secondary-text text-xs font-medium">
                        {/* <span className="text-[#8896A6]">59</span>/100 Booked */}
                      </h4>
                      {/* <button
                              onClick={() => checkIsLoggedIn()}
                              className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                            >
                              Reserve your spot
                            </button> */}
                      {/* {session && streamyardEvent?.hasBooking ? (  */}
                      {session ? (
                        streamyardEvent?.hasBooking ||
                        isDatePassed(streamyardEvent?.event_end_date) ? (
                          <>
                            <Link
                              to={`event/${streamyardEvent?._id}`}
                              className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                            >
                              View Event Details
                            </Link>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              checkIsLoggedIn(
                                streamyardEvent?._id,
                                streamyardEvent?.name,
                                streamyardEvent?.event_date,
                                streamyardEvent?.thumbnail_image
                              )
                            }
                            className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                          >
                            Reserve your spot
                          </button>
                        )
                      ) : (
                        <button
                          onClick={() =>
                            checkIsLoggedIn(
                              streamyardEvent?._id,
                              streamyardEvent?.name,
                              streamyardEvent?.event_date,
                              streamyardEvent?.thumbnail_image
                            )
                          }
                          className="text-xs text-[#4087F3] font-medium border border-[#4087F3] rounded-lg px-3 py-1 leading-4"
                        >
                          Reserve your spot
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}

        {/* Past videos listing */}
        {/* <p className="text-sm  mb-4">Past Events</p> */}
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-semibold">Past Sessions</h1>
          {pastYoutubeVideo?.length > 0 && (
            <Link
              to={"/session/existing-list"}
              className="px-4 py-1 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl"
            >
              View All
            </Link>
          )}
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3  gap-5 mb-8">
          {pastYoutubeVideo.length <= 0 &&
            new Array(3).fill(1).map(() => (
              <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                <Skeleton height={150} width="100%" />
                <div className="p-3">
                  <Skeleton count={2} width="100%" />
                </div>
              </div>
            ))}
          {pastYoutubeVideo?.length > 0 &&
            pastYoutubeVideo.slice(0, 3).map((pastEvent) => (
              <div
                key={pastEvent?.id}
                className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer "
                onClick={() =>
                  openDetails(pastEvent?.snippet?.resourceId?.videoId)
                }
              >
                <div className="relative max-h-[150px] h-full overflow-hidden">
                  <img
                    src={pastEvent?.snippet?.thumbnails?.standard?.url || News1}
                    className="object-cover h-full w-full"
                  />
                  {/* <div className="text-center  bottom-3 absolute w-full">
                    <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                     
                    </span>
                  </div> */}
                </div>
                <div className="p-3">
                  <h4 className="text-base font-medium mb-2 text-md">
                    {/* {pastEvent?.snippet?.title &&
                      pastEvent?.snippet?.title?.length > 25
                        ? pastEvent?.snippet?.title.substring(0, 25) + "..."
                        : pastEvent?.snippet?.title} */}
                    {pastEvent?.snippet?.title}
                  </h4>
                  <p className="text-xs">
                    {" "}
                    {pastEvent?.contentDetails?.videoPublishedAt &&
                      moment
                        .utc(pastEvent?.contentDetails?.videoPublishedAt)
                        .format("DD MMM YYYY, hh:mm A")}
                  </p>
                  {/* <p className="text-xs text-gray-600">
                    {pastEvent?.snippet?.description &&
                      pastEvent?.snippet?.description?.length > 100
                        ? pastEvent?.snippet?.description.substring(0, 100)
                        : pastEvent?.snippet?.description}
                  </p> */}
                </div>
              </div>
            ))}
        </div>

        {/* <p className="text-sm mb-2">Hackathons</p>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-semibold">
              Join the world's best online Hackathon's.
            </h1>
            <button
              onClick={() => setShowHide(true)}
              className="px-4 py-1 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl"
            >
              View All
            </button>
          </div>
          <div className="grid grid-cols-3 gap-5 mb-7">
            <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
              <div className="relative">
                <img src={News1} />
                <div className="text-center  bottom-3 absolute w-full">
                  <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                    Jun. 10 – 12, 2024
                  </span>
                </div>
              </div>
              <div className="p-3">
                <h4 className="text-base font-medium mb-2 text-md">
                  Meta Quest Presence Platform Hackathon
                </h4>
 
                <div className="mt-2 flex justify-between items-center mb-3">
                  <h4 className="text-black text-xs font-medium flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    Mar 25 - May 13, 2024
                  </h4>
                  <button className="text-xs  font-medium bg-[#8896A6] text-white rounded-lg px-3 py-1 leading-4">
                    20 Days left
                  </button>
                </div>
                <div className="mt-2 flex justify-between items-center mb-3">
                  <div>
                    <h6 className="text-secondary-text text-sm font-normal">
                      Price
                    </h6>
                    <h4 className="text-black text-md font-semibold">
                      $253,56.00
                    </h4>
                  </div>
                  <button
                    onClick={() => setShowHide(true)}
                    className="text-xs  font-medium bg-[#4087F3] text-white rounded-lg px-3 py-1 leading-4"
                  >
                    Participate Now
                  </button>
                </div>
              </div>
            </div>
            <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
              <div className="relative">
                <img src={News1} />
                <div className="text-center  bottom-3 absolute w-full">
                  <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                    Jun. 10 – 12, 2024
                  </span>
                </div>
              </div>
              <div className="p-3">
                <h4 className="text-base font-medium mb-2 text-md">
                  Meta Quest Presence Platform Hackathon
                </h4>
 
                <div className="mt-2 flex justify-between items-center mb-3">
                  <h4 className="text-black text-xs font-medium flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    Mar 25 - May 13, 2024
                  </h4>
                  <button className="text-xs  font-medium bg-[#8896A6] text-white rounded-lg px-3 py-1 leading-4">
                    20 Days left
                  </button>
                </div>
                <div className="mt-2 flex justify-between items-center mb-3">
                  <div>
                    <h6 className="text-secondary-text text-sm font-normal">
                      Price
                    </h6>
                    <h4 className="text-black text-md font-semibold">
                      $253,56.00
                    </h4>
                  </div>
                  <button
                    onClick={() => setShowHide(true)}
                    className="text-xs  font-medium bg-[#4087F3] text-white rounded-lg px-3 py-1 leading-4"
                  >
                    Participate Now
                  </button>
                </div>
              </div>
            </div>
            <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
              <div className="relative">
                <img src={News1} />
                <div className="text-center  bottom-3 absolute w-full">
                  <span className=" bg-white text-sm text-black px-3 py-1 rounded-2xl inline-block font-medium">
                    Jun. 10 – 12, 2024
                  </span>
                </div>
              </div>
              <div className="p-3">
                <h4 className="text-base font-medium mb-2 text-md">
                  Meta Quest Presence Platform Hackathon
                </h4>
 
                <div className="mt-2 flex justify-between items-center mb-3">
                  <h4 className="text-black text-xs font-medium flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    Mar 25 - May 13, 2024
                  </h4>
                  <button className="text-xs  font-medium bg-[#8896A6] text-white rounded-lg px-3 py-1 leading-4">
                    20 Days left
                  </button>
                </div>
                <div className="mt-2 flex justify-between items-center mb-3">
                  <div>
                    <h6 className="text-secondary-text text-sm font-normal">
                      Price
                    </h6>
                    <h4 className="text-black text-md font-semibold">
                      $253,56.00
                    </h4>
                  </div>
                  <button
                    onClick={() => setShowHide(true)}
                    className="text-xs  font-medium bg-[#4087F3] text-white rounded-lg px-3 py-1 leading-4"
                  >
                    Participate Now
                  </button>
                </div>
              </div>
            </div>
          </div> */}
      </div>
      {/* // ) : (
        //   <EventDetails closeEventDetailsHandler={closeEventDetailsHandler} />
        // )} */}

      {/* {showHide && (
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-[5px]">
          <div className="bg-white w-96 px-6 rounded-2xl py-10">
            <div className="text-center">
              <img src={Logo} className="mx-auto mb-1 w-7" />
              <h4 className="text-lg font-semibold mb-1">
                L1X Live AMA. Host your L1X Node with Kevin Coutinho
              </h4>
              <h4 className="text-xs font-normal mb-4 flex items-center justify-center">
                <Calendar className="w-4 h-4 text-slate-500 mr-1" /> April 19,
                2024 01:30 PM
              </h4>
              <p className="text-xs font-normal mb-5 text-slate-500 text-ellipsis line-clamp-2">
                Join us for a special Ask Me Anything (AMA) session with Kevin
                Coutinho, where he'll guide you through the detailed process of
                hosting your L1X Node.
              </p>
              <form className="w-full max-w-[320px] mx-auto">
                <div className="grid grid-cols-2  gap-4">
                  <div className="w-full relative mb-4">
                    <input
                      placeholder="First Name"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                    />
                  </div>
                  <div className="w-full relative mb-4">
                    <input
                      placeholder="Last Name"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                    />
                  </div>
                </div>
                <div className="w-full relative mb-4">
                  <input
                    type="text"
                    placeholder="Email"
                    className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                  />
                </div>

                <div className="w-full relative mb-4 ">
                  <button
                    className="border border-[#4087F3] px-4 py-3 text-white text-sm w-full rounded-lg bg-[#4087F3] hover:bg-black hover:border-black"
                    type="submit"
                  >
                    Submit
                  </button>
                  <div className="w-full mt-2">
                    <button
                      className="text-sm text-black hover:text-[#4087F3] border-b border-b-black hover:border-b-[#4087F3] pb-[2px]"
                      onClick={() => setShowHide(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )} */}

      {bookingShowHide && (
        <AuthModal
          message="To book your spot, please Sign up / Sign in"
          redirectUrl="session"
          setBookingShowHide={setBookingShowHide}
        />
      )}

      {/* Enroll Now popup */}
      {isConfirmBooking && (
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-md">
          <div className="bg-white w-96  rounded-2xl overflow-hidden">
            <div className="relative w-full h-[160px] overflow-hidden">
              <img
                src={bookingObject?.eventImg || News1}
                className="object-cover h-full w-full"
              />
              <button
                onClick={() => setIsConfirmBooking(false)}
                className="absolute top-4 right-4"
              >
                <XCircle className="w-5 h-5 hover:text-red-500" />
              </button>
            </div>
            <div className="px-6 py-4">
              <h5 className="text-lg font-medium mb-2">
                {/* Existing events are going on. one events are going on. */}
                {bookingObject?.eventName}
              </h5>
              <h6 className="text-sm font-medium mb-5 text-[#2D4665] flex items-center">
                <Calendar className="w-5 h-5 stroke-[1.5px] text-slate-600 me-2" />
                {bookingObject?.eventDate &&
                  moment
                    .utc(bookingObject?.eventDate)
                    .format("DD MMM YYYY, hh:mm A")}
              </h6>
              <div className="text-center pb-4">
                <button
                  onClick={() => bookSpot(bookingObject?.eventId)}
                  className="px-6 py-2.5 font-medium text-sm rounded-[25px] bg-theme-blue text-white mx-auto"
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Enroll Now popup */}

      {/* congratulation  popup */}
      {isEnroll && (
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-md">
          <div className="bg-white w-96  rounded-2xl overflow-hidden">
            <div className="px-6 py-10 text-center relative">
              <button
                onClick={() => {
                  setIsConfirmBooking(false);
                  setIsEnroll(false);
                  setBookingObject({
                    eventId: "",
                    eventName: "",
                    eventDate: "",
                    eventImg: "",
                  });
                }}
                className="absolute top-4 right-4"
              >
                <XCircle className="w-5 h-5 hover:text-red-500" />
              </button>
              <Lottie
                animationData={completedAnimation}
                loop={true}
                style={{ width: "150px", margin: "0 auto" }}
              />
              <h5 className="text-lg font-medium mb-2 ">Congratulation!</h5>
              <h6 className="text-sm font-medium text-black">
                You have successfully registered for the event
              </h6>
              <div className="w-10 h-[1px] bg-slate-500 mx-auto my-3"></div>
              <h6 className="text-xs font-medium text-[#2D4665]">
                Event will be held on:{" "}
                {bookingObject?.eventDate &&
                  moment
                    .utc(bookingObject?.eventDate)
                    .format("DD MMM YYYY, hh:mm A")}
              </h6>
            </div>
          </div>
        </div>
      )}
      {/* congratulation popup */}
    </>
  );
};

export default Session;
