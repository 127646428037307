import axiosInstance from "../axios.interceptor";
import { Service } from "./service";

export class NotificationService {
  static async listNotifications() {
    const receiver = JSON.parse(localStorage.getItem("session"))?.user_id;
    const url = `${Service.baseurl}/notification/list-notification/${receiver}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async clearNotifications() {
    const receiver = JSON.parse(localStorage.getItem("session"))?.user_id;
    const url = `${Service.baseurl}/notification/clear-notification/${receiver}`;
    const response = await axiosInstance.delete(url);
    return response.data;
  }
}
