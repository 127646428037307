import axiosInstance from "../axios.interceptor";
// import SocketConnection, { SOCKETEVENTS } from "../socket";
import { Service } from "./service";

export class SessionService {
    
    /* For Developer */
    static async getEventslist(_page, _limit,_session="") {
        // const url = `${Service.baseurl}/developer/list-event`;
        const url = `${Service.baseurl}/session-events/list?page=${_page}&per_page=${_limit}&user_profile_id=${_session}`;
        const response = await axiosInstance.get(url);
        return response.data;
    }

    static async getEventsDetails(_data) {
        // // const url = `${Service.baseurl}/developer/list-event`;
        // const url = `${Service.baseurl}/session-events/get-event-details/${_eventId}`;
        // const response = await axiosInstance.get(url);
        // return response.data;
        const url = `${Service.baseurl}/session-events/get-event-details`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }

    static async bookEvent(_data) {
        const url = `${Service.baseurl}/session-events/book-event`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }



    /* For Admin */
    static async getAdminVerification(_data) {
        const url = `${Service.baseurl}/admin/validate-auth`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }

    static async getAdminEventslist(_page, _limit,_authCode) {
        // const url = `${Service.baseurl}/developer/list-event`;
        const url = `${Service.baseurl}/admin/get-session-event-list?page=${_page}&per_page=${_limit}&auth_code=${_authCode}`;
        const response = await axiosInstance.get(url);
        return response.data;
    }


    static async getEventById(_eventId,_auth) {
        const url = `${Service.baseurl}/admin/get-session-event-details/${_eventId}?auth_code=${_auth}`;
        const response = await axiosInstance.get(url);
        return response.data;
    }


    static async createEvent(_data) {
        const url = `${Service.baseurl}/admin/create-session-event`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }

    static async updateEvent(_id,_data) {
    const url = `${Service.baseurl}/admin/edit-session-event/${_id}`;
    const payload = _data;
    const response = await axiosInstance.put(url, payload);
    return response.data;
    }

    static async updateEventStatus(_data) {
        const url = `${Service.baseurl}/admin/change-status`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }

    static async deleteEvent(_data) {
        const url = `${Service.baseurl}/admin/delete-event`;
        const payload = _data;
        const response = await axiosInstance.post(url, payload);
        return response.data;
    }
}
