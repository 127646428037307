import React, { useEffect, useState } from "react";
import { CheckCircle, X } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../Services/authService";
import { toast } from "react-toastify";
const EmailVerified = () => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [verificatinStatus, setVerificatinStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const id = params.get("id");
    // console.log("Token:", id);
    // Do whatever you need to do with the token
    if (id) {
      // return () => verifyEmail(id,token);
      verifyEmail(id,token);
    }
  }, []);


  const verifyEmail = async (_id,_token) => {
    try {
      if (verificatinStatus != null) {
        return
      }
      setLoader(true);
      const data = {"token" : _token, "type" : 0}
      const response = await AuthService.verifyEmail(_id,data);


      if (response.status == true) {
        setVerificatinStatus(true);
        // toast.success(response?.message);
        toast.success('Email is verified, please login');
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
      // console.log('response',response);
      // return;
    } catch (error) {
      console.log("error", error);
      // toast.error(error?.message);
      setErrorMessage(error?.response?.data?.message ?? "Failed to login.");
      setVerificatinStatus(false);
      toast.error(error?.response?.data?.message ?? "Failed to login.");
    } finally {
      setLoader(false);
    }
  };


  return (
    <>
      {loader ? (
        <>
          <div className="w-full absolute top-0 left-0 z-10 h-full">
            <div className="relative z-10">
              <div className="h-1.5 w-full bg-blue-100 overflow-hidden">
                <div className="progress w-full h-full bg-blue-500 left-right"></div>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-white/30"></div>
          </div>
          <div className="App has-bg px-5 sm:px-10">
            <div className="flex items-center justify-center h-screen w-full">
              <div className="bg-white max-h-[600px] rounded-2xl h-full md:flex relative overflow-hidden">
                <div className="w-full max-w-96 sm:min-w-[380px]">
                  <div className="flex items-center justify-center w-full h-full p-7 sm:p-10 flex-col">
                    <div className="w-full  mx-auto text-center mb-8">
                      <h4 className="text-black text-2xl font-semibold mb-1">
                        Email Is Being Verified...
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="App has-bg px-5 sm:px-10">
          <div className="flex items-center justify-center h-screen w-full">
            <div className="bg-white max-h-[600px] rounded-2xl h-full md:flex relative overflow-hidden">
              <div className="w-full max-w-96 sm:min-w-[380px]">
                <div className="flex items-center justify-center w-full h-full p-7 sm:p-10 flex-col">
                  {verificatinStatus ? (
                    <div className="w-full  mx-auto text-center mb-8">
                      <CheckCircle className="w-8 h-8 mx-auto mb-3 stroke-green-600 stroke-[2px]" />


                      <h4 className="text-black text-2xl font-semibold mb-1">
                        Email Verified
                      </h4>


                      <h6 className="text-[#2D4665]/50 font-medium text-sm mb-6">
                        Your email address has been Verified.
                      </h6>
                      <Link className="text-sm bg-blue-600 text-white px-3 py-2 rounded-md hover:opacity-80">
                        Go to home
                      </Link>
                    </div>
                  ) : (
                    <div className="w-full  mx-auto text-center mb-8">
                      <X className="w-8 h-8 mx-auto mb-3 stroke-red-600 stroke-[2px]" />


                      <h4 className="text-black text-2xl font-semibold mb-1">
                        Email Not Verified
                      </h4>


                      <h6 className="text-[#2D4665]/50 font-medium text-sm mb-6">
                        {errorMessage}
                      </h6>
                      <Link
                        to={"/login"}
                        className="text-sm bg-blue-600 text-white px-5 py-2.5 rounded-md hover:opacity-80"
                      >
                        Go to login
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default EmailVerified;