import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import layeroneX from "./../../assets/images/layerone-x.svg";
import { toast } from "react-toastify";
import { SessionService } from "../../Services/sessionService";

function EventLogin() {
  const [authCode, setAuthCode] = useState("");
  const navigate = useNavigate();

  const validateAuthCode = async() =>{
      try {
          let _data = {
              "auth_code": authCode
          }
          let _response = await SessionService.getAdminVerification(_data);
          // let _response = {status:"success"}
          if(_response.status == true){
              // if(_response?.data?.isValid)
              // {
                  localStorage.setItem("eventAuthCode",authCode)

                  navigate("/event-admin/panel");
                  return toast.success("Admin authentication successful",'success');

              // }
          }
          else{
              if(_response?.data?.isValid==false)
              {

                  return toast.error("Please enter valid auth code");

              }
              return toast.error(_response?.message ?? 'Failed to verify details.');
          }
      }
      catch(error){
          return toast.error(error?.response?.data?.message ?? 'Failed to verify details.');

      }

  }

  const handleSubmit = async () => {
    await validateAuthCode();
  };

  return (
    <div className="has-bg w-full h-full">
      <div className="w-full h-screen flex items-center justify-center  p-5">
        <div className="w-80 card rounded-xl shadow-card-shadow bg-white p-7">
          <div className="w-full max-w-[320px] mx-auto text-center mb-5">
            <div className="w-8 h-8 overflow-hidden mb-2 mx-auto">
              <img src={layeroneX} alt="logo" />
            </div>
            <h4 className="text-black text-2xl font-semibold">L1X Labs</h4>
          </div>
          <form>
            <div className="w-full relative mb-4">
              <input
                onChange={(e)=>setAuthCode(e.target.value)}
                type="text"
                placeholder="Please enter authentication tokan"
                className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
              />
            </div>
            <div className="w-full relative mb-4 text-center">
              <button onClick={()=>handleSubmit()}
                type="button"
                className=" border outline-none border-[#4087F3] px-7 py-2  text-white text-sm  rounded-3xl bg-[#4087F3] hover:bg-black hover:border-black"
              >
                Authenticate
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EventLogin;
