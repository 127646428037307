import React, { useEffect, useState } from "react";
import News1 from "../../assets/images/news-1.png";
import { ArrowLeft, ArrowRight, Bookmark, Code, Download } from "react-feather";
import { Link } from "react-router-dom";
import fetchExternalData from "../../Services/fetchExternalData";
import Skeleton from "react-loading-skeleton";
import Roadmap from "./Roadmap";

const Information = () => {
  const arrPriorityBlogs = [
                            "deploy-and-earn-1500-l1x",
                            "l1x-labs-developers-portal",
                            "l1x-developer-essentials-kickstart",
                            // "layer-one-x-vs-traditional-bridges-live-demo",
                            ]
  const [infoCmsPosts, setInfoCmsPosts] = useState([]);
  const [priorityBlogs, setPriorityBlogs] = useState(arrPriorityBlogs);

  useEffect(() => {
    (async () => {
      await getGhostCmsPosts();
      // await getGitBookDetails()
    })();
  }, []);

  const getGhostCmsPosts = async () => {
    let publishPosts = await fetchExternalData.fetchData(75, 1);

    if (publishPosts?.posts) {
      movePostsWithMatchingSlugToFirst(publishPosts?.posts,priorityBlogs)
    }
  };

  function movePostsWithMatchingSlugToFirst(posts, slugs) {
    // Filter out posts with matching slugs
    const matchingPosts = posts.filter(post => slugs.includes(post.slug));

    matchingPosts.sort((a, b) => {
        return slugs.indexOf(a.slug) - slugs.indexOf(b.slug);
    });
    
    // Filter out posts without matching slugs
    const nonMatchingPosts = posts.filter(post => !slugs.includes(post.slug));
    
    // Concatenate matching posts with non-matching posts
    const reorderedPosts = matchingPosts.concat(nonMatchingPosts);

    setInfoCmsPosts(reorderedPosts.slice(0,3));
}

  const getGitBookDetails = async () => {
    let publishPosts = await fetchExternalData.getGitbookData();

    console.log("publishPosts", publishPosts);
  };

  const [yearList, setYearList] = useState([
    {
      status: "Ongoing",
      year: "PoX Phase Mainnet V2 (02/04/2024 to 02/07/2024)",
      card: [
        <RoadMapCard
          list={[
            {
              title:
                "<b>Open Sourcing L1X Core:</b> Releasing the L1X Core repository, encompassing consensus, state, X-Talk, and network code.",
            },
            {
              title: "<b>Toolkit and Interface Development and release of:</b>",
              sublist: [
                "l1x-toolkit and l1x-cargo interface,",
                "l1x-wallet-sdk npm registry,",
                "Implementing X-Talk and core fee mechanisms, ",
                "integrating Bitcoin with X-Talk, ",
              ],
            },
          ]}
          link="View More"
          linkHref="/information/roadmap"
        />,
      ],
    },
    {
      status: "Future",
      year: "PoX Phase Mainnet V3 (02/07/2024 to 02/11/2024)",
      card: [
        <RoadMapCard
          list={[
            {
              title: "<b>Improvement proposal, updates and release of:</b>",
              sublist: [
                "<b>Node Sharding:</b> Introducing node sharding with features tailored to transaction types such as L1X VM, X-Talk, and L1X EVM.",
                "<b>Multi-Database Configuration:</b> Extending support to Cassandra alongside the existing PostgreSQL configuration.",
                "<b>Super Block Headers:</b> Bootstrapping super block headers for enhanced performance.",
              ],
            },
          ]}
        />,
      ],
    }
  ]);

  return (
    <>
      <div className="inner-content-wrapper pt-6 px-6  lg:pt-9 lg:px-12">
        <div className="flex items-center justify-between mb-4">
          <Link
            className="ms-auto px-4 py-1 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl hover:bg-theme-blue hover:text-white"
            to={"/information/details"}
          >
            View All
          </Link>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 mb-10">
          {infoCmsPosts.length <= 0 &&
            new Array(3).fill(1).map(() => (
              <div className="card rounded-xl leading-0 overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                <Skeleton height={150} width="100%" />
                <div className="p-3">
                  <Skeleton count={2} width="100%" />
                </div>
              </div>
            ))}

          {infoCmsPosts &&
            infoCmsPosts.map((cmsPost) => (
              <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer ">
                {/* <Link to={`/information/blog-details/${cmsPost?.id}`}> */}
                {/* <Link
                  to={cmsPost?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={cmsPost?.feature_image} />
                  <div className="p-3">
                    <h4 className="text-base font-medium mb-2 text-ellipsis line-clamp-2">
                      {cmsPost?.title}
                    </h4>
                  </div>
                </Link> */}
                <Link
                  to={`/information/blog-details/${cmsPost?.id}`}
                  rel="noopener noreferrer"
                >
                  <img src={cmsPost?.feature_image} />
                  <div className="p-3">
                    <h4 className="text-base font-medium mb-2 text-ellipsis line-clamp-2">
                      {cmsPost?.title}
                    </h4>
                  </div>
                </Link>
              </div>
            ))}

          {/* <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
                            <img src={News1}/>
                            <div className="p-3">
                                <h4 className="text-base font-medium mb-2">Become a Liquidity Provider or Web3 Username Holder</h4>
                                <p className="text-sm text-gray-600">Massive 250,000 L1X Coin Airdrop: Buy a username on the L1X App or deposit a minimu...</p>
                            </div>
                        </div>

                        <div className="card rounded-xl overflow-hidden shadow-card-shadow hover:shadow-xl transition-shadow cursor-pointer">
                            <img src={News1}/>
                            <div className="p-3">
                                <h4 className="text-base font-medium mb-2">Become a Liquidity Provider or Web3 Username Holder</h4>
                                <p className="text-sm text-gray-600">Massive 250,000 L1X Coin Airdrop: Buy a username on the L1X App or deposit a minimu...</p>
                            </div>
                        </div> */}
        </div>

        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-semibold">
          L1X Protocol roadmap
          </h1>
          {/* <Link
            to="/information/roadmap"
            className="px-4 py-1 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl hover:bg-theme-blue hover:text-white whitespace-nowrap"
          >
            View Complete Roadmap
          </Link> */}
        </div>
        <Roadmap/>
        {/* <div className="w-full mb-4 pl-2.5 grid grid-cols-2 ">
          {yearList.map((cards, index) => {
            return (
              <div key={index} className="w-100 pe-5 pt-5 dotted-border-top">
                <div className="text-gray-400/70 text-md font-medium pb-4 relative top-dots">
                  {cards.status}
                  <h6 className="text-black text-md font-semibold ">
                    {cards.year}
                  </h6>
                </div>
                <div className="w-full gap-5 pb-4">
                  {cards?.card?.map((inerCard, index) => {
                    return <div key={index}>{inerCard}</div>;
                  })}
                </div>
              </div>
            );
          })}
        </div> */}

        {/* <p className="text-sm mb-2">Start using</p>
        <div className="mb-4 max-w-2xl">
          <h1 className="text-2xl font-semibold mb-3">
            Start building your own projects{" "}
          </h1>
          <p className="text-sm leading-relaxed text-primary-text">
            The L1X Protocol enables transactions cross-chain using advanced
            technology. The objective is to revolutionize the decentralized
            landscape. Unravelling exceptional interoperability, scalability,
            speed, and security.
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-8">
          <div className="group">
            <div className="bg-blue-50 p-4 rounded-2xl border border-[#E9EDF3]  group-hover:bg-blue-50 group-hover:border-blue-200 cursor-pointer">
              <Link
                target="_blank"
                to={`https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/setup-l1x-development-environment`}
              >
                <div className="w-10 h-10 flex items-center justify-center rounded-3xl border bg-theme-blue text-white border-[#E9EDF3] mb-3 group-hover:bg-theme-blue group-hover:text-white">
                  <Download className="w-5 h-5" />
                </div>
                <h4 className="text-base font-semibold mb-2">
                  Installation Guide
                </h4>
                <p className="text-sm text-secondary-text">
                  Step-by-step guides to setting up your system and installing
                  the framework.
                </p>
              </Link>
            </div>
          </div>
          <div className="group">
            <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-green-50 group-hover:border-green-200  cursor-pointer">
              <Link
                target="_blank"
                to={`https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-self-onboarding/l1x-documentation`}
              >
                <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-green group-hover:text-white">
                  <Code className="w-5 h-5" />
                </div>
                <h4 className="text-base font-semibold mb-2">Developers Kit</h4>
                <p className="text-sm text-secondary-text">
                  Step-by-step guides to setting up your system and installing
                  the framework.
                </p>
              </Link>
            </div>
          </div>
          <div className="group">
            <div className="bg-white p-4 rounded-2xl border border-[#E9EDF3] group-hover:bg-yellow-50 group-hover:border-yellow-200  cursor-pointer">
              <Link to={`/session`}>
                <div className="w-10 h-10 flex items-center justify-center rounded-3xl border border-[#E9EDF3] mb-3 group-hover:bg-theme-yellow group-hover:text-white">
                  <Bookmark className="w-5 h-5" />
                </div>
                <h4 className="text-base font-semibold mb-2">Events</h4>
                <p className="text-sm text-secondary-text">
                  Step-by-step guides to setting up your system and installing
                  the framework.
                </p>
              </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="mb-8 max-w-2xl">
          <h1 className="text-2xl font-semibold mb-3">
            Setup L1X Development Environment
          </h1>
          <p className="text-sm mb-4">Check the Video version of the Setup</p>

          <p className="text-sm leading-relaxed text-primary-text mb-3">
            Before you start using L1X SDE, make sure you have the following
            prerequisites installed on your system:
          </p>
          <p className="text-sm mb-4">
            <span className="font-semibold">Note:</span> The current version of
            L1X SDE is compatible with the following Host OS: Linux | MacOS |
            Windows (WSL2)
          </p>
          <Link
            className="bg-theme-blue px-6 py-2 text-white rounded-lg inline-block"
            target="_blank"
            to="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/l1x-development-environment-overview/setup-l1x-development-environment"
          >
            Start with Rust and Cargo
          </Link>
        </div> */}

        {/* <div className="mb-5 max-w-2xl">
          <h1 className="text-2xl font-semibold mb-3">Start Building</h1>
          <ul>
            <li>
         
              <a
                href="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/setup-l1x-development-environment"
                target="_blank"
                className="text-theme-blue underline mb-2 block"
              >
                Setup your Development Environment and Deploy your First
                Contract.
              </a>
            </li>
            <li>
              <a
                href="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/3-x-talk-flow-cross-chain-contract"
                target="_blank"
                className="text-theme-blue underline mb-2 block"
              >
                Build your First Interoperable Contract with X-Talk.
              </a>
            </li>
            <li>
              <a
                href="https://l1x-sdk.gitbook.io/l1x-master-sdk/ubTvs7ITP4emn1uocqF8/get-started-with-l1x-sde/development-workspace-environment-and-contract-types/2-erc20-contract-deployment-and-cross-contract-call"
                target="_blank"
                className="text-theme-blue underline mb-2 block"
              >
                Deploy or Migrate your EVM / Solidity Code to L1X EVM.
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* <Loader/> */}
    </>
  );
};

const RoadMapCard = ({ completed, list, linkHref, link }) => {
  return (
    <div className="relative">
      <div
        className={`roadmap-card w-full p-3 rounded-xl relative ${
          completed
            ? "bg-[#F3FFF7] border border-green-200"
            : "border border-gray-300"
        }`}
      >
        <div className="flex items-center justify-between mb-3">
          {completed ? (
            <button className="text-sm px-4 py-1 bg-theme-green text-white rounded-3xl">
              Completed
            </button>
          ) : (
            ""
          )}
        </div>
        <ul className="list-disc list-outside pl-4 text-secondary-text">
          {list.map((listItem, index) => {
            return (
              <li className="text-sm mb-2" key={index}>
                <span className="font-medium" dangerouslySetInnerHTML={{__html: listItem?.title }} />
                <ul className="list-disc list-outside ps-5  my-3">
                  {listItem?.sublist?.map((sublist, index) => {
                    return (
                      <li key={index} className="w-full mb-1" dangerouslySetInnerHTML={{__html: sublist }} />                       
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        {link ? (
          <Link to={linkHref} className="text-xs text-[#4087F3] ps-5 flex">
            {link} <ArrowRight className="w-4 h-4 ms-2 text-[#4087F3] "/>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Information;
