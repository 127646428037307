import React, { useContext, useEffect, useState } from "react";
import SampleImage from "./../../assets/images/sample-image.png";
import { ArrowLeft, ChevronRight, Image } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "../../Services/userService";
import { toast } from "react-toastify";
import { FileService } from "../../Services/fileService";
import Loader from "../../components/Loader";
import UserContext from "../../providers/UserContext";
import profileImage from "../../assets/images/user.jpg";

const Profile = () => {
  const { user, updateDeveloper, logout } = useContext(UserContext);

  const session = JSON.parse(localStorage.getItem("session"))?.session_token;
  const [errors, setErrors] = useState({});
  const [userDetails, setUserDetails] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    phone: "",
    image: "",
  });

  useEffect(() => {
    getUserDetails();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  async function getUserDetails() {
    try {
      if (!session) {
        return;
      }
      setLoader(true);
      const user = await UserService.getUser();

      if (user?.status == true) {
        setUserDetails(user?.data);
      }

      let first = "";
      let last = "";
      if (user?.data?.name) {
        const userName = user?.data?.name.split(" ");
        first = userName[0];
        last = userName[1];
        // console.log('words',words)
      }
      setPrevImage(user?.data?.profile_picture);
      setForm({
        firstName: first || "",
        lastName: last || "",
        email: user?.data?.email || "",
        mobile: user?.data?.country_code + ` ` + user?.data?.phone_number || "",
        phone: user?.data?.phone_number || "",
      });
      setLoader(false);
    } catch (error) {
      console.log(error);
      //   toast.error("Failed to list chat users.");
    }
  }

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!form.firstName || !form.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!form.lastName || !form.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      if (validateForm()) {
        setLoader(true);
        let imageUrl = "";
        if (form.image) {
          if (form.image.length > 0) {
            // let upawait FileService.upload(form.image);
            let images = await FileService.upload(form.image);

            // console.log('images',images);
            // return;
            if (images.status == true) {
              imageUrl = images?.data[0]?.url;
            }
          }
        } else {
          imageUrl = prevImage;
        }

        const userInfo = {
          name: form.firstName + " " + form.lastName,
          // "email": form.email,
          phone_number: form.phone,
          bio: "I am software Dev",
          profile_picture: imageUrl,
        };

        let response = await UserService.updateUser(userInfo);

        if (response.status == true) {
          updateDeveloper({
            isLogin: true,
            name: form.firstName + " " + form.lastName,
            email: form.email || "",
            mobile: form.mobile || "",
            profile: imageUrl,
          });
          setLoader(false);
          return toast.success(response?.message);
        }
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.message);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if file size exceeds 5MB
    if (file && file.size > 10 * 1024 * 1024) {
      toast.error(
        "File size exceeds 10MB limit. Please select a smaller file."
      );
      event.target.value = null;
    } else {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please select a JPG, PNG, or JPEG file."
        );
        event.target.value = null;
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          console.log("reader.result", reader.result);
          setPrevImage(reader.result);
        };
        reader.readAsDataURL(file);
      }

      setForm((prevState) => ({
        ...prevState,
        image: [file],
      }));
    }
    event.target.value = "";
  };

  return (
    <div className="relative">
      <div className="inner-content-wrapperoverflow-y-auto z-10 pt-6 lg:pt-9 relative max-h-[calc(767px-48px)]">
        <div className="flex w-full">
          <div className="w-full lg:basis-3/4 h-full px-6 lg:px-12 ">
            <div className="w-full">
              <button
                onClick={goBack}
                className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mb-3"
                // onClick={() => setShowDetails(false)}
              >
                <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
              </button>
              <p className="text-sm mb-2">Profile</p>
              <div className="w-full mb-4">
                <h1 className="text-2xl font-semibold mb-4">My Account</h1>
                <form
                  className=""
                  // onSubmit={handleSubmit}
                >
                  <div className="w-full">
                    <div className="w-28 h-28 rounded-full border-2 border-white shadow-card-shadow overflow-hidden relative group">
                      <img
                        src={prevImage || profileImage}
                        className="w-full h-full object-cover"
                      />
                      <div className="bg-black/50 absolute top-0 left-0 w-full h-full items-center justify-center cursor-pointer hidden group-hover:flex">
                        <input
                          className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                          type="file"
                          id="file"
                          accept=".jpg,.jpeg,.png"
                          multiple={false}
                          onChange={handleFileChange}
                        />
                        <div className="text-white text-center">
                          <Image className="mx-auto mb-2" />
                          <p className="text-sm">Change Image</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 gap-5">
                    <div className="sm:col-span-2 mt-5">
                      <h5 className="text-base font-semibold">Basic Details</h5>
                    </div>
                    <div className="w-full">
                      <input
                        className="px-4 py-2.5 text-sm border border-gray-300 rounded-md w-full"
                        placeholder="First Name"
                        value={form.firstName}
                        onChange={(e) =>
                          setForm((prevState) => ({
                            ...prevState,
                            firstName: e.target.value,
                          }))
                        }
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        className="px-4 py-2.5 text-sm border border-gray-300 rounded-md w-full"
                        placeholder="Last Name"
                        value={form.lastName}
                        onChange={(e) =>
                          setForm((prevState) => ({
                            ...prevState,
                            lastName: e.target.value,
                          }))
                        }
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        className="px-4 py-2.5 text-sm border border-gray-300 rounded-md w-full"
                        disabled
                        placeholder="Email Address"
                        value={form.email}
                        onChange={(e) =>
                          setForm((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="w-full">
                      <input
                        className="px-4 py-2.5 text-sm border border-gray-300 rounded-md w-full"
                        disabled
                        placeholder="+91 9766 050 993"
                        value={form.mobile}
                        onChange={(e) =>
                          setForm((prevState) => ({
                            ...prevState,
                            mobile: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>

                  {/* <div className='grid grid-cols-2 gap-3'>
                                        <div className='col-span-2 mt-5'>
                                            <h5 className='text-base font-semibold'>Security</h5>
                                        </div>
                                        <div className=''>
                                            <Link to="" className="inline-flex items-center"><span className='text-sm font-semibold border-b border-theme-blue inline-block text-theme-blue pb-0.5'>Change Password</span><ChevronRight className='w-5 text-theme-blue'/> </Link>
                                        </div>
                                    </div> */}
                  <div className="mt-10">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="px-6 py-2.5 text-sm font-semibold bg-theme-navyblue text-white rounded-md"
                    >
                      Update Profile
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <Loader
          statusName="Updating Profile"
          bgColor="bg-theme-green/20"
          textColor="text-theme-green"
        />
      )}
    </div>
  );
};

export default Profile;
