import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../assets/images/sidebar/logo.svg";
import Information from "../assets/images/sidebar/information.svg";
import Developer from "../assets/images/sidebar/developer.svg";
import Courses from "../assets/images/sidebar/courses.svg";
import Interact from "../assets/images/sidebar/interact.svg";
import Sessions from "../assets/images/sidebar/sessions.svg";
import Protocol from "../assets/images/sidebar/protocol.svg";
import ProfileNFT from "../assets/images/sidebar/profileNFT.svg";
import PageTextContext from "../providers/PageTextContext";
import Grant from "../assets/images/sidebar/applyGrant.svg";
import { X } from "react-feather";

const SideMenus1 = [
  { name: "Information", icon: Information, href: "/information" },
  { name: "Developer Documents", icon: Developer, href: "/developer-document" },
  { isBreak: true },
  { name: "Courses", icon: Courses, href: "/courses" },
  { name: "Interact", icon: Interact, href: "/interact" },
  { name: "Sessions", icon: Sessions, href: "/session" },

  { isBreak: true },
  {
    name: "Improvement Protocol",
    icon: Protocol,
    href: "/improvement-protocol/overview",
  },
  { name: "Apply for a Grant", icon: Grant, href: "https://l1xapp.com/ecosystem", target: '_blank' },
];

const Sidebar = ({ sidebarCloseHandler }) => {
  const { pageData, updatePageData } = useContext(PageTextContext);
  const location = useLocation();

  const handleMenuClick = (item) => {
    updatePageData({ pageTitle: item.name });
    localStorage.setItem("pageData", JSON.stringify({ pageTitle: item.name }));
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setTimeout(() => {
        location.pathname = "/information";
        updatePageData({ pageTitle: "Information" });
        localStorage.setItem(
          "pageData",
          JSON.stringify({ pageTitle: "Information" })
        );
      }, 500);
    }

    const storedPageData = localStorage.getItem("pageData");
    if (storedPageData) {
      updatePageData(JSON.parse(storedPageData));
    }
  }, []);

  const isActive = (href) => {
    return (
      location.pathname.includes("improvement-protocol") &&
      href.startsWith("/improvement-protocol")
    );
  };

  return (
    <>
      <div className="absolute z-50 bg-white lg:static sidebar lg:bg-white/85 w-[300px] h-screen md:h-[calc(100vh-40px)] flex flex-col p-4 md:max-h-[768px] shadow-lg lg:shadow-none sidebarAnimation">
        <div className="logo-space flex items-center gap-3">
          <img src={Logo} />
          <div className="">
            <h4 className="text-xl font-bold leading-none">Developer Portal</h4>
          </div>
          <button
            className="ms-auto block lg:hidden"
            onClick={() => sidebarCloseHandler()}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <ul className="pt-9 flex flex-col gap-3 sidebar">
          {SideMenus1.map((item, index) =>
            item?.isBreak ? (
              <span
                className="block h-[1px] bg-slate-300 w-full"
                key={index}
              ></span>
            ) : (
              //     <li>
              //     <Link to={item.href} className='flex items-center p-1.5 gap-3 w-full hover:bg-white rounded-lg'>
              //         <img src={item.icon}/>
              //         <span className='text-sm font-normal'>{item.name}</span>
              //     </Link>
              // </li>
              <li key={index} onClick={() => sidebarCloseHandler()}>
                <NavLink
                  to={item.href}
                  className={`flex items-center p-1.5 gap-3 w-full hover:bg-white rounded-lg menu-item ${
                    isActive(item.href) ? "active" : ""
                  }`}
                  activeClassName="active"
                  onClick={() => handleMenuClick(item)}
                >
                  <img src={item.icon} alt={item.name} className="w-6 h-6" />
                  <span className="text-sm font-normal">{item.name}</span>
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>
      <div
        className="bg-black/55 absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm block lg:hidden cursor-pointer"
        onClick={() => sidebarCloseHandler()}
      ></div>
    </>
  );
};

export default Sidebar;
