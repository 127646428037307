import React, { useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const Roadmap = () => {
  const [yearList, setYearList] = useState([
    {
      status: "Completed",
      year: "Proof of X Phase V1 (Completed on 02/04/2024) ",
      card: [
        <RoadMapCard
          completed={true}
          list={[
            {
              title:
                "<b>Closed Node Hosting Environment:</b> Establishing a closed node hosting environment with dApps deployment and testing capabilities.",
            },
            {
              title:
                "<b>Protocol Improvements:</b> Implementing various protocol improvements and upgrades to enhance network functionality.",
            },
            {
              title:
                "<b>L1X Coin Issuance:</b> L1X Coin Balance Issuance and Vesting.",
            },
            {
              title: "Node NFT Opting.",
            },
          ]}
        />,
      ],
    },
    {
      status: "Completed",
      // year: "PoX Phase Mainnet V2 (02/04/2024 to 02/07/2024)",
      year: "PoX Phase Mainnet V2 (Completed on 04/07/2024)",
      card: [
        <RoadMapCard
        completed={true}
          list={[
            {
              title:
                "<b>Open Sourcing L1X Core:</b> Releasing the L1X Core repository, encompassing consensus, state, X-Talk, and network code.",
            },
            {
              title: "<b>Toolkit and Interface Development and release of:</b>",
              sublist: [
                "l1x-toolkit and l1x-cargo interface,",
                "l1x-wallet-sdk npm registry,",
                "Implementing X-Talk and core fee mechanisms, ",
                "integrating Bitcoin with X-Talk, ",
                "and deploying username and AVDR contracts.",
                "Issuing L1X coin on multiple networks",
              ],
            },
            {
              title:
                "<b>Node Hosting:</b> aiming to accommodate 240 nodes for FVN and ELN.",
            },
            {
              title:
                "<b>Interface Development:</b> Launching Developer Portal, Node Operator Panel, Project Onboarding Panel, and Wallet Profiler Interfaces.",
            },
            {
              title:
                " <b>Protocol Updates: </b> Implementing protocol updates, performance enhancements, and security fixes.",
            },
          ]}
        />,
      ],
    },
    {
      status: "Ongoing",
      year: "PoX Phase Mainnet V3 (02/07/2024 to 02/12/2024)",
      card: [
        <RoadMapCard
          list={[
            {
              title: "<b>Improvement proposal, updates and release of:</b>",
              sublist: [
                "<b>Node Sharding:</b> Introducing node sharding with features tailored to transaction types such as L1X VM, X-Talk, and L1X EVM.",
                "<b>Multi-Database Configuration:</b> Extending support to Cassandra alongside the existing PostgreSQL configuration.",
                "<b>Super Block Headers:</b> Bootstrapping super block headers for enhanced performance.",
              ],
            },
          ]}
        />,
      ],
    },
   
  ]);

  return (
    <div className="inner-content-wrapper">
      {/* <Link
        className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mb-3"
        to="/information"
      >
        <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
      </Link>
      
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold">
          L1X Core Roadmap (Journey towards global currency)
        </h1>
      </div> */}
      <div className="w-full mb-4 pl-2.5 ">
        {yearList.map((cards, index) => {
          return (
            <div key={index} className="w-100 ps-6 dotted-border-left">
              <div className="text-gray-400/70 text-md font-medium pb-4 relative left-dots">
                {cards.status}
                <h6 className="text-black text-md font-semibold ">
                  {cards.year}
                </h6>
              </div>
              <div className="w-full gap-5 pb-4">
                {cards?.card?.map((inerCard, index) => {
                  return <div key={index}>{inerCard}</div>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RoadMapCard = ({ completed, list }) => {
  
  return (
    <div className="relative">
      <div
        className={`roadmap-card lg:max-w-[70%] p-3 rounded-xl relative ${
          completed
            ? "bg-[#F3FFF7] border border-green-200"
            : "border border-gray-300/70"
        }`}
      >
        <div className="flex items-center justify-between mb-3">
          {completed ? (
            <button className="text-sm px-4 py-1 bg-theme-green text-white rounded-3xl">
              Completed
            </button>
          ) : (
            ""
          )}
        </div>
        <ul className="list-disc list-outside pl-4 text-secondary-text">
          {list.map((listItem, index) => {
            return (
              <li className="text-sm mb-2" key={index}>
                <span className="font-medium" dangerouslySetInnerHTML={{__html : listItem?.title}} />
                <ul className="list-disc list-outside ps-5  my-3">
                  {listItem?.sublist?.map((sublist, index) => {
                    return (
                      <li key={index} className="w-full mb-1"  dangerouslySetInnerHTML={{__html : sublist}} />
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Roadmap;
