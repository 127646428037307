import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ArrowLeft, Paperclip, Plus, Send, Volume2 } from "react-feather";
import ChatUser from "./../../assets/images/user.jpg";
import emptyMessage from "./../../assets/images/empty-message.svg";

import { toast } from "react-toastify";
import { InteractionService } from "../../Services/interactionService";
import socketConnection, { SOCKETEVENTS } from "../../socket";
import { FileService } from "../../Services/fileService";
import ChatMediaPreview from "./ChatMediaPreview";
import Loader from "../../components/Loader";
import { SocketContext } from "../../providers/SocketProvider";
import Logo from "./../../assets/images/sidebar/logo.svg";
import ChatMessageSkeleton from "./ChatMessageSkeleton";
import SendMessageCard from "./SendMessageCard";
import RecievedMessageCard from "./RecievedMessageCard";

const Chat = ({
  interaction = {
    contacts: [],
    groups: [],
    activeChat: {},
    type: "message", // message | channels,
    contactLoader: false,
    groupLoader: false,
  },
  toggleModelSearch = false,
  setToggleModelSearch = () => {},
  changeType = (type) => {},
  setContactsList = (list, activeChat) => {},
  setGroupsList = (list, activeChat) => {},
  fetchGroupList = () => {},
  fetchContactList = () => {},
}) => {
  const pagelimit = 50;
  const allowedMediaTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const [chats, setChats] = useState();
  const session = JSON.parse(localStorage.getItem("session"))?.session_token;
  const socketContext = useContext(SocketContext);
  const containerRef = useRef();
  const scrolleObserverRef = useRef();
  const scrolleObserverRefMobile = useRef();
  const [loader, setLoader] = useState(false);
  const [joinGroupLoader, setJoinGroupLoader] = useState(false);
  const [showMobileChat, setShowMobileChat] = useState(true);
  const [form, setForm] = useState({
    receiver: interaction.activeChat?._id,
    message: "",
    loader: false,
    attachments: [],
    page: 1,
    hasMore: true,
    chat: [],
  });
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0] && entries[0].isIntersecting) {
        setForm((prevState) => ({
          ...prevState,
          page: prevState.page + 1,
        }));
      }
    },
    {
      threshold: 0.1,
      rootMargin: "0px",
    }
  );

  const list = useMemo(() => {
    return interaction.type == "message"
      ? interaction?.contacts
      : interaction?.groups;
  }, [interaction.contacts, interaction.groups, interaction.type]);

  useEffect(() => {
    if (scrolleObserverRef.current) {
      observer.observe(scrolleObserverRef.current);
    }

    return () => {
      if (scrolleObserverRef.current) {
        observer.unobserve(scrolleObserverRef.current);
        observer.disconnect();
      }
    };
  }, [scrolleObserverRef.current]);

  useEffect(() => {
    if (scrolleObserverRefMobile.current) {
      observer.observe(scrolleObserverRefMobile.current);
    }

    return () => {
      if (scrolleObserverRefMobile.current) {
        observer.unobserve(scrolleObserverRefMobile.current);
        observer.disconnect();
      }
    };
  }, [scrolleObserverRefMobile.current]);

  useEffect(() => {
    setChats(list);
  }, [list]);

  useEffect(() => {
    if (socketContext.connected) {
      fetchGroupList();
      fetchContactList();
    }
  }, [socketContext?.connected]);

  useEffect(() => {
    listMessages(form.page);
  }, [form.page]);

  useEffect(() => {
    InteractionService.readMessage(form.receiver, interaction.type);
    listMessages(1, []);
  }, [form.receiver]);

  useEffect(() => {
    socketConnection.removeInteraction();
    socketConnection.listenInteraction((e) =>
      handleMessage(e, form.receiver, chats)
    );
  }, [chats]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      receiver: interaction.activeChat?._id,
      attachments: [],
      chat: [],
      message: "",
      page: 1,
      hasMore: true,
    }));
  }, [interaction.activeChat?._id]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      receiver: interaction.activeChat?.receiver,
      attachments: [],
      chat: [],
      message: "",
      page: 1,
      hasMore: true,
    }));
  }, [interaction.activeChat?.receiver]);

  useEffect(() => {
    if (containerRef.current && form.page == 1) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [form.chat]);

  function handleMessage(message, activeChatId, chatList) {
    console.log(message);
    const userId = JSON.parse(localStorage.getItem("session"))?.user_id;
    const elKey = interaction.type == "channels" ? "_id" : "receiver";
    let isGroupMessage =
      interaction.type == "channels" && activeChatId == message?.group;
    const isChatMessage =
      activeChatId == message?.receiver?._id ||
      activeChatId == message?.sender?._id;
    if (isGroupMessage || isChatMessage) {
      setForm((prevState) => ({
        ...prevState,
        chat: [message, ...prevState.chat],
      }));
    } else {
      const userIndex = chatList?.findIndex(
        (el) =>
          el[elKey] == message?.sender?._id ||
          el[elKey] == message?.receiver?._id
      );
      if (userIndex >= 0) {
        let user = chatList[userIndex];
        chatList.splice(userIndex, 1);
        user = {
          ...user,
          message: message?.message,
          ["unread_count"]:
            isGroupMessage || isChatMessage ? 0 : (user?.unread_count || 0) + 1,
          created_at: new Date().toISOString(),
        };
        const newChatList = [user, ...chatList];
        message?.event == "GROUP_MESSAGE"
          ? setContactsList([...newChatList], interaction.activeChat)
          : setGroupsList([...newChatList], interaction.activeChat);
      } else if (
        (message?.event == SOCKETEVENTS.SINGLE_MESSAGE &&
          interaction.type == "message") ||
        (message?.event == SOCKETEVENTS.GROUP_MESSAGE &&
          interaction.type == "channels")
      ) {
        const sender = {
          ...message?.sender,
          unread_count: 1,
          online: true,
          last_seen: new Date().toISOString(),
          created_at: new Date().toISOString(),
          message: message?.message,
          _id: message?.sender?._id,
          receiver: message?.sender?._id,
        };
        message?.event == "GROUP_MESSAGE"
          ? setGroupsList([sender, ...chatList], interaction.activeChat)
          : setContactsList([sender, ...chatList], interaction.activeChat);
      }
    }
  }

  async function listMessages(page, messages) {
    try {
      if (!form.receiver || !form.hasMore) {
        return;
      }
      setShowMobileChat(true);
      setLoader(true);
      if (interaction.type == "channels") {
        const response = await InteractionService.listChannelsMessage(
          form.receiver,
          page,
          pagelimit
        );
        setForm((prevState) => ({
          ...prevState,
          hasMore: response?.data?.length >= pagelimit,
          chat: [...(messages ?? prevState.chat), ...response?.data],
        }));
      } else {
        const response = await InteractionService.listSingleMessage(
          form.receiver,
          page,
          pagelimit
        );
        setForm((prevState) => ({
          ...prevState,
          hasMore: response?.data?.length >= pagelimit,
          chat: [...(messages ?? prevState.chat), ...response?.data],
        }));
      }
    } catch (error) {
      toast.error("Failed to load chat messages.");
    } finally {
      setLoader(false);
    }
  }

  async function handleFormSubmit(e) {
    try {
      e.preventDefault();
      setForm((prevState) => ({
        ...prevState,
        loader: true,
      }));
      let attachments = [];
      if (form.attachments.length > 0) {
        attachments = await FileService.upload(form.attachments);
      }
      let response = false;
      const payload = {
        receiver: form.receiver,
        message: form.message,
        attachments: attachments?.data,
      };
      if (interaction.type == "message") {
        response = await InteractionService.sendMessageToUser(payload);
      } else {
        response = await InteractionService.sendMessageToChannel(payload);
      }
      if (!response) {
        throw "Failed to send message";
      }
      setForm((prevState) => ({
        ...prevState,
        message: "",
        attachments: [],
      }));
    } catch (error) {
      console.log(error);
      toast.error("Failed to send message");
    } finally {
      setForm((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if file size exceeds 5MB
    if (file && file.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds 5MB limit. Please select a smaller file.");
      event.target.value = null; // Clear the file input
    } else if (!allowedMediaTypes.includes(file.type)) {
      toast.error("Please select a PDF, image, or document file.");
    } else {
      setForm((prevState) => ({
        ...prevState,
        attachments: [file],
      }));
    }
    event.target.value = "";
  };

  async function joinChannel() {
    try {
      setJoinGroupLoader(true);
      let response = await InteractionService.joinChannel(
        interaction.activeChat?._id
      );
      fetchGroupList();
      return toast.success(response?.message);
    } catch (error) {
      console.log(error);
      toast.error("Failed to join channel");
    } finally {
      setJoinGroupLoader(false);
    }
  }

  function removeAttachment(index) {
    setForm((prevState) => ({
      ...prevState,
      attachments: [],
    }));
  }

  return (
    <>
      <>
        {JSON.stringify(interaction.activeChat) == "{}" ? (
          <div className="basis-2/3 flex-col relative hidden md:flex">
            {session && !socketContext?.connected && (
              <Loader
                statusName="Catching Up"
                bgColor="bg-theme-blue/20"
                textColor="text-theme-blue"
              />
            )}
            <div className="flex items-center justify-center bg-[#F6F8FC] w-full h-full chat-bg">
              <div className="max-w-[370px] w-full mx-auto text-center">
                <img
                  src={emptyMessage}
                  alt="Empty Message image"
                  className="max-w-full mx-auto mb-5 w-40"
                />
                <h3 className="text-[#0F1828] text-lg text-center font-medium mb-5">
                  Connect easily with peoples, <br /> community, and Channels{" "}
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    className="hover:opacity-90 bg-[#4087F3] py-2 px-4 inline-flex items-center justify-center rounded-3xl text-sm font-medium text-white"
                    onClick={() => {
                      changeType("message");
                      setToggleModelSearch(!toggleModelSearch);
                    }}
                  >
                    {" "}
                    <Plus className="w-5 h-5 mr-1" /> Search Developer
                  </button>
                  <button
                    className="hover:opacity-90 bg-[#0A2F5D] py-2 px-4 inline-flex items-center justify-center rounded-3xl text-sm font-medium text-white"
                    onClick={() => {
                      changeType("channels");
                      setToggleModelSearch(!toggleModelSearch);
                    }}
                  >
                    {" "}
                    <Volume2 className="w-5 h-5 mr-1" /> Join Channel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (interaction.type == "channels" &&
            interaction.activeChat?.is_member) ||
          interaction.type == "message" ? (
          <>
            <div className="basis-2/3 hidden  md:flex flex-col overflow-y-auto">
              {/* Header */}
              <div className="w-full border-b border-b-[#EAEEF6]">
                <div className="flex items-center justify-between px-5 py-3">
                  <div className="relative mr-2">
                    <div className="rounded-full overflow-hidden w-8 h-8 min-w-8 inline-flex items-center justify-center ">
                      <img
                        src={
                          interaction.activeChat?.profile_picture || ChatUser
                        }
                        alt="user icon"
                      />
                    </div>
                    {interaction.type == "channels" ? (
                      ""
                    ) : interaction.activeChat?.online ? (
                      <span className="absolute top-0 right-0 w-2 h-2 bg-theme-green rounded-full ring-2 ring-green-300"></span>
                    ) : (
                      <span className="absolute top-0 right-0 w-2 h-2 bg-gray-400 rounded-full ring-2 ring-gray-200"></span>
                    )}
                  </div>
                  <div className="w-full">
                    <h4 className="text-sm text-[#515151] font-medium leading-4 mb-1">
                      {interaction.activeChat?.name}
                    </h4>
                    {interaction.type == "message" ? (
                      <h6 className="text-[10px] font-normal leading-none">
                        {interaction.activeChat?.online ? (
                          <span className="text-theme-green inline-block font-medium">
                            Online
                          </span>
                        ) : interaction.activeChat?.last_seen ? (
                          <span className="text-gray-600 inline-block font-medium">
                            {" "}
                            Last Seen:{" "}
                            {new Date(
                              interaction.activeChat?.last_seen
                            )?.toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour12: true,
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </span>
                        ) : (
                          ""
                        )}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* End Of Header */}

              {/* Message */}
              <div
                className="grow-[1] bg-[#F6F8FC] px-8 py-12 max-h-[594px] overflow-y-auto flex flex-col-reverse"
                ref={containerRef}
                id="messages"
              >
                {form.chat?.map((message) => (
                  <div key={message?._id}>
                    {message?.type == 4 ? (
                      <div className="flex items-center py-2">
                        <span className="inline-block h-[1px] bg-gray-200 w-full"></span>
                        <p className="text-center text-xs  px-2 whitespace-nowrap">
                          {message?.message}
                        </p>
                        <span className="inline-block h-[1px] bg-gray-200 w-full"></span>
                      </div>
                    ) : message?.sender?._id ==
                      JSON.parse(localStorage.getItem("session"))?.user_id ? (
                      <SendMessageCard message={message} />
                    ) : (
                      <RecievedMessageCard message={message} />
                    )}
                  </div>
                ))}
                <div ref={scrolleObserverRef} id="observer"></div>
                {loader && <ChatMessageSkeleton length={1} />}
              </div>
              {/* End Message */}

              {/* Footer */}
              <div className="">
                {/* Media Preview */}
                {form.attachments?.length > 0 && (
                  <ChatMediaPreview
                    attachments={form.attachments}
                    removeAttachment={removeAttachment}
                    loader={form.loader}
                    device="mobile"
                  />
                )}
                {/* End Of Media Preview */}

                <form onSubmit={handleFormSubmit}>
                  <div className="flex items-center py-[15px] px-7 border-t border-t-[#EAEEF6]">
                    <div className="w-full mr-8">
                      <input
                        type="text"
                        placeholder="Type message..."
                        className="text-sm leading-none placeholder:text-[#A6ADB7] outline-none w-full"
                        maxLength={1000}
                        value={form.message}
                        onChange={(event) =>
                          setForm((prevState) => ({
                            ...prevState,
                            message: event.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="ml-auto flex items-center">
                      <button
                        className="w-7 h-7 flex items-center justify-center rounded-full bg-white hover:bg-gray-100"
                        type="button"
                      >
                        <input
                          type="file"
                          id="file"
                          style={{ display: "none" }}
                          accept=".jpg,.jpeg,.pdf,.doc,.docx"
                          multiple={false}
                          onChange={handleFileChange}
                        />
                        <label htmlFor="file" style={{ cursor: "pointer" }}>
                          <Paperclip className="w-5 h-5 ms-1 text-[#2D4665]" />
                        </label>
                      </button>
                      <button
                        className="text-white text-sm leading-4 bg-[#4087F3] py-2 px-3 rounded-lg flex items-center text-normal ms-4"
                        disabled={
                          loader ||
                          (!form.message && form.attachments?.length <= 0) ||
                          form.loader
                        }
                        type="submit"
                      >
                        Send <Send className="w-[15px] h-[12px] ms-1" />{" "}
                      </button>
                    </div>
                  </div>
                </form>
                {/* End Of Footer */}
              </div>
            </div>

            {showMobileChat && (
              <div className="basis-2/3 flex  md:hidden flex-col overflow-y-auto absolute left-0 top-0 bg-white w-full">
                {/* Header */}
                <div className="w-full border-b border-b-[#EAEEF6]">
                  <div className="flex items-center justify-between px-5 py-3">
                    <button
                      onClick={() => {
                        setShowMobileChat(false);
                      }}
                    >
                      <ArrowLeft className="w-6 h-6 mr-3" />
                    </button>
                    <div className="relative mr-2">
                      <div className="rounded-full overflow-hidden w-8 h-8 min-w-8 inline-flex items-center justify-center ">
                        <img
                          src={
                            interaction.activeChat?.profile_picture || ChatUser
                          }
                          alt="user icon"
                        />
                      </div>
                      {interaction.type == "channels" ? (
                        ""
                      ) : interaction.activeChat?.online ? (
                        <span className="absolute top-0 right-0 w-2 h-2 bg-theme-green rounded-full ring-2 ring-green-300"></span>
                      ) : (
                        <span className="absolute top-0 right-0 w-2 h-2 bg-gray-400 rounded-full ring-2 ring-gray-200"></span>
                      )}
                    </div>
                    <div className="w-full">
                      <h4 className="text-sm text-[#515151] font-medium leading-4 mb-1">
                        {interaction.activeChat?.name}
                      </h4>
                      {interaction.type == "message" ? (
                        <h6 className="text-[10px] font-normal leading-none">
                          {interaction.activeChat?.online ? (
                            <span className="text-theme-green inline-block font-medium">
                              Online
                            </span>
                          ) : interaction.activeChat?.last_seen ? (
                            <span className="text-gray-600 inline-block font-medium">
                              {" "}
                              Last Seen:{" "}
                              {new Date(
                                interaction.activeChat?.last_seen
                              )?.toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                                hour12: true,
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          ) : (
                            ""
                          )}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* End Of Header */}

                {/* Message */}
                <div
                  style={{
                    maxHeight:
                      form.attachments?.length > 0 ? "calc(100vh - 255px)" : "",
                  }}
                  className="grow-[1] bg-[#F6F8FC] px-6 py-8 lg:px-8 lg:py-12 h-[calc(100vh-180px)] lg:max-h-[594px] overflow-y-auto flex flex-col-reverse"
                  ref={containerRef}
                  id="messages"
                >
                  {form.chat?.map((message) => (
                    <div key={message?._id}>
                      {message?.type == 4 ? (
                        <div className="flex items-center py-2">
                          <span className="inline-block h-[1px] bg-gray-200 w-full"></span>
                          <p className="text-center text-xs  px-2 whitespace-nowrap">
                            {message?.message}
                          </p>
                          <span className="inline-block h-[1px] bg-gray-200 w-full"></span>
                        </div>
                      ) : message?.sender?._id ==
                        JSON.parse(localStorage.getItem("session"))?.user_id ? (
                        <SendMessageCard message={message} />
                      ) : (
                        <RecievedMessageCard message={message} />
                      )}
                    </div>
                  ))}
                  <div ref={scrolleObserverRefMobile}></div>
                  {loader && <ChatMessageSkeleton length={1} />}
                </div>
                {/* End Message */}

                {/* Footer */}
                <div className="relative">
                  {/* Media Preview */}
                  {form.attachments?.length > 0 && (
                    <ChatMediaPreview
                      attachments={form.attachments}
                      removeAttachment={removeAttachment}
                      loader={form.loader}
                      device="desktop"
                    />
                  )}
                  {/* End Of Media Preview */}

                  <form onSubmit={handleFormSubmit}>
                    <div className="flex items-center py-[15px] px-7 border-t border-t-[#EAEEF6]">
                      <div className="w-full mr-8">
                        <input
                          type="text"
                          placeholder="Type message..."
                          className="text-sm leading-none placeholder:text-[#A6ADB7] outline-none w-full"
                          maxLength={500}
                          value={form.message}
                          onChange={(event) =>
                            setForm((prevState) => ({
                              ...prevState,
                              message: event.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="ml-auto flex items-center">
                        <button
                          className="w-7 h-7 flex items-center justify-center rounded-full bg-white hover:bg-gray-100"
                          type="button"
                        >
                          <input
                            type="file"
                            id="file"
                            style={{ display: "none" }}
                            accept=".jpg,.jpeg,.pdf,.doc,.docx"
                            multiple={false}
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file" style={{ cursor: "pointer" }}>
                            <Paperclip className="w-5 h-5 ms-1 text-[#2D4665]" />
                          </label>
                        </button>
                        <button
                          className="text-white text-sm leading-4 bg-[#4087F3] py-2 px-3 rounded-lg flex items-center text-normal ms-4"
                          disabled={
                            loader ||
                            (!form.message && form.attachments?.length <= 0) ||
                            form.loader
                          }
                          type="submit"
                        >
                          Send <Send className="w-[15px] h-[12px] ms-1" />{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* End Of Footer */}
                </div>
              </div>
            )}
          </>
        ) : interaction.type == "channels" &&
          !interaction.activeChat?.is_member ? (
          <div className="basis-2/3  flex flex-col  absolute lg:relative top-0 left-0 bg-white z-30 w-full h-full">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center chat-bg px-4">
              <div className="w-full text-center max-w-md mx-auto">
                <img src={Logo} className="mx-auto mb-2" />
                <h2 className="text-lg font-semibold mb-2">
                  {interaction.activeChat?.name}
                </h2>
                <p className="text-sm mb-5">
                  Join our channel for updates, news, and discussions on L1X
                  Blockchain. Stay connected with like-minded individuals and be
                  part of our community!
                </p>
                <button
                  className="text-white text-base leading-4 bg-[#4087F3] py-2.5 px-4 rounded-lg inline-flex items-center text-normal"
                  onClick={() => joinChannel()}
                  disabled={joinGroupLoader}
                >
                  {joinGroupLoader ? "Loading..." : "Join Channel"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default Chat;
