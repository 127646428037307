import axios from "axios";
import axiosInstance from "../axios.interceptor";
import { Service } from "./service";

export class AuthService {
  static async login(data) {
    const url = `${Service.baseurl}/developer/login`;
    const payload = {
      email: data?.email,
      password: data?.password,
    };
    const response = await axiosInstance.post(url, payload);
    return response.data;
  }

  static async register(data) {
    const url = `${Service.baseurl}/developer/signup`;
    const payload = data;
    console.log("payload", payload);
    const response = await axiosInstance.post(url, payload);
    return response.data;
  }

  static async verifyEmail(_id, _data) {
    const url = `${Service.baseurl}/developer/verify-email/${_id}`;
    const payload = _data;
    const response = await axiosInstance.patch(url, payload);
    return response.data;
  }

  static async sendForgotPasswordMail(email) {
    const url = `${Service.baseurl}/developer/send-forgot-password-mail?email=${email}`;
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async resetPassword({ token = "", password = "" }) {
    const payload = {
      password,
    };
    const url = `${Service.baseurl}/developer/reset-password`;
    const response = await axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}
