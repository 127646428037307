import React, { useState } from "react";
import signupBg from "./../../assets/images/signup-bg.jpg";
import layeroneX from "./../../assets/images/layerone-x.svg";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft, Eye } from "react-feather";
import { toast } from "react-toastify";
import { AuthService } from "../../Services/authService";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import Loader from '../../components/Loader';

const Register = () => {
  const [form, setForm] = useState({
    firstName:"",
    lastName:"",
    email: "",
    mobile: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState()
  const [phoneValue, setPhoneValue] = useState();
  const [countryCode, setCountryCode] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!form.firstName || !form.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }

    if (form.firstName?.length > 18) {
      errors.firstName = "Max 18 character allowed.";
      valid = false;
    }

    if (!form.lastName || !form.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }

    if (form.lastName?.length > 18) {
      errors.firstName = "Max 18 character allowed.";
      valid = false;
    }

    if (!form.email || !form.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "Email is invalid";
      valid = false;
    }

    // console.log('phoneValue',phoneValue);
    // return;
    if (!phoneValue || !phoneValue.trim()) {
      errors.mobile = "Phone no. is required";
      valid = false;
    }

    if (!form.password || !form.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    } 
    // else if (
    //   !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}/.test(
    //     form.password.trim()
    //   )
    // ) 
    else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#!@#$%^&*`()\-\+={}|~\\\/<>,.:;]).{8,}/.test(
        form.password.trim()
      )
    ) 
    {
      errors.password =
        "Use an 8+ character password with uppercase, lowercase letters, digits, and a special character.";
      valid = false;
    } else if (form.password?.length > 18) {
      errors.password =
        "Max 18 character are allowed for password.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handlePhoneChange = (value) => {
    if(value)
    {
      const mobileValue = formatPhoneNumberIntl(value); 
      const spaceIndex = mobileValue.indexOf(" ");
      const countryCode = mobileValue.slice(0, spaceIndex);
      const phoneNumber = mobileValue.slice(spaceIndex + 1).replace(/\s/g, "");

      // console.log('countryCode',countryCode,'phoneNumber',phoneNumber)
      setCountryCode(countryCode);
      setPhoneValue(phoneNumber);
    }
  };



  async function handleFormSubmit(e) {
    try {
      e.preventDefault();

      if (validateForm()) {
        setLoader(true)

        const registerDetails = {
            "name": form.firstName + ' ' +form.lastName,
            "email": form.email,
            "password": form.password,
            "phone_number":phoneValue,
            "country_code":countryCode
        }

        let response = await AuthService.register(registerDetails);

       if(response.status == true)
       {
        setLoader(false)
            setForm({
              firstName:"",
              lastName:"",
              email: "",
              mobile: "",
              password: "",
            });
            setPhoneValue("");
            setCountryCode("");
            

          toast.success(response?.message + ", please verify email");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
       }
       setLoader(false)
      }
      setLoader(false)
      // console.log('setErrors',errors)
    
    } catch (error) {
      setLoader(false)
      console.log('error',error);
      // toast.error(error?.message);
      toast.error(error?.response?.data?.message ?? 'Failed to login.');
    }
  }

  return (
    <div className="App has-bg md:px-10">
      <div className="md:flex md:items-center md:justify-center h-screen w-full overflow-y-auto">
        <div className="w-full max-w-screen-xl mx-auto h-screen md:max-h-[767px]  md:h-full overflow-hidden md:rounded-2xl">
          <div className="bg-white h-full md:flex">
            <div className="md:basis-2/3 hidden md:block relative">
              <div className="w-full h-full">
                <img
                  src={signupBg}
                  alt="sign up"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute flex items-center justify-center flex-col top-0 left-0 w-full h-full p-10">
                <div className="w-14 h-14 overflow-hidden mb-5">
                  <img src={layeroneX} alt="logo" />
                </div>
                <h3 className="text-white lg:text-3xl text-2xl font-light text-center">
                  Welcome to{" "}
                </h3>
                <h2 className="text-white font-bold lg:text-[42px] text-4xl  text-center">
                  L1X Developer Platform
                </h2>
              </div>
            </div>
            <div className="basis-1/3 sm:min-w-[380px] relative">
              <div className="md:flex items-center justify-center w-full h-screen md:h-full p-7 sm:p-10 flex-col overflow-y-auto">
                <div className="w-full max-w-[320px] mx-auto text-center mb-8  mt-8 md:mt-0">
                  <div className="w-8 h-8 overflow-hidden mb-2 mx-auto">
                    <img src={layeroneX} alt="logo" />
                  </div>
                  <h4 className="text-black text-2xl font-semibold">
                    L1X Labs{" "}
                  </h4>
                  <h6 className="text-[#2D4665] font-medium text-sm">
                    Create an Account to Explore L1X Labs community.
                  </h6>
                </div>
                <form className="w-full max-w-[320px] mx-auto"
                   onSubmit={handleFormSubmit}>
                    <div className="grid sm:grid-cols-2 gap-x-4">
                      <div className="w-full relative mb-4">
                        <input
                          type="text"
                          placeholder="First Name"
                          className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                          value={form.firstName}
                          onChange={(e) =>
                            setForm((prevState) => ({
                              ...prevState,
                              firstName: e.target.value,
                            }))
                          }
                          maxLength={18}
                        />
                        {errors.firstName && (
                          <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
                        )}
                      </div>
                      <div className="w-full relative mb-4">
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                          value={form.lastName}
                          onChange={(e) =>
                            setForm((prevState) => ({
                              ...prevState,
                              lastName: e.target.value,
                            }))
                          }
                          maxLength={18}
                        />
                        {errors.lastName && (
                          <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
                        )}
                      </div>
                  </div>
                  <div className="w-full relative mb-4">
                    <input
                      type="text"
                      placeholder="Email"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                      value={form.email}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                    />
                     {errors.email && (
                          <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                        )}
                  </div>
                  {/* <div className="w-full relative mb-4">
                    <input
                      type="text"
                      placeholder="Mobile no"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                      value={form.mobile}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          mobile: e.target.value,
                        }))
                      }
                    />
                    {errors.mobile && (
                      <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
                    )}
                  </div> */}
                  <div className='phone-country-picker  mb-4'>
                      <PhoneInput
                          international={true}
                          placeholder="Enter phone number"
                          value={value}
                          onChange={handlePhoneChange}
                          maxLength={18}
                          />

                        {errors.mobile && (
                          <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
                        )}
                  </div>
                  <div className="w-full relative mb-4">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="border border-[#CFD8E7] px-4 py-3 placeholder:text-[#8896A6] text-black text-sm outline-none focus:border-[#4087F3] w-full rounded-lg "
                      value={form.password}
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          password: e.target.value,
                        }))
                      }
                      maxLength={18}
                    />
                    <span className="password-visiblity absolute top-[11px] right-[11px] z-10" onClick={handlePasswordToggle}>
                      {showPassword ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      ) : (

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                      )}
                  </span>
                    {/* <button className="absolute top-[11px] right-[11px] z-10">
                      <Eye className="text-[#8896A6] w-6 h-6 stroke-[1px]" />
                    </button> */}
                      {errors.password && (
                          <p className="text-red-500 text-xs mt-1">{errors.password}</p>
                        )}
                  </div>
                  <div className="w-full relative mb-4">
                    <button type="submit" className="border border-[#4087F3] px-4 py-3 text-white text-sm w-full rounded-lg bg-[#4087F3] hover:bg-black hover:border-black"
                     disabled={loader}>
                      
                      {loader ? "Registering..." : "Sign Up"}
                    </button>
                  </div>
                  <div className="w-full relative mb-4 text-center leading-3">
                    <div className="mb-2 w-full">
                      <h4 className="text-sm text-[#8896A6] hover:text-black ">
                        Already have an account?
                      </h4>
                    </div>
                    <div className="w-full">
                      <Link
                        to="/login"
                        className="text-sm text-[#4087F3] hover:text-black border-b border-b-[#4087F3] hover:border-b-black pb-[2px]"
                      >
                        Login Instead
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="absolute top-4 -left-6 hidden md:block">
                <Link to='/' className="flex items-center px-4 py-2 rounded-full bg-gray-100 text-sm">
                <ArrowLeft className="mr-2 w-5" /> Back to Home</Link>
              </div>
              <div className="absolute top-4 left-4 block md:hidden">
                <Link to='/' className="flex items-center px-2 py-2 rounded-full bg-gray-100 text-sm min-w-[40px]">
                <ArrowLeft className="w-5 mx-auto" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
                <Loader statusName="Registering, please wait" bgColor='bg-theme-green/20' textColor='text-theme-green'/>
            )}
    </div>
  );
};

export default Register;
