import React from "react";
import { Navigate } from "react-router-dom";

const LoginGuard = ({ children }) => {
  const session = JSON.parse(localStorage.getItem("session"));
  return !session?.session_token ? (
    children
  ) : (
    <Navigate to={"/"} />
  );
};

export default LoginGuard;
