import React from 'react'
import { Search } from 'react-feather'

const CommonSearch = () => {
    return (
        <div className="absolute top-0 left-0 w-full h-full z-[51] flex items-start justify-center pt-28">
            <div className="relative z-10">
                <div className="bg-white w-[400px] rounded-md overflow-hidden">
                    <div className="relative">
                        <input className="px-4 py-3 rounded-md bg-white w-full" placeholder="Search by Name, Email" />
                        <Search className="absolute top-1/2 right-3 -translate-y-1/2 text-secondary-text" />
                    </div>
                    <div className="w-full border-t border-gray-200 overflow-y-auto max-h-60 ">
                        <div className="flex items-center py-2.5 px-4 cursor-pointer hover:bg-gray-100 border-b border-gray-100">
                            <div className="w-7 h-7">
                                <img src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" className="w-full h-full flex-shrink-0 rounded-full" />
                            </div>
                            <span className="font-normal ml-3 block truncate" >Wade Cooper</span>
                        </div>
                        <div className="bg-slate-50 px-12 py-16 text-center">
                            <h2 className="font-semibold text-slate-900">No results found</h2>
                            <p className="mt-2 text-sm leading-6 text-slate-600">We can’t find anything with that term at the moment, try searching something else.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-black/50 backdrop-blur-md z-0"></div>
        </div>
    )
}

export default CommonSearch