import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  ArrowLeft,
  ChevronLeft,
  Copy,
  GitHub,
  Gitlab,
  MessageCircle,
  MessageSquare,
} from "react-feather";
import { Link } from "react-router-dom";

const ProtocolRequests = () => {
  const [showDetails, setShowDetails] = useState(false);

  const ShowDetailsHandler = () => {
    setShowDetails(true);
  };

  function copyToClipboard(event, text = "") {
    window.navigator.clipboard.writeText(text);
    event.currentTarget.innerHTML = "";
    const spanElement = document.createElement("span");
    spanElement.textContent = "Copied!";
    const copyComponent = document.createElement("div");
    ReactDOM.render(<Copy className="w-3 ml-1" />, copyComponent);
    event.currentTarget.appendChild(spanElement);
    event.currentTarget.appendChild(copyComponent);
    setTimeout(() => {
      spanElement.innerHTML = "Copy";
    }, 1000);
  }

  return (
    <div className="inner-content-wrapper px-6 lg:px-12">
      {!showDetails ? (
        <>
          <div className="w-full flex items-center justify-between sticky left-0 bg-white top-0 pb-5 pt-6 lg:pt-9">
            <div className="grid grid-cols-2 bg-[#E9EDF3] rounded-2xl  mx-auto md:mx-0">
              <Link
                to="/improvement-protocol/overview"
                className="text-xs text-center rounded-3xl text-[#2D4665] px-3 py-2"
              >
                Overview
              </Link>
              <Link
                to="/improvement-protocol/protocol-requests"
                className="text-xs text-center rounded-3xl px-3 py-2 bg-[#4087F3] text-white"
              >
                Protocol Requests
              </Link>
            </div>
          </div>
          <div className="w-full pb-5">
            <div className="w-full mb-4">
              <h1 className="text-xl lg:text-2xl font-semibold mb-4">
                Protocol Improvement Requests
              </h1>
            </div>
            {/* <div className="w-full rounded-lg overflow-hidden">
              <table className="w-full border border-[#E9EDF3}">
                <thead>
                  <tr>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left py-2 px-4">
                      Numbers
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Title
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Tags
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Authors
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Stage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">270</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Add BLAKE2 compression function `F` precompile
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-yellow text-white px-3 py-1 inline-block text-center rounded-md">
                        X-Talk
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Alex Beregszaszi{" "}
                        <Link className="text-[#4087F3]">(@axic)</Link>,
                      </span>
                      <span className="block">
                        Paweł Bylica{" "}
                        <Link className="text-[#4087F3]">(@chfast)</Link>
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Open
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">150</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Homestead Hard-fork Changes
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-green text-white px-3 py-1 inline-block text-center rounded-md">
                        Core Protocol
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">c@ethdev.com</Link>,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Review & Feedback
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">10</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Change difficulty adjustment to target mean block time
                      including uncles
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-purple text-white px-3 py-1 inline-block text-center rounded-md">
                        Toolkit and SDK
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">
                          Christian Reitwiessner 
                        </Link>
                        ,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Finalisation
                    </td>
                  </tr>
                  <tr onClick={() => ShowDetailsHandler()}>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">270</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Add BLAKE2 compression function `F` precompile
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-yellow text-white px-3 py-1 inline-block text-center rounded-md">
                        X-Talk
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Alex Beregszaszi{" "}
                        <Link className="text-[#4087F3]">(@axic)</Link>,
                      </span>
                      <span className="block">
                        Paweł Bylica{" "}
                        <Link className="text-[#4087F3]">(@chfast)</Link>
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Open
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">150</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Homestead Hard-fork Changes
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-green text-white px-3 py-1 inline-block text-center rounded-md">
                        Core Protocol
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">c@ethdev.com</Link>,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Review & Feedback
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">10</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Change difficulty adjustment to target mean block time
                      including uncles
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-purple text-white px-3 py-1 inline-block text-center rounded-md">
                        Toolkit and SDK
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">
                          Christian Reitwiessner 
                        </Link>
                        ,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Finalisation
                    </td>
                  </tr>
                  <tr onClick={() => ShowDetailsHandler()}>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">270</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Add BLAKE2 compression function `F` precompile
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-yellow text-white px-3 py-1 inline-block text-center rounded-md">
                        X-Talk
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Alex Beregszaszi{" "}
                        <Link className="text-[#4087F3]">(@axic)</Link>,
                      </span>
                      <span className="block">
                        Paweł Bylica{" "}
                        <Link className="text-[#4087F3]">(@chfast)</Link>
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Open
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">150</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Homestead Hard-fork Changes
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-green text-white px-3 py-1 inline-block text-center rounded-md">
                        Core Protocol
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">c@ethdev.com</Link>,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Review & Feedback
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">10</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Change difficulty adjustment to target mean block time
                      including uncles
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-purple text-white px-3 py-1 inline-block text-center rounded-md">
                        Toolkit and SDK
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">
                          Christian Reitwiessner 
                        </Link>
                        ,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Finalisation
                    </td>
                  </tr>
                  <tr  onClick={() => ShowDetailsHandler()} className="cursor-pointer">
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">10</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Change difficulty adjustment to target mean block time
                      including uncles
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-purple text-white px-3 py-1 inline-block text-center rounded-md">
                        Toolkit and SDK
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Christian Reitwiessner {" "}
                        <Link className="text-[#4087F3]">
                          Christian Reitwiessner 
                        </Link>
                        ,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Finalisation
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            <div className="w-full rounded-lg overflow-y-auto mb-5 text-sm">
              <table className="w-full border border-[#E9EDF3]">
                <thead>
                  <tr>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left py-2 px-4">
                      LIP
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Title
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Type
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Authors
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Created
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={() => ShowDetailsHandler()}
                    className="cursor-pointer"
                  >
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">2</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      L1X Toolkit
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-yellow text-white px-3 py-0.5 inline-block text-center rounded-md">
                        Tools
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Kevin Coutinho{" "}
                        <Link className="text-[#4087F3]">(@kevincoutinho.l1x)</Link>,
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      2024-04-18
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Open
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full pt-9">
          <button
            className="bg-[#2D4665]/10 w-8 h-8 flex items-center justify-center rounded-full mb-3"
            onClick={() => setShowDetails(false)}
          >
            <ArrowLeft className="w-[18px] h-[18px] stroke-[1.5px]" />
          </button>
          <div className="w-full mb-4">
            <h1 className="text-2xl font-semibold mb-4">L1X Toolkit</h1>

            <div className="w-full rounded-lg overflow-y-auto mb-5 text-sm">
              <table className="w-full border border-[#E9EDF3}">
                <thead>
                  <tr>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left py-2 px-4">
                      LIP
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Title
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Type
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Authors
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Created
                    </th>
                    <th className="text-[#2D4665] bg-[#E9EDF3] text-[13px] font-medium text-left  py-2 px-4">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={() => ShowDetailsHandler()}
                    className="cursor-pointer"
                  >
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="text-[#4087F3] font-medium">2</span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      L1X Toolkit
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="whitespace-nowrap bg-theme-yellow text-white px-3 py-0.5 inline-block text-center rounded-md">
                        Tools
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      <span className="block">
                        Kevin Coutinho{" "}
                        <Link className="text-[#4087F3]">(@kevicoutinho.l1x)</Link>
                      </span>
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      2024-04-18
                    </td>
                    <td className="text-[#2D4665] text-[13px] border-b border-b-[#E9EDF3] font-normal py-2 px-4">
                      Open
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="max-w-3xl pb-10">
              <h4 className="text-lg font-semibold mb-4">Simple Summary</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The L1X Toolkit offers developers a comprehensive suite of tools
                and utilities to streamline L1X blockchain development,
                including account management, contract deployment, and
                interaction with L1X contracts.
              </p>

              <h4 className="text-lg font-semibold mb-4">Abstract</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The L1X Toolkit provides a robust set of tools and utilities
                designed to simplify the development and deployment of
                decentralized applications on the L1X blockchain. From account
                management to contract deployment and interaction, the toolkit
                offers an end-to-end solution for developers, enhancing the
                efficiency and reliability of L1X blockchain development.
              </p>
              <h4 className="text-lg font-semibold mb-4">Motivation</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                With the growing complexity of blockchain development, there's a
                need for standardized tools to facilitate the development
                process. The L1X Toolkit addresses this need by offering a
                unified set of tools and utilities tailored for the L1X
                blockchain, thereby lowering the entry barrier for developers
                and fostering innovation within the Layer One X ecosystem.
              </p>

              <h4 className="text-lg font-semibold mb-4">Specification</h4>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold text-black">
                  Setup Environment:{" "}
                </span>{" "}
                Install Node.js, npm, Rust, and Cargo to run and manage L1X
                projects.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold text-black">
                  Wallet Management:{" "}
                </span>{" "}
                Create and manage L1X wallets for asset management and contract
                deployment.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold text-black">
                  Account Management:{" "}
                </span>{" "}
                Check balances, transfer L1X coins, and manage account nonces.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold text-black">
                  Transaction Management:{" "}
                </span>
                Retrieve transaction receipts and fetch transaction events for
                debugging and application logic.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold text-black">
                  Contract Development:{" "}
                </span>
                Create, build, deploy, and interact with L1X smart contracts
                using predefined templates.
              </p>

              <h4 className="text-lg font-semibold mb-4">
                Example of Development Flow
              </h4>

              <h4 className="text-lg font-semibold mb-4">Introduction</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Welcome to the L1X Developer Toolkit Guide, the comprehensive
                manual for developers seeking to leverage the L1X blockchain for
                developing decentralized applications. This document serves as
                your roadmap through installing necessary tools, creating
                wallets, managing accounts, and deploying contracts within the
                L1X ecosystem.
              </p>

              <h4 className="text-2xl font-semibold mb-4">Getting Started</h4>
              <h4 className="text-lg font-semibold mb-4">Setup environment</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                1. <b>Node.js</b> and <b>npm</b>: Essential for running
                JavaScript projects and managing dependencies. Install{" "}
                <code className="px-1 py-0.5 bg-gray-200 rounded">
                  l1x-toolkit
                </code>{" "}
                and{" "}
                <code className="px-1 py-0.5 bg-gray-200 rounded"> l1x</code>{" "}
                CLI tools:
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code>npm install l1x l1x-toolkit</code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(e, "npm install l1x l1x-toolkit")
                  }
                >
                  <span attributeName="copytext">Copy</span>{" "}
                  <Copy className="w-3 ml-1" />
                </button>
              </div>

              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                1. <b>Rust</b> and <b>Cargo</b>: Required for compiling Rust
                projects and managing Rust packages. Install{" "}
                <code className="px-1 py-0.5 bg-gray-200 rounded">cargo</code>{" "}
                and{" "}
                <code className="px-1 py-0.5 bg-gray-200 rounded">
                  {" "}
                  cargo-l1x
                </code>{" "}
                plugin to get started:
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block"># Install cargo</code>
                <code className="block">
                  {" "}
                  curl https://sh.rustup.rs -sSf | sh
                </code>
                <code className="block"> # Install cargo l1x plugin</code>
                <code className="block"> cargo install cargo-l1x</code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `curl https://sh.rustup.rs -sSf | sh \n cargo install cargo-l1x`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-2xl font-semibold mb-6">Create Wallet</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Begin your L1X journey by creating a wallet:
              </p>
              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block">l1x wallet create</code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) => copyToClipboard(e, `l1x wallet create`)}
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                This command generates a new L1X wallet, including a private key
                and associated addresses, which you'll use to manage assets and
                deploy contracts.
              </p>

              <h4 className="text-2xl font-semibold mb-6">Core Methods:</h4>

              <h4 className="text-2xl font-semibold mb-4">
                Account Management
              </h4>
              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    Check Balance and Nonce:
                  </b>
                </li>
              </ul>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Retrieve your account's balance and nonce, essential for
                transaction management.
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block">
                  l1x account:balance {"<L1X_ADDRESS>"} —network local|testnet
                </code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x account:balance <L1X_ADDRESS> —network local|testnet`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>
              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Response:</b>
                </li>
              </ul>
              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block">
                  {"{"}
                  {Object.entries({
                    balance: "158",
                    nonce: 0,
                    account_type: 1,
                    balance_formatted: "158000000000000000000",
                  }).map(([key, value]) => (
                    <div key={key}>
                      &nbsp;&nbsp;"{key}": "{value}",
                    </div>
                  ))}
                  {"}"}
                </code>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
  "balance": "158",
  "nonce": "0",
  "account_type": "1",
  "balance_formatted": "158000000000000000000",
}`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    To Transfer Native L1X Coin:
                  </b>
                </li>
              </ul>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Transfer L1X coins to another address, a basic yet crucial
                operation.
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm pr-20">
                <code className="block">
                  l1x account:transfer --to {"<L1X_ADDRESS>"} --value{" "}
                  {"<L1X_AMOUNT>"} --privateKey {"<PRIVATE_KEY>"} —network
                  local|testnet
                </code>
                <code className="block">
                  - `L1X_AMOUNT` should be in decimals (10 ^ 18)
                </code>
                <code className="block">
                  - Example: 10 L1X = 10 x (10 ^ 18)
                </code>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x account:transfer --to <L1X_ADDRESS> --value <L1X_AMOUNT> --privateKey <PRIVATE_KEY> —network local|testnet
- L1X_AMOUNT should be in decimals (10 ^ 18)
- Example: 10 L1X = 10 x (10 ^ 18)`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Response:</b>
                </li>
              </ul>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block">
                  {"{"}
                  {Object.entries({
                    txHash: "TX_HASH",
                  }).map(([key, value]) => (
                    <div key={key}>
                      &nbsp;&nbsp;"{key}": "{value}",
                    </div>
                  ))}
                  {"}"}
                </code>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "txHash": "TX_HASH",
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-2xl font-semibold mb-4">
                Transaction Management
              </h4>

              <h4 className="text-lg font-semibold mb-4">
                Retrieve Transaction Receipt:
              </h4>
              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    Fetch the receipt of a submitted transaction using its hash.
                  </b>
                </li>
              </ul>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm pr-20">
                <code className="block">
                  l1x tx:receipt {"<TX_HASH>"} —network local|testnet{" "}
                  {"<L1X_ADDRESS>"}{" "}
                </code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x tx:receipt <TX_HASH> —network local|testnet <L1X_ADDRESS>`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Response</b>
                </li>
              </ul>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm pr-20">
                <code className="block">
                  {`
{
  "transaction": {
    "tx_type": 4,
    "transaction": {
      "SmartContractFunctionCall": [Object]
    }
  },
  "block_hash": "d122e87c7939a93c3743dd7433d92515c65f13a9bddd350c866b7ce129bed0d4",
  "block_number": 5178109,
  "fee_used": "1",
  "timestamp": 1706513499429,
  "from": "51ab9c4805ff60048e05b7535be0081b271068fd",
  "transaction_hash": "fe014c6a9ebc6d14c823fd12ddaa83f0bf6c747d228ccbcea24a1cd120f5dd85"
}
        `
                    .split("\n")
                    .map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                </code>
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "transaction": {
                        "tx_type": 4,
                        "transaction": {
                          "SmartContractFunctionCall": [Object]
                        }
                      },
                      "block_hash": "d122e87c7939a93c3743dd7433d92515c65f13a9bddd350c866b7ce129bed0d4",
                      "block_number": 5178109,
                      "fee_used": "1",
                      "timestamp": 1706513499429,
                      "from": "51ab9c4805ff60048e05b7535be0081b271068fd",
                      "transaction_hash": "fe014c6a9ebc6d14c823fd12ddaa83f0bf6c747d228ccbcea24a1cd120f5dd85"
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    Fetch Transaction Events:
                  </b>
                </li>
              </ul>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Access the events triggered by a transaction, invaluable for
                debugging and application logic.
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm pr-20">
                <code className="block">bashCopy code</code>
                <code className="block">
                  l1x tx:events {"<TX_HASH>"} —network local|testnet
                </code>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x tx:events <TX_HASH> —network local|testnet`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Response:</b>
                </li>
              </ul>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The code block is as follows:
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <code className="block">
                  {"{"}
                  {Object.entries({
                    events: "[...]",
                  }).map(([key, value]) => (
                    <div key={key}>
                      &nbsp;&nbsp;"{key}": "{value}",
                    </div>
                  ))}
                  {"}"}
                </code>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "events": "[...]",
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-2xl font-semibold mb-4">
                Developing with L1X Contracts
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Developing and deploying smart contracts is at the heart of L1X
                blockchain development.
              </p>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Create a new project
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <pre className="block">
                  cargo l1x create &lt;name&gt; <br />
                </pre>

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) => copyToClipboard(e, `cargo l1x create <name>`)}
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">
                Contract Development Workflow
              </h4>
              <h4 className="text-base font-semibold mb-4">
                Directory Structure:
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Organize your project files efficiently for development and
                testing.
              </p>

              <ul className="list-disc list-inside mb-4 text-sm text-[#2D4665]">
                <li className="mb-2">
                  The directory structure for the project should include the
                  following:
                </li>
                <li className="mb-2">
                  <div className="inline-flex items-center flex-wrap">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      contracts/:
                    </pre>{" "}
                    This directory will contain all your contract files.
                  </div>
                </li>
                <li className="mb-2">
                  <div className="inline-flex items-center flex-wrap">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      test/:
                    </pre>{" "}
                    This is where your test files will be stored.
                  </div>
                </li>
                <li className="mb-2">
                  <div className="inline-flex items-center flex-wrap">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      l1x.config/:
                    </pre>{" "}
                    This file will hold your project's configuration settings.
                  </div>
                </li>
              </ul>

              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Config file structure :{" "}
                <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100 inline-block">
                  l1x.config
                </pre>
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
{
  "networks": {
    "local": {
      "endpoint": LOCAL_ENDPOINT,
      "clusterAddress": CLUSTER_ADDRESS
    },
    "testnet": {
      "endpoint": Testnet_ENDPOINT,
      "clusterAddress": CLUSTER_ADDRESS
    }
  }
}
</pre>
`,
                  }}
                />

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "networks": {
                        "local": {
                          "endpoint": LOCAL_ENDPOINT,
                          "clusterAddress": CLUSTER_ADDRESS
                        },
                        "testnet": {
                          "endpoint": Testnet_ENDPOINT,
                          "clusterAddress": CLUSTER_ADDRESS
                        }
                      }
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">
                Build L1X contract:
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                Compile your L1X contracts with ease:
              </p>

              <div className="p-3 pr-20 bg-gray-100 text-primary-text rounded-lg mb-4 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
cargo l1x build -p l1x-contract --release
</pre>
`,
                  }}
                />

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `cargo l1x build -p l1x-contract --release`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-base font-semibold mb-4">
                Configure Chain Params:
              </h4>

              <ul className="list-disc list-inside mb-4 text-sm text-[#2D4665]">
                <li className="mb-2">
                  <div className="inline-flex items-center">
                    Edit{" "}
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      l1x.config
                    </pre>{" "}
                  </div>
                </li>
              </ul>

              <h4 className="text-base font-semibold mb-4">
                Types of Contract Call Type
              </h4>
              <ul className="list-disc list-inside mb-4 text-sm text-[#2D4665]">
                <li className="mb-2">
                  Call:
                  <ul className="list-disc list-inside pl-4">
                    <li>
                      To Make State changing call, which will involve making a
                      transaction
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  View:
                  <ul className="list-disc list-inside pl-4">
                    <li>
                      To Make Read only call, which will not involve making a
                      transaction
                    </li>
                  </ul>
                </li>
              </ul>

              <h4 className="text-base font-semibold mb-4">
                Command Params in Command:
              </h4>

              <ol className="list-decimal list-outside ps-4 mb-4 text-sm text-[#2D4665]">
                <li className="mb-2">
                  <div className="inline-flex items-center flex-wrap">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      -network local|testnet
                    </pre>
                    is an option you specify to choose the network where you
                    want to deploy.{" "}
                  </div>
                </li>
                <ul className="list-disc list-inside pl-4">
                  <li>
                    <div className=" flex-wrap inline-flex items-center mb-2">
                      If you choose{" "}
                      <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                        local
                      </pre>
                      , it means you want to deploy the target locally.
                    </div>
                  </li>
                  <li>
                    <div className=" flex-wrap inline-flex items-center mb-2">
                      If you choose{" "}
                      <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                        testnet
                      </pre>
                      , it means you want to deploy the target on a test
                      network.
                    </div>
                  </li>
                </ul>
              </ol>

              <h4 className="text-lg font-semibold mb-4">Start Node</h4>

              <h4 className="text-lg font-semibold mb-4">
                To Start Local Node (Testing Only)
              </h4>
              <div className="w-full">
                <p className="text-sm mb-5 text-[#2D4665] leading-6 inline-block pb-1 border-b border-gray-200">
                  // Can be started without DevBox. Need to update Binaries.
                </p>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Command:</i>
                  </b>
                </li>
              </ul>

              <p className=" flex-wrap inline-flex items-center mb-2 text-sm flex-wrap">
                <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100 whitespace-pre-wrap">
                  l1x deploy {"<CONTRACT_NAME>"} --network local|testnet
                </pre>{" "}
                is a command line instruction.
              </p>

              <ul className="list-decimal list-outside ps-4 mb-4 text-sm">
                <li>
                  <p className=" flex-wrap inline-flex items-center mb-2">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      l1x deploy
                    </pre>{" "}
                    is the command to deploy a target using the l1x deployment
                    tool.
                  </p>
                </li>
                <li>
                  <p className=" flex-wrap inline-flex items-center mb-2">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      {"<CONTRACT_NAME>"}
                    </pre>{" "}
                    is a placeholder that you replace with the name of the
                    contract , contracts directory .
                  </p>
                </li>
              </ul>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Response:</i>
                  </b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
{
  "txHash": "TX_HASH",
  "contractAddress": "BASE_CONTRACT_ADDRESS"
}
</pre>
`,
                  }}
                />

                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "txHash": "TX_HASH",
                      "contractAddress": "BASE_CONTRACT_ADDRESS"
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">
                Initialise L1X contract
              </h4>

              <p className="text-sm mb-5 text-[#2D4665] leading-6 ">
                Invoke contract methods to interact with deployed contracts,
                either modifying the state or querying data.
              </p>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Command:</i>
                  </b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div

                  dangerouslySetInnerHTML={{
                    __html: `
<pre class="whitespace-pre-wrap">
l1x init-contract <BASE_CONTRACT_ADDRESS> --params "{}" --network local|testnet
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x init-contract  --params "{}" --network local|testnet`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-decimal list-outside ps-4 mb-4 text-sm">
                <li>
                  <p className=" flex-wrap inline-flex items-center mb-2">
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      l1x init-contract {"<BASE_CONTRACT_ADDRESS>"}
                    </pre>{" "}
                    is a command line instruction for initialising a smart
                    contract.
                  </p>
                </li>
                <li>
                  The command initialises a contract at the specified base
                  contract address.
                </li>
                <li>
                  <p className=" flex-wrap inline-flex items-center mb-2">
                    It takes parameters in JSON format, signified by{" "}
                    <pre className="text-red-600 px-1 py-0.5 rounded bg-gray-100">
                      -params "{}"
                    </pre>
                    .
                  </p>
                </li>
              </ul>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Response:</i>
                  </b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
{
  "txHash": "TX_HASH",
  "contractAddress": "INITIALISED_CONTRACT"
}
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "txHash": "TX_HASH",
                      "contractAddress": "INITIALISED_CONTRACT"
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">
                Call a L1X contract method (State Changing)
              </h4>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Command:</i>
                  </b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
l1x call <INITIALISED_CONTRACT_ADDRESS> <method_name> [arguments] --network local|testnet
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x call   [arguments] --network local|testnet`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">
                    <i>Response:</i>
                  </b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
{
  "txHash": "TX_HASH"
}
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "txHash": "TX_HASH"
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">
                Call a Read-only L1X contract method
              </h4>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Command:</b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
l1x view <INITIALISED_CONTRACT_ADDRESS> <method_name> [arguments] --network local|testnet
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `l1x view   [arguments] --network local|testnet`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <ul className="list-disc list-inside mb-4">
                <li>
                  <b className="font-semibold text-sm">Response:</b>
                </li>
              </ul>

              <div className="pr-20  p-3 bg-gray-100 text-primary-text rounded-lg mb-6 relative text-xs sm:text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
<pre>
{
  "result": "Sample Response"
}
</pre>
`,
                  }}
                />
                <button
                  className="bg-gray-200 hover:bg-gray-300 px-2 py-0.5 text-xs rounded flex items-center absolute top-2 right-2"
                  onClick={(e) =>
                    copyToClipboard(
                      e,
                      `{
                      "result": "Sample Response"
                    }`
                    )
                  }
                >
                  Copy <Copy className="w-3 ml-1" />
                </button>
              </div>

              <h4 className="text-lg font-semibold mb-4">Rationale</h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The L1X Toolkit is designed to address the specific needs of L1X
                blockchain developers by providing a comprehensive set of tools
                and utilities. From setting up the development environment to
                deploying and interacting with smart contracts, the toolkit aims
                to simplify and standardize the development process, making it
                easier for developers to build decentralized applications on the
                L1X blockchain.
              </p>

              <h4 className="text-lg font-semibold mb-4">
                Backwards Compatibility
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                As the L1X blockchain is newly introduced and developers are yet
                to begin development, the L1X Toolkit is designed to provide a
                future-proof foundation without concerns of backward
                compatibility issues. Developers starting fresh on the L1X
                blockchain can fully leverage the toolkit's features and tools
                from the outset, ensuring compatibility with the latest
                standards and best practices. The toolkit offers a streamlined
                and unified development environment tailored for the L1X
                blockchain, allowing developers to adopt a cohesive and
                efficient approach from the beginning of their projects.
                Comprehensive documentation and guides will be available to
                assist developers in utilizing the toolkit effectively, ensuring
                a smooth and productive development experience on the L1X
                blockchain
              </p>

              <h4 className="text-lg font-semibold mb-4">
                Backwards Compatibility
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                As the L1X blockchain is newly introduced and developers are yet
                to begin development, the L1X Toolkit is designed to provide a
                future-proof foundation without concerns of backward
                compatibility issues. Developers starting fresh on the L1X
                blockchain can fully leverage the toolkit's features and tools
                from the outset, ensuring compatibility with the latest
                standards and best practices. The toolkit offers a streamlined
                and unified development environment tailored for the L1X
                blockchain, allowing developers to adopt a cohesive and
                efficient approach from the beginning of their projects.
                Comprehensive documentation and guides will be available to
                assist developers in utilizing the toolkit effectively, ensuring
                a smooth and productive development experience on the L1X
                blockchain
              </p>

              <h4 className="text-2xl font-semibold mb-4">Test Cases</h4>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold">Scenario 1: </span>
                Install `l1xtoolkit` and `l1x` CLI tools using npm and cargo.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold">Scenario 2: </span>
                Create an L1X wallet and manage accounts, checking balances and
                transferring L1X coins.
              </p>
              <p className="text-sm mb-3 text-[#2D4665] leading-6">
                <span className="inline-block font-semibold">Scenario 3: </span>
                Deploy an L1X smart contract using `cargo l1x` and interact with
                the deployed contract using `l1x` CLI tools.
              </p>
              <h4 className="text-lg font-semibold mb-4">
                Reference Implementation
              </h4>
              <p className="text-sm mb-5 text-[#2D4665] leading-6">
                A reference implementation of the L1X Toolkit is available on
                GitHub, showcasing how to use the toolkit's components in
                practice. This reference implementation serves as a guide for
                developers, demonstrating best practices and helping to identify
                any potential issues with the proposal.
              </p>
              <p className="text-sm sm:mb-32 text-[#2D4665] leading-6 ">
                By adopting the L1X Toolkit, developers can streamline their L1X
                blockchain development process, benefiting from standardized
                tools and utilities tailored for the L1X ecosystem. This will
                ultimately contribute to the growth and success of decentralized
                applications built on the L1X blockchain.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProtocolRequests;
