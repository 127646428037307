import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env?.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = JSON.parse(localStorage.getItem("session"))?.session_token;
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.status_code != 200) {
      throw response.data;
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
