import React, { useEffect, useState } from "react";
import ChatUser from "../assets/images/user.jpg";
import { toast } from "react-toastify";
import { NotificationService } from "../Services/notificationService";
import Skeleton from "react-loading-skeleton";

const Notification = () => {
  const [notifications, setNotifications] = useState({
    loader: false,
    list: [],
  });

  useEffect(() => {
    fetchNotification();
  }, []);

  async function fetchNotification() {
    try {
      setNotifications((prevState) => ({
        ...prevState,
        loader: true,
      }));
      const notifications = await NotificationService.listNotifications();
      setNotifications((prevState) => ({
        ...prevState,
        list: notifications?.data?.results ?? [],
      }));
    } catch (error) {
      toast.error("Failed to fetch notifications.");
    } finally {
      setNotifications((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  }

  async function clearNotifications() {
    try {
      setNotifications((prevState) => ({
        ...prevState,
        loader: true,
      }));
      await NotificationService.clearNotifications();
      setNotifications((prevState) => ({
        ...prevState,
        list: [],
      }));
    } catch (error) {
      toast.error("Failed to clear notifications.");
    } finally {
      setNotifications((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  }

  return (
    <>
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h6 className="text-black text-md font-semibold">Notifications</h6>
          {!notifications.loader && notifications.list.length > 0 && (
            <button
              className="px-2 py-1 bg-blue-50 hover:bg-blue-100 text-xs rounded-md"
              onClick={clearNotifications}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      {!notifications.loader && notifications?.list?.length > 0 ? (
        notifications.list?.map((notification, index) => (
          <div
            className="px-4 py-3 flex items-start w-full"
            key={notification?._id ?? index}
          >
            <div className="w-8 h-8 min-w-8 flex items-center justify-center rounded-full overflow-hidden mr-3">
              <img
                src={notification?.sender?.profile_picture || ChatUser}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full">
              <div className="flex items-center justify-between">
                <h4 className="text-sm text-black font-medium truncate max-w-[110px]">
                  {notification?.sender?.name ?? ""}
                </h4>
                <h6 className="text-[#7C7C7C] text-[10px] whitespace-nowrap">
                  {new Date(notification?.created_at)?.toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </h6>
              </div>

              <p className="text-[#7C7C7C] text-xs truncate max-w-[200px]">
                {notification?.message ?? ""}
              </p>
            </div>
            <div></div>
          </div>
        ))
      ) : !notifications.loader ? (
        <div className="px-4 py-3 flex items-center justify-center gap-3">
          <h6 className="text-[#7C7C7C] text-xs text-ellipsis line-clamp-2 py-4">
            No Notification Found
          </h6>
        </div>
      ) : (
        ""
      )}

      {notifications.loader &&
        new Array(1).fill(1).map((el, index) => (
          <div
            className=" md:max-w-[343px] flex items-center justify-between w-full px-5 py-3 "
            key={index}
          >
            <div className="mr-3 rounded-full w-11 h-11 min-w-11">
              <Skeleton
                circle={true}
                className=" w-11 h-11 min-w-11"
                containerClassName=""
              />
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center">
                <Skeleton className="w-11 min-w-24 h-4 inline-block" />
                <span className="ms-auto">
                  <Skeleton className="w-5 min-w-10 h-4 inline-block" />
                </span>
              </div>
              <div className="flex items-center">
                <Skeleton
                  className="w-full min-w-fit h-3 inline-block"
                  containerClassName="w-full"
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Notification;
