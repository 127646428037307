import React, { useState, useEffect, useContext } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AlignLeft,
  Bell,
  Book,
  Bookmark,
  ChevronDown,
  ExternalLink,
  Link2,
  // Link,
  LogOut,
  Search,
  User,
} from "react-feather";
import { Menu, Transition } from "@headlessui/react";
import profileImage from "./../assets/images/user.jpg";
import UserContext from "../providers/UserContext";
import Notification from "./Notification";
import PageTextContext from "../providers/PageTextContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ sidebarHandler }) => {
  const { user, logout } = useContext(UserContext);
  const { pageData, updatePageData } = useContext(PageTextContext);
  const navigate = useNavigate();

  const [session, setSession] = useState("");

  useEffect(() => {
    const sessionNew = JSON.parse(localStorage.getItem("session"));
    setSession(sessionNew);
  }, []);

  async function redirectToLogin() {
    navigate("/login", { state: { redirect: "/information" } });
  }

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleLogout = () => {
    logout(navigate);
  };

  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const handleProfileClick = (_type) => {
    if (_type == "profile") {
      updatePageData({ pageTitle: "Profile" });
      localStorage.setItem(
        "pageData",
        JSON.stringify({ pageTitle: "Profile" })
      );
    } else if (_type == "session") {
      updatePageData({ pageTitle: "Sessions" });
      localStorage.setItem(
        "pageData",
        JSON.stringify({ pageTitle: "Sessions" })
      );
    } else if (_type == "session") {
      updatePageData({ pageTitle: "Sessions" });
      localStorage.setItem(
        "pageData",
        JSON.stringify({ pageTitle: "Sessions" })
      );
    } else if (_type == "courses") {
      updatePageData({ pageTitle: "Courses" });
      localStorage.setItem(
        "pageData",
        JSON.stringify({ pageTitle: "Courses" })
      );
    }
  };

  return (
    <div className="flex items-center py-2 px-5 border-b-[1px] border-b-[#EAEEF6] relative z-20 min-h-[49px]">
      <button className="block lg:hidden me-4" onClick={() => sidebarHandler()}>
        <AlignLeft className="w-5 h-5" />
      </button>
      <div className="me-4">
        <h2 className="text-black font-medium text-md leading-6 hidden md:block">
          {window.location.pathname === "/information" ||
          window.location.pathname === "/"
            ? "L1X Development Environment"
            : pageData.pageTitle}
        </h2>
      </div>
      <div className="ms-auto flex items-center">
        {/* <div className="w-full relative ms-4" onClick={toggleSearch}>
          <input
            type="text"
            placeholder="Search"
            className="placeholder:text-[#2D4665] text-black border border-[#EAEEF6]/75 ps-4 pe-8 py-1 rounded-[25px] text-sm outline-none focus:border-slate-400 w-full min-w-[245px] leading-5"
          />
          <Search className="w-5 h-5 text-[#2D4665]/50 absolute top-[5px] right-3" />
        </div> */}
        {user?.isLogin && (
          <div className="ms-4 relative">
            <span className="bg-[#F13312] inline-block w-[6px] h-[6px] rounded-full absolute top-[5px] right-2"></span>
            <Menu as="div" className="relative inline-block text-left">
              <span className="bg-[#F13312] inline-block w-[6px] h-[6px] rounded-full absolute top-[5px] right-2"></span>
              <Menu.Button className="border border-[#d5d8da] rounded-full w-8 h-8 focus:border-slate-400 outline-none flex items-center justify-center">
                <Bell className="w-5 h-5 text-[#2D4665]/50 " />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-auto -right-10 z-20 mt-2 w-[300px] origin-top-right  border border-gray-200 bg-white shadow-card-shadow-xxl  focus:outline-none rounded-xl overflow-hidden">
                  <div className="max-h-72 overflow-x-auto">
                    <Notification />
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
        <div className="md:ms-4 relative flex items-center">
          <Link
            to="https://docsend.com/view/yxxumg97x5mzv77v"
            target="_blank"
            className="px-3 py-2 sm:px-4 font-medium text-xs sm:text-sm bg-[#9263d5] text-white rounded-md min-w-[80px] hidden md:block"
          >
            Whitepaper
          </Link>
          {user?.isLogin
            ? session && (
                <Menu
                  as="div"
                  className="relative inline-block text-left  ms-3"
                >
                  <div>
                    <Menu.Button className="text-[#2D4665] text-sm whitespace-nowrap flex items-center focus:border-slate-400 outline-none">
                      <span className="w-8 h-8 rounded-full overflow-hidden inline-flex me-2">
                        <img
                          src={user?.profile || profileImage}
                          alt="Profile image"
                          className="w-full h-full object-cover"
                        />
                      </span>
                      <ChevronDown className="w-5 h-5 text-[#2D4665] inline-block ms-1" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-2xl overflow-hidden">
                      <div className=" pt-5 pb-3 px-2 bg-gradient-to-r from-[#C2387F] to-[#673AB7]">
                        <div className="w-12 h-12 border-[2px] border-white mx-auto mb-2 rounded-full overflow-hidden">
                          <img
                            src={user?.profile || profileImage}
                            alt="Profile image"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <h4 className="text-white text-center font-semibold text-md mb-1">
                          {/* Dany Matthew{" "} */}
                          {user?.name}
                        </h4>
                        <h6 className="text-white text-center font-normal text-xs">
                          {user?.email}
                        </h6>
                      </div>
                      <div className="pt-3 pb-4">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className="px-4 py-[10px] text-sm w-full text-left flex text-[#2D4665]"
                              to="/profile"
                              onClick={() => handleProfileClick("profile")}
                              // onClick={handleProfileClick('profile')}
                            >
                              <User className="w-5 h-5 text-[#2D4665] me-3" />
                              My Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className="px-4 py-[10px] text-sm w-full text-left flex text-[#2D4665]"
                              to="/session"
                              onClick={() => handleProfileClick("session")}
                            >
                              <Bookmark className="w-5 h-5 text-[#2D4665] me-3" />
                              Events
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className="px-4 py-[10px] text-sm w-full text-left flex text-[#2D4665]"
                              to="/courses"
                              onClick={() => handleProfileClick("courses")}
                            >
                              <Book className="w-5 h-5 text-[#2D4665] me-3" />
                              My Courses
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className="px-4 py-[10px] text-sm w-full text-left flex text-[#2D4665]"
                              onClick={handleLogout}
                            >
                              <LogOut className="w-5 h-5 text-[#2D4665] me-3" />
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Header;
