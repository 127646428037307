import React from 'react'
import Logo from "../assets/images/sidebar/logo.svg";
import { useNavigate,Link } from "react-router-dom";


const AuthModal = ({ message = '', subtext = '', redirectUrl = '', setBookingShowHide='' }) => {
    const navigate = useNavigate();
    async function redirectToLogin() {
        navigate("/login", { state: { redirect: "/interact" } });
      }

      const handleClose = () => {
        setBookingShowHide(false); // Call the setBookingShowHide function passed from the parent
      };
    
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-md">
          <div className="bg-white w-96 px-6 rounded-2xl py-10  max-w-[94%]">
            <div className="text-center">
              <img src={Logo} className="mx-auto mb-5 w-7" />
              <h5 className="text-lg font-medium mb-2">
                {message}
              </h5>
              <p className="text-xs text-slate-500 font-light mb-6">
                {subtext}
              </p>
              <button
                className="px-6 py-2.5 font-medium text-sm rounded-md bg-theme-blue text-white"
                onClick={redirectToLogin}
              >
                Sign In / Sign Up
              </button>
              {redirectUrl && (
                <div className="w-full mt-2">
                        <button onClick={() => setBookingShowHide(false)}
                          className="text-sm text-[#4087F3] hover:text-black border-b border-b-[#4087F3] hover:border-b-black pb-[2px]"
                        >
                          Back
                        </button>
                      </div>
                    )}
            </div>
          </div>
        </div>
  )
}

export default AuthModal