import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ChatListSkeleton = () => {
  return (
    <div className="w-full">
      {new Array(8).fill(1).map((el, index) => (
        <div className=" md:max-w-[343px] flex items-center justify-between w-full px-5 py-3 " key={`chat_skeleton_${index}`}>
          <div className="mr-3 rounded-full w-11 h-11 min-w-11">
            <Skeleton
              circle={true}
              className=" w-11 h-11 min-w-11"
              containerClassName=""
            />
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center">
              <Skeleton className="w-11 min-w-24 h-4 inline-block" />
              <span className="ms-auto">
                <Skeleton className="w-5 min-w-10 h-4 inline-block" />
              </span>
            </div>
            <div className="flex items-center">
              <Skeleton
                className="w-full min-w-fit h-3 inline-block"
                containerClassName="w-full"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatListSkeleton;
