import React, { useState } from "react";
import { User, Volume2, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/sidebar/logo.svg";
import L1XToolKit from "../../assets/images/l1x-toolkit-image.png";
import {Animated} from "react-animated-css";

const Overview = () => {

  const [showHide, setShowHide] = useState()
  const [showDetails, setShowDetails] = useState(false);
  const [showZoomImage, setShowZoomImage] = useState(false);
  
  const ShowDetailsHandler = () => {
    setShowDetails(true);
  };


  return (
    <div className="relative">
      <div className="inner-content-wrapper">
        <div className="flex w-full">
          <div className=" max-w-4xl px-6 lg:px-12 ">
            <div className="w-full flex items-center justify-between sticky left-0 bg-white top-0 pb-5 pt-6 lg:pt-9">
              <div className="grid grid-cols-2 bg-[#E9EDF3] rounded-2xl mx-auto md:mx-0">
                <Link
                  to="/improvement-protocol/overview"
                  className="text-xs text-center rounded-3xl px-3 py-2 bg-[#4087F3] text-white"
                >
                  Overview
                </Link>
                <Link
                  to="/improvement-protocol/protocol-requests"
                  className="text-xs text-center rounded-3xl text-[#2D4665] px-3 py-2"
                >
                  Protocol Requests
                </Link>
              </div>
              <div className="ms-5 hidden lg:block">
                <Link target="_blank" to="https://github.com/L1X-Foundation/LIPs/blob/master/LIPS/LIP-template.md" className="px-4 py-2 font-medium text-sm bg-blue-50 border border-blue-600 text-blue-600 rounded-3xl inline-block text-center">
                  {" "}
                  Start With Template
                </Link>
              </div>
            </div>
            <div className="w-full">
             
              <div className="w-full mb-4">
               <p className="text-sm mb-5 text-[#2D4665] leading-6">
                <b>The L1X Improvement Proposal (LIP)</b> is a structured document used by developers to propose enhancements to the L1X platform. Each LIP includes a detailed technical specification and a rationale for the proposed feature. LIP authors are responsible for engaging with the community to build consensus and address any concerns. This process ensures that each proposal is thoroughly examined and aligns with the community's standards. The collaborative approach fosters a robust development environment within the L1X community.
                </p>

                <h4 className="text-lg font-semibold mb-4">
                LIP Rationale
                </h4>
                <p className="text-sm mb-5 text-[#2D4665] leading-6">
                LIPs are designed to be the main tool for suggesting new features, gathering technical feedback from the community, and recording the design choices made in L1X development. The maintenance of LIPs as text files in a version-controlled repository ensures that the evolution and rationale of each proposal are preserved as a historical record.
                </p>

                <h4 className="text-lg font-semibold mb-4">
                LIP Types
                </h4>
                <p className="text-sm mb-3 text-[#2D4665] leading-6">
                There are three kinds of LIPs:
                </p>

                <ul className="list-decimal list-inside text-sm text-primary-text pl-4">
                  <li className="mb-2 font-bold">Standard LIPs:</li>
                  <ul className="list-disc list-outside pl-8 mb-6">
                    <li className="mb-2"><b className="font-semibold">Core LIPs</b> focus on introducing pivotal new features within the LIP protocol, aiming to expand its capabilities and functionality.</li>
                    <li className="mb-2"><b className="font-semibold">Network LIPs</b> detail proposed enhancements to the network protocol specifications to improve performance and interoperability.</li>
                    <li className="mb-2"><b className="font-semibold">Contract Standards LIPs</b> define standardized interfaces for L1X Smart Contracts, promoting reusability and consistency across the LIP ecosystem.</li>
                    <li className="mb-2"><b className="font-semibold">Xtalk LIPs </b> focus on language-level standards, including the standardization of method names, to ensure clarity and uniformity in development practices.</li>
                  </ul>
                  <li className="mb-2 font-bold">Tools:</li>
                  <ul className="list-disc list-outside pl-8 mb-6">
                    <li className="mb-2"><b className="font-semibold">Wallet Standards LIP:</b> Outlines ecosystem-wide APIs for wallet implementations, aiming to standardize interactions and functionalities across different wallet platforms within the LIP ecosystem.</li>
                    <li className="mb-2"><b className="font-semibold">Developer Tools LIP:</b> Establishes comprehensive norms and guidelines for developer tools, enhancing the efficiency, compatibility, and user experience of software development tools within the LIP ecosystem.</li>
                  </ul>
                  <li className="mb-2 font-bold">An Informational LIP:</li>
                  <ul className="list-disc list-outside pl-8 mb-6">
                    <li className="mb-2">An Informational LIP details design issues or provides general guidelines and insights to the L1X community, without introducing any new features. Informational LIPs may not represent the consensus of the L1X community or act as recommendations, thus giving users and implementers the freedom to either ignore or follow the advice they offer.</li>
                  </ul>
                </ul>

                <h4 className="text-lg font-semibold mb-4">
                Stages: 
                </h4>
                <p className="text-sm mb-5 text-[#2D4665] leading-6">
                The L1X Improvement Protocol (LIP) outlines the structured process for proposing, drafting, reviewing, and integrating improvements to the L1X blockchain network. This section describes the various stages in the lifecycle of a LIP.
                </p>
                <div className="my-10">
                  <div className="w-full cursor-pointer" onClick={() => setShowZoomImage(true)}>
                  <img src={L1XToolKit} className="w-full max-w-full"/>
                  </div>
                </div>


                <ul className="list-disc list-outside pl-8 mb-6 text-sm text-primary-text">
                    <li className="mb-2"><b className="font-semibold">Open:</b> Anyone in the L1X community can pre-draft ideas for potential improvements to the L1X blockchain network.</li>
                    <li className="mb-2"><b className="font-semibold">Draft Proposal & Feedback:</b> Invited participants, typically core developers and active community members, formally draft proposals based on pre-drafted ideas. Feedback is solicited and incorporated into the proposal.</li>
                    <li className="mb-2"><b className="font-semibold">Review & Feedback:</b> L1X Editors, appointed individuals responsible for overseeing the protocol's evolution, review the draft proposals and provide feedback to ensure technical accuracy and alignment with the L1X network's goals.</li>
                    <li className="mb-2"><b className="font-semibold">Finalization:</b> Editors propose the final draft of the LIP to the broader developer community for their vote. Nodes, representing the decentralized network, accept the LIP through a consensus mechanism.</li>
                    <li className="mb-2"><b className="font-semibold">Acceptance & Integration:</b> Upon acceptance, developers implement the necessary code changes to accommodate the approved LIP. After development, the LIP is integrated into the L1X blockchain network, contributing to its ongoing evolution and improvement.</li>
                  </ul>

                  <h4 className="text-lg font-semibold mb-4">
                  What does a successful LIP look like?
                </h4>

                <ul className="list-disc list-outside pl-8 mb-6 text-sm text-primary-text">
                    <li className="mb-2"><b className="font-semibold">Preamble:</b> Metadata for the LIP, including the LIP number, title, authors, type (Standard, Tools, or Informational), category (Core, Networking, Xtalk), status, created date, and any relevant discussions.</li>
                    <li className="mb-2"><b className="font-semibold">Simple Summary:</b> A concise description of the issue being addressed.</li>
                    <li className="mb-2"><b className="font-semibold">Abstract:</b> A short (~200 word) description of the technical issue and the changes proposed.</li>
                    <li className="mb-2"><b className="font-semibold">Motivation:</b> An explanation of why the existing protocol specifications are inadequate and why the L1X community should adopt the proposed changes.</li>
                    <li className="mb-2"><b className="font-semibold">Specification:</b> A detailed technical specification describing the syntax and semantics of any new feature. This is the core of the LIP.</li>
                    <li className="mb-2"><b className="font-semibold">Rationale:</b> An explanation of the decisions made in the proposed design and why other designs were not chosen. This section should also include discussion of trade-offs and benefits.</li>
                    <li className="mb-2"><b className="font-semibold">Backwards Compatibility:</b> A discussion of how the change will affect existing applications and the L1X ecosystem at large. It should detail any breaking changes and suggest mitigations.</li>
                    <li className="mb-2"><b className="font-semibold">Test Cases:</b> Example test cases or scenarios that demonstrate the proposed changes' correctness.</li>
                    <li className="mb-2"><b className="font-semibold">Reference Implementation:</b> An optional section that includes a reference implementation of the proposal. This helps demonstrate how the LIP would be implemented and aids in discovering any issues with the proposal.</li>
                  </ul>

                  <div className="p-4 rounded-2xl shadow-card-shadow mb-8">
                    <p className="text-sm mb-4 text-[#2D4665] leading-6">
                    <b className="font-600">LIP Template:</b> Each LIP should be utilizing the LIP-0 template and contain all necessary sections. This approach helps LIP reviewers and community members easily review and offer feedback.
                    </p>
                    <div className="flex gap-4 items-center">
                    <Link to="/interact?type=channels&id=660cb53b95e97b327e437ca1" className="text-center px-4 py-1.5 rounded-full bg-theme-blue text-sm text-white">Give Your Feedback</Link>
                    <Link className="px-4 py-1.5 rounded-full bg-theme-navyblue text-sm text-white text-center" target="_blank" to="https://github.com/L1X-Foundation/LIPs/blob/master/LIPS/LIP-template.md">Start With Template</Link>
                    </div>
                </div>



                

              </div>
            </div>
          </div>
          {/* <div className="basis-1/4 min-w-[280px] pt-9 px-5 ">
            <div className="w-full sticky top-9 left-0">
            <h4 className="text-[#222D3A] text-sm font-medium mb-2">Overview</h4>
            <ul className="w-full">
              <li className="text-[#4087F3] hover:text-[#4087F3] text-sm leading-[14px] relative border-l-2 border-l-[#4087F3] hover:border-l-[#4087F3] pl-2 mb-3">
                <Link>Contributing</Link>
              </li>
              <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-[14px] relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                <Link> LIP status terms</Link>
              </li>
              <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-[14px] relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                <Link> LIP Types</Link>
              </li>
              <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-[14px] relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                <Link> Standard Track (752)</Link>
              </li>
              <li className="text-[#2D4665]  hover:text-[#4087F3] text-sm leading-[14px] relative border-l-2 border-l-[transparent] hover:border-l-[#4087F3] pl-2 mb-3">
                <Link> Core</Link>
              </li>
            </ul>
            </div>
          </div> */}
        </div>
      </div>

      {showHide && 
        <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10 flex items-center justify-center backdrop-blur-[5px]">
            <div className="bg-white w-96 px-6 rounded-2xl py-10">
              <div className="text-center">
                <img src={Logo} className="mx-auto mb-1 w-7" />
                <h4 className="text-lg font-semibold mb-4">L1X Labs</h4>
                <h5 className="text-2xl font-semibold mb-6">
                  Coming Soon
                </h5>
                <button onClick={() => setShowHide(false)}
                  className="px-6 py-2 font-medium text-sm rounded-md bg-gray-200 text-black"
                >
                Close
                </button>
              </div>
            </div>
          </div>
        }

        {
          showZoomImage && (
            <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={300} className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-full bg-white z-30 max-h-[768px] flex items-center justify-center w-full max-w-screen-xl mx-auto overflow-hidden rounded-2xl p-10">
              <button onClick={() => setShowZoomImage(false)} className="absolute top-4 right-4"><XCircle className="text-slate-400 hover:text-red-500" /></button>
              <img src={L1XToolKit} className="w-full max-w-full"/>
            </Animated>
          )
        }
  </div>
  );
};

export default Overview;
